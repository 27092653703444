import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";

//import for dropdown
import Select from "react-select";
import Card from "../../../components/Card";
import { useNavigate } from "react-router-dom";

const UserInterest = () => {
  const data = [
    {
      value: 1,
      label: "select-1",
    },
    {
      value: 2,
      label: "select-2",
    },
    {
      value: 3,
      label: "select-3",
    },
    {
      value: 4,
      label: "select-4",
    },
    {
      value: 5,
      label: "select-5",
    },
    {
      value: 6,
      label: "select-6",
    },
    {
      value: 7,
      label: "select-7",
    },
    {
      value: 8,
      label: "select-8",
    },
  ];
  // set value for default selection
  const [selectedValue, setSelectedValue] = useState([]);
  const [timezone, setTimeZone] = useState([])
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [iam, setIam] = useState('')
  const [bio, setBio] = useState('')

  const emailTo = localStorage.getItem('email-to')

  const emailSwitch = localStorage.getItem('emailSwitch')
  useEffect(() => {
    // Fetch timezone options

    // ... Your fetch logic here ...

    // Restore previously filled form values from local storage (if available)
    const storedFormValues = JSON.parse(localStorage.getItem("userInterestForm"));
    if (storedFormValues) {
      setSelectedTimeZone(storedFormValues.selectedTimeZone);
      setIam(storedFormValues.iam);
      setBio(storedFormValues.bio);
    }
  }, []);

  // Save form values to local storage whenever they change
  useEffect(() => {
    const formValues = {
      selectedValue,
      selectedTimeZone,
      iam,
      bio,
    };
    localStorage.setItem("userInterestForm", JSON.stringify(formValues));
  }, [selectedTimeZone, iam, bio]);


  const handleTimeZoneChange = (event) => {
    setSelectedTimeZone(event.target.value);

  };


  console.log("time--", selectedTimeZone)




  useEffect(() => {
    if (localStorage.getItem("userloggedin") === 'true') {
      try {
        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


        const targetUser = userDataArray.find(user => user.email === emailTo);

        if (targetUser) {
          const { userID, accessToken } = targetUser;


          fetch('https://app.xblue.ai/Xblue/api/get/timezone', {
            method: 'GET',

            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }).then(response => {

            console.log("timense-----", response)
            return response.json();
          }).then(data => {
            console.log("time", data);
            setTimeZone(data.data)
            if (data.code == 200) {
              console.log("success")
            }
            else {
              console.log("failed")
            }

          })
        }




      } catch (error) {
        console.log(error.message);
      }
    }
    else {
      try {
        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


        const targetUser = userDataArray.find(user => user.email === emailSwitch);

        if (targetUser) {
          const { userID, accessToken } = targetUser;


          fetch('https://app.xblue.ai/Xblue/api/get/timezone', {
            method: 'GET',

            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }).then(response => {

            console.log("timense-----", response)
            return response.json();
          }).then(data => {
            console.log("time", data);
            setTimeZone(data.data)
            if (data.code == 200) {
              console.log("success")
            }
            else {
              console.log("failed")
            }

          })
        }




      } catch (error) {
        console.log(error.message);
      }
    }


  }, [])

  const options = timezone.map((value) => ({ value, label: value }))


  // const handleChange = (e) => {
  //    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);

  // };


  const navigate = useNavigate()
  const handleBack = () => {
    // navigate('/app/user-interest')
    navigate('/app/user-interest')

  }

  const showKeyword = () => { };
  const customStyles = {
    backgroundColor: "#EFEFEF",
    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#EFEFEF",
      boxShadow: "none",
    }),
  };




  const tokenLogin = localStorage.getItem('accessToken')

  const ID = localStorage.getItem('UserID')


  const handleSubmit = () => {
    if (localStorage.getItem("userloggedin") === 'true') {

      try {

        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


        const targetUser = userDataArray.find(user => user.email === emailTo);

        if (targetUser) {
          const { userID, accessToken } = targetUser;

          const myStrring = selectedValue.toString()

          let fdata = new FormData();


          fdata.append('id', userID);
          fdata.append('iam', iam);
          fdata.append('bio', bio);
          fdata.append('timezone', selectedTimeZone);



          // fetch('https://app.tweetnow.ai/TweetNow/api/save/description', {
          fetch('https://app.xblue.ai/Xblue/api/save/description', {
            method: 'POST',
            body: fdata,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          }).then(response => {

            console.log("response time zine", response)
            return response.json();
          }).then(data => {
            console.log("time zine data", data);
            if (data.code == 200) {
              localStorage.setItem("TimeZone", selectedTimeZone)
              navigate('/dashboard/app/notification')

              // alert("user added succesfully")

            }


            else {

              console.log("failed hello")
            }

          })
        }



      } catch (error) {
        console.log(error)
      }
    }
    else {
      try {

        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


        const targetUser = userDataArray.find(user => user.email === emailSwitch);

        if (targetUser) {
          const { userID, accessToken } = targetUser;

          const myStrring = selectedValue.toString()

          let fdata = new FormData();


          fdata.append('id', userID);
          fdata.append('iam', iam);
          fdata.append('bio', bio);
          fdata.append('timezone', selectedTimeZone);



          // fetch('https://app.tweetnow.ai/TweetNow/api/save/description', {
          fetch('https://app.xblue.ai/Xblue/api/save/description', {
            method: 'POST',
            body: fdata,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          }).then(response => {

            console.log("response time zine", response)
            return response.json();
          }).then(data => {
            console.log("time zine data", data);
            if (data.code == 200) {

              navigate('/dashboard/app/notification')
              localStorage.setItem("TimeZone", selectedTimeZone)
              // alert("user added succesfully")

            }


            else {

              console.log("failed hello")
            }

          })
        }



      } catch (error) {
        console.log(error)
      }
    }
  }




  return (
    <>
      <section className="interest-page">

        <Container className="p-0">
          <Row className="no-gutters">
            <Col md="12" className="bg-white pb-lg-0 pb-5">
              <div className="user-bio-from">
                <div className="headdiv">
                  <h1 className="tweetheading">Welcome to Xblue</h1>
                  <br />
                  <h4 className="tweetheading2">
                    How would you describe yourself on Twitter?
                  </h4>
                  <br />
                  <p>Who are you ? What you like to talk about ?</p>
                </div>
                <div className="middiv">
                  <Form className="biod">
                    <Form.Group className="form-group">
                      <Form.Label>I am a </Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue=""
                        styles={customStyles}
                        value={iam}
                        onChange={(event) => setIam(event.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>I usually post about </Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue=""
                        value={bio}
                        onChange={(event) => setBio(event.target.value)} />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label htmlFor="exampleFormControlSelect1">
                        Timezone
                      </Form.Label>
                      <Form.Select aria-label="Default select example timezone-form"
                        onChange={handleTimeZoneChange}
                        value={selectedTimeZone}>

                        <option value={-1}>Select a timezone</option>
                        {timezone.map(opt => (
                          <option key={opt} value={opt}>
                            {opt}
                          </option>

                        ))}
                      </Form.Select>
                    </Form.Group>

                    {/* <Form.Select aria-label="Default select example"
                      onChange={handleTimeZoneChange}
                      value={selectedTimeZone}
                    >
                      <option>Open this select menu</option>
                      {timezone.map(option => (
                        <option key={option.value} value={option}>
                          {option}
                        </option>
                      ))}

                    </Form.Select> */}


                  </Form>
                </div>
                <div className="bottomdiv">
                  <Row className="buttonrow">
                    {/* <Col  sm="12" lg="12"> */}
                    {/* <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Block Buttons</h4>
                           </div>
                        </Card.Header>
                        <Card.Body> */}
                    <Button className="btn-block-back mb-1" onClick={handleBack}>
                      Back
                    </Button>{" "}
                    <Button className="btn-block-next mb-1" onClick={handleSubmit}>
                      Next
                    </Button>{" "}
                    {/* </Card.Body>
                     </Card> */}
                    {/* </Col> */}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default UserInterest;
