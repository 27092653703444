import React, { useEffect, useState } from 'react'

//header
import Header from '../../components/partials/dashboard/HeaderStyle/header'

//sidebar
import RightSidebar from '../../components/partials/dashboard/SidebarStyle/rightsidebar'

//sidebar
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar'

//footer
import Footer from '../../components/partials/dashboard/FooterStyle/footer'

//default 
// import DefaultRouter from '../../router/default-router'

// share-offcanvas
// import ShareOffcanvas from '../../components/share-offcanvas'

//settingoffCanvas
import SettingOffCanvas from '../../components/setting/SettingOffCanvas'
import { Outlet, useNavigate } from 'react-router-dom'


import { MyContext, MySideBarContext, MyThemeContext } from '../../components/MyQueue/MyContext'

const Default = () => {
    const [cursorstatus, setCursorStatus] = useState(false)
    const [context, setContext] = useState({});
    const navigate = useNavigate()

    // const add = (data) => {
    //     console.log("1234t", data);
    //     setContextData(data)
    // }

    const [contextData, setContextData] = useState({})
    const [themeContext, setThemeContext] = useState({})
    const [displayProfileDetails, setDisplayProfileDetails] = useState();

    // const [contextDraft, setContextDraft] = useState({})
    console.log("default-new", contextData, context)
    const emailTo = localStorage.getItem('email-to')
    const emailSwitch = localStorage.getItem('emailSwitch')

    const handleComposeClick = () => {
        setCursorStatus(!cursorstatus)
        // onComposeClick();
        // Call the callback function passed from the parent component
        // Logic to handle the "Compose a Tweet" button click in the Sidebar component
        // You can perform any necessary actions or pass the information to other components here
    };


    const updateDisplayProfileDetails = (profileDetails) => {
        setDisplayProfileDetails(profileDetails);
    };

    // useEffect(() => {

    //     let token = localStorage.getItem('accessToken');
    //     if (!token || token === null || token === "") {
    //         navigate('/')
    //     }

    // }, [])



    useEffect(() => {
        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


        if (localStorage.getItem("userloggedin") === 'true') {

            const targetUser = userDataArray.find(user => user.email === emailTo);

            if (targetUser) {
                const { userID, accessToken } = targetUser;


                if (!accessToken || accessToken === null || accessToken === "") {
                    navigate('/')
                }
            } else {
                console.log(`User with email ${emailTo} not found`);
            }
        }
        else {
            const targetUser = userDataArray.find(user => user.email === emailSwitch);

            if (targetUser) {
                const { userID, accessToken } = targetUser;


                if (!accessToken || accessToken === null || accessToken === "") {
                    navigate('/')
                }
            } else {
                console.log(`User with email ${emailTo} not found`);
            }
        }

    }, [])
    return (
        <>
            {/* <MyThemeContext.Provider value={[themeContext, setThemeContext]}> */}
            < MySideBarContext.Provider value={[contextData, setContextData]}>
                <MyContext.Provider value={[context, setContext]}>
                    <Sidebar onComposeClick={handleComposeClick}
                        updateProfileDetails={updateDisplayProfileDetails}
                    />
                    <Header />
                    <div className="main-content" >
                        {/* <div id="content-page" className="content-page"> */}
                        {/* <DefaultRouter/> */}
                        <Outlet />
                        {/* </div> */}
                    </div>
                    <RightSidebar cont={context} handleComposeClick={cursorstatus} displayProfileDetails={displayProfileDetails} />
                    {/* <Footer /> */}
                    {/* <SettingOffCanvas /> */}
                </MyContext.Provider>
            </ MySideBarContext.Provider>
            {/* </MyThemeContext.Provider> */}
        </>
    )
}

export default Default
