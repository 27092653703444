import React, { useEffect, useState, useContext, useMemo } from 'react'
import './style.css'
import { Row, Col, Container, Dropdown, Button, Card, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUpRightFromSquare, faStar, faPaperPlane, faQuoteLeft, faQuoteRight, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Ellipse from '../../../assets/images/Ellipse.png'
import Spinner from 'react-bootstrap/Spinner';
import Masonry from 'react-masonry-css';
import { MySideBarContext, MyContext } from '../../../components/MyQueue/MyContext'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { BASE_URL } from '../../../utilities/Config';

export default function AiGenerated() {
    const userID = localStorage.getItem('UserID')
    const tokenLogin = localStorage.getItem('accessToken')
    const [searchTweet, setSearchTweet] = useState('')
    const [displaySearchTweet, SetDisplaySearchTweet] = useState([])
    const [loading, setLoading] = useState(false)
    const [context, setContext] = useContext(MyContext);
    const [displayAImessage, setDisplayAImessage] = useState(null)
    const [displayProfileImage, setDisplayProfileImage] = useState('')
    const navigate = useNavigate()
    console.log(context, "contextcontext");
    const emailSwitch = localStorage.getItem('emailSwitch')
    const emailTo = localStorage.getItem('email-to')
    const breakpointColumnsObj = {
        default: 3,
        1200: 3,
        992: 2,
        768: 1
    };

    useEffect(() => {

        displayProfile()
    }, [])



    const handleSearchAI = () => {

        if (searchTweet == '') {

            toast.error("Please enter topic", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.log("print")
        }
        else {
            if (localStorage.getItem("userloggedin") === 'true') {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoading(true);
                        let fdata = new FormData();

                        fdata.append('id', userID)
                        fdata.append('text', searchTweet);


                        fetch(`${BASE_URL}api/ai/multiple/tweets`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response search", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet search", data);

                            if (data.code == 200) {
                                // window.location.reload()

                                // alert("fvrfg")
                                SetDisplaySearchTweet(data)

                            }
                            else if (data.code == 400) {

                                toast.error(data.data.message_for_AI_Settings, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }


                            else {



                                toast.error(data.data.error.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                            setLoading(false);
                        })
                    }
                    else {
                        console.log("dcdscdscsdc dscs")
                    }



                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoading(true);
                        let fdata = new FormData();

                        fdata.append('id', userID)
                        fdata.append('text', searchTweet);


                        fetch(`${BASE_URL}api/ai/multiple/tweets`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response search", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet search", data);

                            if (data.code == 200) {
                                // window.location.reload()

                                // alert("fvrfg")
                                SetDisplaySearchTweet(data)

                            }
                            else if (data.code == 400) {

                                toast.error(data.data.message_for_AI_Settings, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }


                            else {



                                toast.error(data.data.error.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                            setLoading(false);
                        })
                    }
                    else {
                        console.log("dcdscdscsdc dscs")
                    }



                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (searchTweet == '') {

                toast.error("Please enter topic", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log("print")
            }
            else {
                if (localStorage.getItem("userloggedin") === 'true') {
                    try {
                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                        const targetUser = userDataArray.find(user => user.email === emailTo);

                        if (targetUser) {
                            const { userID, accessToken } = targetUser;
                            setLoading(true);
                            let fdata = new FormData();

                            fdata.append('id', userID)
                            fdata.append('text', searchTweet);


                            fetch(`${BASE_URL}api/ai/multiple/tweets`, {
                                method: 'POST',
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                }
                            }).then(response => {

                                console.log("response search", response)
                                return response.json()
                            }).then(data => {
                                console.log("tweet search", data);

                                if (data.code == 200) {
                                    // window.location.reload()

                                    // alert("fvrfg")
                                    SetDisplaySearchTweet(data)

                                }
                                else if (data.code == 400) {

                                    toast.error(data.data.message_for_AI_Settings, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }


                                else {



                                    toast.error(data.data.error.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }
                                setLoading(false);
                            })
                        }
                        else {
                            console.log("dcdscdscsdc dscs")
                        }



                    } catch (error) {
                        console.log(error)
                    }
                }
                else {
                    try {
                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                        const targetUser = userDataArray.find(user => user.email === emailSwitch);

                        if (targetUser) {
                            const { userID, accessToken } = targetUser;
                            setLoading(true);
                            let fdata = new FormData();

                            fdata.append('id', userID)
                            fdata.append('text', searchTweet);


                            fetch(`${BASE_URL}api/ai/multiple/tweets`, {
                                method: 'POST',
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                }
                            }).then(response => {

                                console.log("response search", response)
                                return response.json()
                            }).then(data => {
                                console.log("tweet search", data);

                                if (data.code == 200) {
                                    // window.location.reload()

                                    // alert("fvrfg")
                                    SetDisplaySearchTweet(data)

                                }
                                else if (data.code == 400) {

                                    toast.error(data.data.message_for_AI_Settings, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }


                                else {



                                    toast.error(data.data.error.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }
                                setLoading(false);
                            })
                        }
                        else {
                            console.log("dcdscdscsdc dscs")
                        }



                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        }
    }


    const displayProfile = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profileeeee", data.data);
                    if (data.code == 200) {
                        setDisplayAImessage(data.data)
                        setDisplayProfileImage(data.data.user)

                        if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {
                            // alert("subscribed")
                        }
                        else {
                            navigate('/app/payments')
                        }


                    }
                    else {



                        console.log("failed")


                    }
                }
                else {
                    console.log("asxcjksjxs cdisuc ")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profileeeee", data.data);
                    if (data.code == 200) {
                        setDisplayAImessage(data.data)
                        setDisplayProfileImage(data.data.user)
                        if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {
                            // alert("subscribed")
                        }
                        else {
                            navigate('/app/payments')
                        }



                    }
                    else {



                        console.log("failed")


                    }
                }
                else {
                    console.log("asxcjksjxs cdisuc ")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };

    const handleNavigateAISettings = () => {

        navigate('/dashboard/app/settings')
    }






















    const [contextData, setContextData] = useContext(MySideBarContext)
    console.log("contextData", contextData)



    const currentDat = new Date();
    const initialDate = currentDat.toLocaleDateString('en-GB');
    const startDate = moment(initialDate, 'DD-MM-YYYY');
    const [currentMonth, setCurrentMonth] = useState(startDate.month());
    const year = startDate.year();
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalText, setModalText] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [sectionTexts, setSectionTexts] = useState([]);
    const [time, setTime] = useState([
        '2:30 PM', '4:23 AM', '5:34 PM'
    ])
    const [dateTimeList, setDateTimeList] = useState([])


    const [dynamictime, setDynamicTime] = useState([])
    const [dynamicQueue, setDynamicQueue] = useState([])


    useEffect(() => {

        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };

        fetchData();

    }, [])


    useEffect(() => {
        if (contextData.QUEUESaved) {

            // displayDraft()
            displayDynamicQueue()
        }
        console.log(contextData.QUEUESaved, 'Queueu SAVED');
    }, [contextData.QUEUESaved])




    // const myQueuueTimeSlotDynanmic = async () => {
    //     try {
    //         const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published/tweet/list", data.data.timeslot);
    //         setDynamicTime(data.data.timeslot)


    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }
    // };

    const myQueuueTimeSlotDynanmic = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)

                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)

                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };





    // const displayDynamicQueue = async () => {
    //     try {
    //         const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published/queue", data);
    //         if (data.code == 200) {
    //             setDynamicQueue(data.data)

    //         }


    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }
    // };


    const displayDynamicQueue = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)


                    }

                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)


                    }

                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };

    console.log("dynamic-queue-1", dynamicQueue);



    //THIS IS THE ONE ---------
    useMemo(() => {
        let timeIndex = -1;
        let availableSlot = null;
        // let dateIndex = dateTimeList.findIndex(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
        // console.log(dateIndex, moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'), 'asdsad');
        // if(dateTimeList[dateIndex]){
        //     timeIndex = dateTimeList[dateIndex].time.findIndex(obj => obj.time === contextData?.handleTime)
        // }
        // if (dateTimeList[dateIndex] && dateTimeList[dateIndex].time[timeIndex]){
        // dateTimeList[dateIndex].time[timeIndex].text = contextData?.tweetText
        // dateTimeList[dateIndex].time[timeIndex].image = contextData?.selectedImages
        // dateTimeList[dateIndex].time[timeIndex].gif = contextData?.selectedGifpicker
        //     setDateTimeList([...dateTimeList])
        // }



        if (contextData?.handleDate) {
            let dateFilter = dateTimeList.filter(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
            console.log("dvdsv", dateFilter)
            dateFilter.map(el => {

                timeIndex = el.time.findIndex(obj => obj.time === contextData?.handleTime)

                if (el && el.time[timeIndex]) {
                    el.time[timeIndex].text = contextData?.tweetText
                    el.time[timeIndex].image = contextData?.selectedImages
                    el.time[timeIndex].gif = contextData?.selectedGifpicker
                }

            }

            )
            setDateTimeList([...dateTimeList])
            console.log(dateTimeList, 'dateTimeLista');
            let list = dateTimeList;

            for (let i = 0; i < list.length; i++) {
                const currentTime = moment();
                const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY h:mm A').format('DD/MM/YYYY')
                let blankTextInd = list[i].time.findIndex(obj => obj.text === '');
                if (blankTextInd > -1) {
                    if (list[i] && list[i].time && list[i].time[blankTextInd]?.time && moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY h:mm A').isSameOrAfter(currentTime)) {
                        console.log('future time');
                        const timeFirst = list[i].time[blankTextInd]
                        console.log("!st,,,", timeFirst)
                        setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                        break;
                    }
                }

                console.log(",,,", i);
            }
        } else {
            dateTimeList.some((el) => {
                console.log("e---l", el)
                const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
                if (timeIndex !== -1) {
                    el.time[timeIndex].text = contextData?.tweetText;
                    el.time[timeIndex].image = contextData?.selectedImages;
                    el.time[timeIndex].gif = contextData?.selectedGifpicker;

                    return true; // Stop iterating after updating the first available slot


                }
                return false;
            });

            //     dateTimeList.some((el) => {
            //                 const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
            //                 if (timeIndex !== -1) {
            //                   availableSlot = el.time[timeIndex];
            //                   el.time[timeIndex].text = contextData?.tweetText;
            //                   el.time[timeIndex].image = contextData?.selectedImages;
            //                   el.time[timeIndex].gif = contextData?.selectedGifpicker;
            //                   return true; // Stop iterating after updating the first available slot
            //                 }
            //                 return false;
            //               });

            //       setDateTimeList([...dateTimeList]);




        }
        //    console.log('Available Slot:', availableSlot);

    }, [contextData])
    console.log("contextData", contextData, dateTimeList);

    // useEffect(() => {
    //     let timeIndex = -1;
    //     let availableSlot = null;

    //     dateTimeList.some((el) => {
    //       timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //       if (timeIndex !== 1) {
    //         availableSlot = el.time[timeIndex];
    //         el.time[timeIndex].text = contextData?.tweetText;
    //         el.time[timeIndex].image = contextData?.selectedImages;
    //         el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //         return true; // Stop iterating after updating the first available slot
    //       }
    //       return false;
    //     });

    //     setDateTimeList([...dateTimeList]);

    //     console.log('Available Slot:', availableSlot);
    //   }, [contextData]);

    //   console.log("contextData", contextData, dateTimeList);




    // useEffect(() => {
    //     let availableSlot = null;

    //     if (contextData?.handleDate) {
    //       let dateFilter = dateTimeList.filter(
    //         (obj) =>
    //           moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') ===
    //           moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd')
    //       );

    //       dateFilter.some((el) => {
    //         const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //         if (timeIndex !== -1) {
    //           availableSlot = el.time[timeIndex];
    //           el.time[timeIndex].text = contextData?.tweetText;
    //           el.time[timeIndex].image = contextData?.selectedImages;
    //           el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //           return true; // Stop iterating after updating the first available slot
    //         }
    //         return false;
    //       });

    //       setDateTimeList([...dateTimeList]);
    //     } else {
    //       dateTimeList.some((el) => {
    //         const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //         if (timeIndex !== -1) {
    //           availableSlot = el.time[timeIndex];
    //           el.time[timeIndex].text = contextData?.tweetText;
    //           el.time[timeIndex].image = contextData?.selectedImages;
    //           el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //           return true; // Stop iterating after updating the first available slot
    //         }
    //         return false;
    //       });

    //       setDateTimeList([...dateTimeList]);
    //     }

    //     console.log('Available Slot:', availableSlot);
    //   }, [contextData, dateTimeList]);



    useEffect(() => {
        const updateCurrentMonth = () => {
            const newMonth = moment().month();
            if (newMonth !== currentMonth) {
                setCurrentMonth(newMonth);
            }
        };

        const intervalId = setInterval(updateCurrentMonth, 60000); // Check every minute for date update

        return () => {
            clearInterval(intervalId); // Clear interval on component unmount
        };
    }, [currentMonth]);



    useEffect(() => {
        console.log('currentDate');

        let list = []
        let currentDate = moment(startDate); // Create a moment object for currentDate
        const endMonth = currentMonth + 2; // Display three months at a time
        const sectionKey = currentDate.format('YYYY-MM-DD');
        const sectionText = sectionTexts[sectionKey] || '';
        console.log('currentDate', currentDate);



        while (currentDate.year() === year && currentDate.month() <= endMonth) {

            // const slotForDays = dynamictime.filter(el => el.weekdays.includes(currentDate.format('dddd')))
            const slotForDays = (dynamictime ?? []).filter(el => el.weekdays.includes(currentDate.format('dddd')));

            // const slottext = dynamicQueue.map(es => es.text)
            console.log(dynamictime, "---dynamictime", slotForDays, "---slotForDays", dynamicQueue, "dynamicQueue", currentDate.format('dddd'), 'asasas');
            let timeslotArr = slotForDays?.map(a => {
                // console.log(a.weekdays, 'aaaaaa');
                // const ATime = moment(a.timeslot,'hh:mm:ss').format('h:mm A ')
                const ATime = a.timeslot
                //const queueItem = dynamicQueue.findIndex(item => item.schedule_time === ATime && item.schedule_date === currentDate.format('D MMMM YYYY'));
                // console.log("qute", queueItem)
                let textValue = ""
                let GivValue = ""
                let ImageValue = ""
                let id = ""
                let is_thread = ""
                let tweet_id = ""
                let thread_tweet_id = ""
                if (dynamicQueue && dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text
                ) {
                    id = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].id
                    textValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text

                    GivValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].gif

                    ImageValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].image

                    console.log("images", ImageValue)

                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }

                else if (dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].text
                ) {
                    let dynamicArrayIndex = dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)
                    let dynamicArray = dynamicQueue[dynamicArrayIndex].map(element => {
                        id = element.id
                        textValue = element.text

                        GivValue = element.gif

                        ImageValue = element.image
                        is_thread = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].is_thread
                        tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].tweet_id
                        thread_tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].thread_tweet_id
                        console.log("is_thread", is_thread)
                        return {
                            id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : '', is_thread: is_thread, tweet_id: tweet_id, thread_tweet_id: thread_tweet_id
                        }

                    })
                    return dynamicArray;




                }
                else {
                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }

                // return {
                //     id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                // }

            }
            )

            const formattedDate = currentDate.format('dddd | MMMM D, YYYY');
            list.push({
                formattedDate: formattedDate,
                time: timeslotArr,
                sectionKey: sectionKey
            })
            currentDate.add(1, 'day'); // Move to the next day

        }

        setDateTimeList([...list])

        for (let i = 0; i < list.length; i++) {
            const currentTime = moment();
            const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY HH:mm').format('DD/MM/YYYY')
            let blankTextInd = list[i].time.findIndex(obj => obj?.text === '' &&
                moment(currentDate + " " + obj?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)
            );
            console.log("listFor", list[i], i, blankTextInd)
            if (blankTextInd > -1) {
                console.log("listFor, !time,,,", list[i].time[blankTextInd]?.time, currentDate, currentTime)
                // if (list[i] && list[i].time && list[i].time[blankTextInd]?.time &&
                //     moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)) {
                console.log('future time', list[i].formattedDate);
                const timeFirst = list[i].time[blankTextInd]
                console.log("listFor, !st,,,", timeFirst, list[i], blankTextInd, i)
                setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                break;
                // }
            }
            console.log(",,,", i, currentDate, currentTime);
        }





        // for (let i = 0; i < list.length; i++) {

        //     const currentTime = moment();
        //     const futureTimeSlotIndex = list[i].time.findIndex(
        //       (obj) => moment(obj.time, "h:mm A").isSameOrAfter(currentTime)
        //     );

        //     if (futureTimeSlotIndex > -1) {
        //       const timeFirst = list[i].time[futureTimeSlotIndex];
        //       console.log("!st,,,", timeFirst);
        //       setContext({ t: timeFirst, formattedDate: list[i].formattedDate });
        //       break;
        //     }
        //     console.log(",,,", i);
        //   }


    }, [dynamictime])

    console.log(dateTimeList, 'dateTimeList');

    const handleTrendingTweetContext = (data) => {
        console.log("datats", data)
        localStorage.removeItem('savedValue')
        // setContext({ ...context, t: { ...context.t, text: data, thread: null } })
        setContext({ ...context, t: { ...context.t, text: data, full_text: data, thread: null } })


    }

    // const [data, setData] = useState([{ text: "", image: "" }]);

    // const handleImageFileChange = (event, index) => {
    //     const file = event.target.files[0];
    //     const newData = [...data];
    //     newData[index].image = file ? file.name : "";
    //     setData(newData);
    // };

    // const handleTextChange = (event, index) => {
    //     const newText = event.target.value;
    //     const newData = [...data];
    //     newData[index].text = newText;
    //     setData(newData);
    // };







    const [threads, setThreads] = useState([{ text: '' }]); // Initialize with two threads

    const handleTextareaChange = (index, newText) => {
        setThreads((prevThreads) => {
            const updatedThreads = [...prevThreads];
            updatedThreads[index].text = newText;
            return updatedThreads;
        });
    };

    const handleAddThread = () => {
        setThreads((prevThreads) => [...prevThreads, { text: '' }]);
    };


    console.log('Current state of threads:', threads);






    // const [startTime, setStartTime] = useState(null);
    // const [elapsedTime, setElapsedTime] = useState(moment.duration(0));
    // const [isTimerRunning, setIsTimerRunning] = useState(false);

    // const handleStartTimer = () => {
    //     setStartTime(moment());
    //     setIsTimerRunning(true);
    // };

    // useEffect(() => {
    //     let timer;

    //     if (isTimerRunning) {
    //         timer = setInterval(() => {
    //             setElapsedTime(moment.duration(moment().diff(startTime)));
    //         }, 1000);
    //     }

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, [isTimerRunning, startTime]);

    // const timerColor = elapsedTime.asMinutes() > 3 ? 'red' : 'black';


    return (
        <div id='content-page' className='content-page' style={{ backgroundColor: 'white' }}>


            {/* <button onClick={handleStartTimer} disabled={isTimerRunning}>
                Start Timer
            </button>
            <h2 style={{ color: timerColor }}>
                Elapsed Time: {moment.utc(elapsedTime.asMilliseconds()).format('mm:ss')}
            </h2> */}

            {/* {displayAImessage !== '' ?
                <Alert variant="danger" className='alert-text'>
                    <FontAwesomeIcon icon={faCircleInfo} className='alert-icon' />{displayAImessage ? displayAImessage : ''}. <span className='span-alert' onClick={handleNavigateAISettings}>Please update them here </span>
                </Alert>
                : ''} */}
            {/* 
            <div>
                {
                    displayAImessage?.message_for_AI_Settings !== '' && displayAImessage?.message_for_AI_Settings !== undefined ? (
                        <div>
                            Hello
                        </div>
                    ) : (
                        <div>
                            Byee
                        </div>
                    )
                }
            </div> */}

            {(displayAImessage?.message_for_AI_Settings !== ''
                && displayAImessage?.message_for_AI_Settings !== undefined
            ) ||
                (displayAImessage?.message_for_user_subscription !== '' &&
                    displayAImessage?.message_for_user_subscription !== undefined) ?

                <Alert variant="danger" className='alert-text'>
                    <FontAwesomeIcon icon={faCircleInfo} className='alert-icon-1' />
                    {displayAImessage?.message_for_AI_Settings || displayAImessage?.message_for_user_subscription}. <span className='span-alert' onClick={handleNavigateAISettings}>
                        Please update them here
                    </span>
                </Alert>
                :
                ''
            }

            {/* {displayAImessage !== '' ?
                <Alert variant="danger" className='alert-text'>
                    <FontAwesomeIcon icon={faCircleInfo} className='alert-icon' />{displayAImessage ? displayAImessage : ''}. <span className='span-alert' onClick={handleNavigateAISettings}>Please update them here </span>
                </Alert>
                : ''} */}


            <Container>
                <div className='search-tweet-ai'>
                    <h4>AI Generated </h4>
                    <hr className='aiborder' />
                </div>
                <div className='search-box-conatiner text-center'>
                    <h3>AI-generated posts for you</h3>
                    <p>Our AI generated these posts for you. Post them directly or use them as inspiration. Refreshed every day.</p>


                    <div className='search-input-ai'>
                        <FontAwesomeIcon icon={faSearch} className='search-icon-AI' onClick={handleSearchAI} />

                        <input type="text"
                            placeholder='Search'


                            className='search-input-text-ai'
                            value={searchTweet}
                            onChange={(e) => setSearchTweet(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />

                        {/* <FontAwesomeIcon icon={faSearch} className='search-icon-AI' onClick={handleSearchAI} /> */}

                    </div>

                </div>

                <div className='mt-5 text-center'>



                    <Row>
                        {
                            loading ? (
                                <div className='text-center'>

                                    <Spinner animation="grow" size="sm" variant="primary" className='mr-5' />
                                    <Spinner animation="grow" size="sm" variant="primary" className='mr-5' />
                                    <Spinner animation="grow" size="sm" variant="primary" className='mr-5' />
                                    <Spinner animation="grow" size="sm" variant="primary" className='mr-5' />
                                    <Spinner animation="grow" size="sm" variant="primary" className='mr-5' />

                                </div>
                            ) :


                                displaySearchTweet.data ? (
                                    <div className='dail-masornry'>
                                        <Masonry
                                            breakpointCols={breakpointColumnsObj}
                                            className="my-masonry-grid"
                                            columnClassName="my-masonry-grid_column"
                                        >
                                            {displaySearchTweet.data.generatedTweets.map((option, index) => (

                                                <Card className='DailyIns-card'>
                                                    <Card.Header as="h5">
                                                        <div className="DailyIns-card-header">
                                                            <div className='DailyIns-card-image'>
                                                                <img src={displayProfileImage?.image_url} className="DailyIns-img-fluid" alt="You tube" />
                                                            </div>



                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <FontAwesomeIcon icon={faQuoteLeft} className='faQuoteLeft' />
                                                        <Card.Text>


                                                            <p className='quote'>
                                                                {option}
                                                            </p>

                                                        </Card.Text>
                                                        <FontAwesomeIcon icon={faQuoteRight} className='faQuoteRight' />
                                                    </Card.Body>


                                                    <Card.Footer className="DailyIns-Card-footer">

                                                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 10, paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}>
                                                            <div>
                                                                {/* <span><FontAwesomeIcon icon={faHeart} style={{ color: 'red' }} /> &nbsp; 231</span>
                                                                <span><FontAwesomeIcon icon={faRetweet} style={{ color: 'green' }} /> &nbsp; 23</span> */}
                                                            </div>

                                                            <div className='AI-Card-footer-right'>
                                                                {/* <button onClick={() => setContext({ ...context, t: { ...context.t, text: option } })} className='useTweet-button'> */}
                                                                <button onClick={() => handleTrendingTweetContext(option)} className='useTweet-button'>
                                                                    Edit & Post
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </Card.Footer>

                                                </Card>
                                            ))
                                            }
                                        </Masonry>
                                    </div>
                                ) : (
                                    <div className='text-center'>
                                        {/* <h5>No data available</h5> */}
                                    </div>
                                )}
                    </Row>









                </div>


                {/* <div>
                 
                    {threads.map((text, index) => (
                        <div key={index}>
                            <p>Text: {text.text}</p>
                        </div>
                    ))}


                  


                    <textarea
                        value={threads.map((thread) => thread.text).join('\n\n')}
                        onChange={(e) => {
                            const newTexts = e.target.value.split('\n\n');
                            setThreads((prevThreads) => {
                                return prevThreads.map((thread, index) => ({
                                    text: newTexts[index] || '', // Use empty string if no value
                                }));
                            });
                        }}
                    />

                
                    <button onClick={handleAddThread}>Add Thread</button>
                </div> */}







            </Container>
            {/* <ToastContainer /> */}
        </div>
    )
}
