import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Container, Row, Col, Form, Modal } from 'react-bootstrap'
import Select from "react-select";
import Slider from '@mui/material/Slider';
import './style.css'
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCreditCard, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import Fontawesome from '../icons/icon-fontawesome-5';
import moment from 'moment';
import { MySideBarContext, MyContext, MyThemeContext } from '../../../components/MyQueue/MyContext'
import LinkPreviewComponent from './ImageLink';
import { BASE_URL } from '../../../utilities/Config';
import Microlink from '@microlink/react';
import Switch from '@mui/material/Switch';


// import { ReactTitle } from 'react-meta-tags';
import MetaTags from 'react-meta-tags';
// import ReactLinkPreview from 'react-link-preview';

// import { getPreview } from 'react-link-preview';
// import linkPreview from 'react-link-preview';

import linkPreview from 'link-preview-js';





export default function Settings() {
    const [context, setContext] = useContext(MyContext);
    // const [themeContext, setThemeContext] = useContext(MyThemeContext)
    const [email, setEmail] = useState()
    const [bio, setBio] = useState()
    const [timeZone, setTimeZone] = useState([])
    const [iam, setIam] = useState()
    const [selectedTimeZone, setSelectedTimeZone] = useState("");
    const [selectedValue, setSelectedValue] = useState([]);
    const [interestList, setInterestlist] = useState([])
    const [displayProfileDetails, setDisplayProfileDetails] = useState()
    const [sliderValue, setSliderValue] = useState(0.1);
    const [mainTopics, setMainTopics] = useState('')
    const [displayPlan, setDisplayPlan] = useState("")
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState([])
    const emailTo = localStorage.getItem('email-to')
    const Language = ["English",
        "Spanish",
        "French",
        "Albanian",
        "German",
        "Portuguese",
        "Vietnamese",
        "Italian",
        "Dutch",
        "Hindi",
        "Ukrainian",
        "Russian",
        "Arabic",
        "Chinese"]

    const navigate = useNavigate()


    const emailSwitch = localStorage.getItem('emailSwitch')


    const [checked, setChecked] = React.useState(true);

    // const handleChangeTheme = (event) => {
    //     setChecked(event.target.checked);
    //     setThemeContext({ theme: event.target.checked })
    //     localStorage.setItem('themeSwitch', event.target.checked)
    // };

    console.log("jksjbswbj", checked)




    // const [url, setUrl] = useState('');
    // const [data, setData] = useState(null);


    // const handleInputChange = (e) => {
    //     setUrl(e.target.value);
    // };


    // const handleFetchPreview = async () => {
    //     try {
    //         const previewData = await linkPreview(url);
    //         setData(previewData);
    //     } catch (error) {
    //         console.error('Error fetching link preview:', error);
    //     }
    // };

    const [previewData, setPreviewData] = useState(null);
    const [loading, setLoading] = useState(true);


    //const url = "https://dev.to/rahulj9a/how-to-build-simple-link-preview-without-any-library-in-js-2j84"
    //const url = "https://www.youtube.com/watch?v=n6hCF5xeU0I"
    //const url = "https://www.npmjs.com/package/react-meta-tags"
    //const url = "https://mui.com/material-ui/react-select/"
    const url = "https://codepen.io/alexcarpenter/pen/JBoBLZ"








    const userID = localStorage.getItem('UserID')
    const tokenLogin = localStorage.getItem('accessToken')
    const providerToken = localStorage.getItem('providerToken')

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const handleTimeZoneChange = (event) => {
        setSelectedTimeZone(event.target.value);

    };

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);

    };
    const handleChange = (e) => {
        let val = e.map((v) => v.value);

        setSelectedValue((prevValue) => {
            const uniqueValues = [...new Set([...prevValue, ...val])];
            console.log("cd", uniqueValues)
            return uniqueValues;

        });


    };

    const handleIAmChange = (e) => {
        setMainTopics(e.target.value)

    }




    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };


    console.log("hello silder", sliderValue)


    const removeSelectedValue = (value) => {
        setSelectedValue((prevValue) => prevValue.filter((v) => v !== value));
    };


    const [theme, setTheme] = useState('')

    // email GET

    useEffect(() => {
        displayProfile()
        displayTimeZone()
        displayKeyWords()
        displaySubscriptionDetails()
        const time = localStorage.getItem("TimeZone")
        setSelectedTimeZone(time)


    }, [])
    const themeSelect = localStorage.getItem('themeSwitch')



    console.log("tehme", themeSelect)




    //live url -----

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const data = await response.text();
                const parser = new DOMParser();
                const doc = parser.parseFromString(data, 'text/html');
                const title = doc.querySelector('title')?.textContent || '';
                const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || '';
                const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';

                setPreviewData({ title, description, image });
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);



    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(url);
    //             const data = await response.text();

    //             const isYouTubeVideo = isYouTubeURL(url);
    //             if (isYouTubeVideo) {
    //                 const videoId = extractYouTubeVideoId(url);
    //                 const videoThumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

    //                 setPreviewData({
    //                     videoId,
    //                     videoThumbnail,
    //                 });
    //                 setLoading(false);
    //             } else {
    //                 const parser = new DOMParser();
    //                 const doc = parser.parseFromString(data, 'text/html');
    //                 const title = doc.querySelector('title')?.textContent || '';
    //                 const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || '';
    //                 const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';

    //                 setPreviewData({
    //                     title,
    //                     description,
    //                     image,
    //                 });
    //                 setLoading(false);
    //             }
    //         } catch (error) {
    //             console.error(error);
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, [url]);

    // const isYouTubeURL = (url) => {
    //     return url.includes('youtube.com') || url.includes('youtu.be');
    // };

    // const extractYouTubeVideoId = (url) => {
    //     const videoIdRegex = /(?:\/embed\/|\/watch\?v=|\/(?:embed\/|v\/|watch\?.*v=|youtu\.be\/|embed\/|v=))([^&?#]+)/;
    //     const match = url.match(videoIdRegex);
    //     return match ? match[1] : '';
    // };

    // if (loading) {
    //     return <p>Loading...</p>;
    // }

    // if (!previewData) {
    //     return <p>Failed to fetch link preview.</p>;
    // }

    // const handleClick = () => {
    //     window.open(url, '_blank');
    // };

    // if (previewData.videoId) {
    //     return (
    //         <div onClick={handleClick} style={{ cursor: 'pointer' }}>
    //             <img src={previewData.videoThumbnail} alt="Video Thumbnail" />
    //         </div>
    //     );
    // }



    //live url----

    const displayProfile = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profile", data.data.user);
                    if (data.code == 200) {

                        setDisplayProfileDetails(data.data.user)
                        setEmail(data.data.user.email)
                        setBio(data.data.user.bio)
                        setSliderValue(data.data.user.freedom)
                        setSelectedTimeZone(data.data.user.timezone)
                        setMainTopics(data.data.user.iam)
                        setSelectedLanguage(data.data.user.ai_language)


                        setSelectedCategories(data?.data?.user?.interest)
                        // setSelectedValue(data.data.user.interest)
                        if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {
                            // alert("subscribed")
                        }
                        else {
                            navigate('/app/payments')
                        }



                    }
                    else {
                        setDisplayProfileDetails([])


                        console.log("failed")


                    }
                }
                else {
                    console.log("error in failed login")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profile", data.data.user);
                    if (data.code == 200) {

                        setDisplayProfileDetails(data.data.user)
                        setEmail(data.data.user.email)
                        setBio(data.data.user.bio)
                        setSliderValue(data.data.user.freedom)
                        setSelectedTimeZone(data.data.user.timezone)
                        setMainTopics(data.data.user.iam)
                        setSelectedLanguage(data.data.user.ai_language)


                        setSelectedCategories(data?.data?.user?.interest)
                        // setSelectedValue(data.data.user.interest)

                        if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {
                            // alert("subscribed")
                        }
                        else {
                            navigate('/app/payments')
                        }


                    }
                    else {
                        setDisplayProfileDetails([])


                        console.log("failed")


                    }
                }
                else {
                    console.log("error in failed login")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };


    const displayKeyWords = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/interest/keyword`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profile----", data);
                    if (data.code == 200) {

                        setInterestlist(data.data.interestList)

                    }
                    else {

                        setInterestlist([])

                        console.log("failed")


                    }
                }
                else {

                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/interest/keyword`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profile----", data);
                    if (data.code == 200) {

                        setInterestlist(data.data.interestList)

                    }
                    else {

                        setInterestlist([])

                        console.log("failed")


                    }
                }
                else {

                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };


    const displaySubscriptionDetails = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/retrieve/subscription/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published-subcs", data);
                    if (data.code == 200) {
                        setDisplayPlan(data.data)


                    }
                    else {
                        toast.error(data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });


                        console.log("failed")


                    }
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    const response = await fetch(`${BASE_URL}api/retrieve/subscription/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published-subcs", data);
                    if (data.code == 200) {
                        setDisplayPlan(data.data)


                    }
                    else {
                        toast.error(data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });


                        console.log("failed")


                    }
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    }

    // email POST

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);


                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey, email } = targetUser;

                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('email', email);



                    fetch(`${BASE_URL}api/save/email`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("email data", data);
                        if (data.code == 200) {




                        }


                        else {

                            console.log("failed hello")
                        }

                    })
                }
                else {
                    console.log("error in falied")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);


                if (targetUser) {
                    const { userID, accessToken, providerToken, } = targetUser;

                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('email', email);



                    fetch(`${BASE_URL}api/save/email`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("email data", data);
                        if (data.code == 200) {

                        }


                        else {

                            console.log("failed hello")
                        }

                    })
                }
                else {
                    console.log("error in falied")
                }



            } catch (error) {
                console.log(error)
            }
        }
    }

    //TimeZone GET

    const displayTimeZone = () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    fetch(`${BASE_URL}api/get/timezone`, {
                        method: 'GET',

                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {

                        console.log("timense-----", response)
                        return response.json();
                    }).then(data => {
                        console.log("time", data);
                        setTimeZone(data.data)
                        if (data.code == 200) {
                            console.log("success")
                        }
                        else {
                            console.log("failed")
                        }

                    })
                }
                else {
                    console.log("error in login falied")
                }



            } catch (error) {
                console.log(error.message);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    fetch(`${BASE_URL}api/get/timezone`, {
                        method: 'GET',

                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }).then(response => {

                        console.log("timense-----", response)
                        return response.json();
                    }).then(data => {
                        console.log("time", data);
                        setTimeZone(data.data)
                        if (data.code == 200) {
                            console.log("success")
                        }
                        else {
                            console.log("failed")
                        }

                    })
                }
                else {
                    console.log("error in login falied")
                }



            } catch (error) {
                console.log(error.message);
            }
        }


    }


    //Time zone POST

    const handleTimeSubmit = (e) => {
        e.preventDefault();
        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('timezone', selectedTimeZone);



                    fetch(`${BASE_URL}api/save/timezone`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("timezone data", data);
                        if (data.code == 200) {


                            toast.success("Time added succesfully", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            localStorage.setItem("TimeZone", selectedTimeZone)
                        }


                        else {
                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });


                        }

                    })
                }
                else {
                    console.log("error in valid")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('timezone', selectedTimeZone);



                    fetch(`${BASE_URL}api/save/timezone`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("timezone data", data);
                        if (data.code == 200) {


                            toast.success("Time added succesfully", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            localStorage.setItem("TimeZone", selectedTimeZone)
                        }


                        else {
                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });


                        }

                    })
                }
                else {
                    console.log("error in valid")
                }



            } catch (error) {
                console.log(error)
            }
        }
    }

    //Language POST

    const handleLanguageSubmit = (e) => {
        e.preventDefault();

        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('language', selectedLanguage);



                    fetch(`${BASE_URL}api/save/ai/language`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("timezone data", data);
                        if (data.code == 200) {


                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            displayProfile()
                        }


                        else {
                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });


                        }

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('language', selectedLanguage);



                    fetch(`${BASE_URL}api/save/ai/language`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("timezone data", data);
                        if (data.code == 200) {


                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            displayProfile()
                        }


                        else {
                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });


                        }

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }
    }


    //Bio POST
    const handleBioForm = (e) => {
        e.preventDefault()
        if (localStorage.getItem("userloggedin") === 'true') {

            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('bio', bio);



                    fetch(`${BASE_URL}api/save/bio`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("timezone data", data);
                        if (data.code == 200) {
                            toast.success("Description added succesfully", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            // localStorage.setItem("TimeZone", selectedTimeZone)
                        }


                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('bio', bio);



                    fetch(`${BASE_URL}api/save/bio`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("timezone data", data);
                        if (data.code == 200) {
                            toast.success("Description added succesfully", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            // localStorage.setItem("TimeZone", selectedTimeZone)
                        }


                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleSilderPost = (e) => {
        e.preventDefault()
        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('freedom', sliderValue);


                    fetch(`${BASE_URL}api/save/freedom`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("slider data", data);
                        if (data.code == 200) {




                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            // setSliderValue(data.data.freedom)

                        }
                        else if (data.code == 400) {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }


                        else {


                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('freedom', sliderValue);


                    fetch(`${BASE_URL}api/save/freedom`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response email", response)
                        return response.json();
                    }).then(data => {
                        console.log("slider data", data);
                        if (data.code == 200) {




                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            // setSliderValue(data.data.freedom)

                        }
                        else if (data.code == 400) {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }


                        else {


                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleMainTopics = (e) => {
        console.log("main", mainTopics)
        e.preventDefault()
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('topic', mainTopics);

                    fetch(`${BASE_URL}api/save/topics`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response topics", response)
                        return response.json();
                    }).then(data => {
                        console.log("topics data", data);
                        if (data.code == 200) {


                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            //  setMainTopics(data.data)


                        }


                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                        }

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('topic', mainTopics);

                    fetch(`${BASE_URL}api/save/topics`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response topics", response)
                        return response.json();
                    }).then(data => {
                        console.log("topics data", data);
                        if (data.code == 200) {


                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            //  setMainTopics(data.data)


                        }


                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                        }

                    })
                }
                else {
                    console.log("error")
                }



            } catch (error) {
                console.log(error)
            }
        }
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#eeeeee",
            border: "1px solid #ced4da",
            borderRadius: "10px",
            minHeight: "35px",
            height: "40px",
            width: "100%",
            marginBottom: "15px",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            display: "block", // Hide the selected values
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            display: "none", // Hide the remove button for selected values
        }),
        multiValueRemove: (base, state) => ({
            ...base,
            display: 'none', // Hide the cross button
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: "rgb(131, 145, 161)",
                fontFamily: "urbanist",
                fontSize: "1rem",
                fontWeight: "400",
                justifyContent: "flex-start",
            };
        },
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            color: "#000000",
            fontFamily: "poppins",
            fontSize: "16px",
            fontWeight: "400",
        }),
        MenuList: (base) => ({
            ...base,
            // zIndex: 9999,
            color: "#000000",
            fontFamily: "poppins",
            fontSize: "16px",
            fontWeight: "400",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: "30px",
            padding: "0 6px",
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
    };
    const buttonStyles = {
        color: "#ffffff",

    };

    useEffect(() => {
        const storedData = localStorage.getItem("data3");
        if (storedData) {
            // setSelectedValue(storedData.split(","));
            const parsedData = storedData.split(",");
            setSelectedValue(parsedData || []);

            console.log("setoooo[-----", storedData);
        }

    }, []);

    const handleKeyWords = (e) => {
        e.preventDefault();
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    // const myStrring = selectedValue.toString()
                    const myStrring = selectedCategories.toString()
                    console.log("myString", myStrring, selectedCategories)
                    let fdata = new FormData();

                    fdata.append('keyword', myStrring);
                    fdata.append('id', userID);


                    fetch(`${BASE_URL}api/save/keyword`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response interest", response)
                        return response.json();
                    }).then(data => {
                        console.log("data interes--", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });


                            if (selectedValue.length == 0) {
                                setSelectedValue([])
                                // localStorage.setItem("data3", selectedValue)
                                console.log("0000", selectedValue)
                            }
                            else {
                                // localStorage.setItem("data3", String(data.data.interest))
                            }




                        }

                        else {

                            toast.error(data?.data?.keyword[0], {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("error in login")
                }






            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    // const myStrring = selectedValue.toString()
                    const myStrring = selectedCategories.toString()
                    console.log("myString", myStrring, selectedCategories)
                    let fdata = new FormData();

                    fdata.append('keyword', myStrring);
                    fdata.append('id', userID);


                    fetch(`${BASE_URL}api/save/keyword`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response interest", response)
                        return response.json();
                    }).then(data => {
                        console.log("data interes--", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });


                            if (selectedValue.length == 0) {
                                setSelectedValue([])
                                // localStorage.setItem("data3", selectedValue)
                                console.log("0000", selectedValue)
                            }
                            else {
                                // localStorage.setItem("data3", String(data.data.interest))
                            }




                        }

                        else {

                            toast.error(data?.data?.keyword[0], {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("error in login")
                }






            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleKeyWords2 = (e) => {
        e.preventDefault();
        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    console.log("myString", myStrring, selectedCate)
                    // const myStrring = selectedValue.toString()
                    const myStrring = selectedCate.toString()

                    let fdata = new FormData();

                    fdata.append('keyword', myStrring);
                    fdata.append('id', userID);


                    fetch(`${BASE_URL}api/save/keyword`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response interest", response)
                        return response.json();
                    }).then(data => {
                        console.log("data interes--", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            displayProfile()

                            if (selectedValue.length == 0) {
                                setSelectedValue([])
                                // localStorage.setItem("data3", selectedValue)
                                console.log("0000", selectedValue)
                            }
                            else {
                                // localStorage.setItem("data3", String(data.data.interest))
                            }




                        }

                        else {

                            toast.error(data?.data?.keyword[0], {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("error in login")
                }






            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    console.log("myString", myStrring, selectedCate)

                    // const myStrring = selectedValue.toString()
                    const myStrring = selectedCate.toString()

                    let fdata = new FormData();

                    fdata.append('keyword', myStrring);
                    fdata.append('id', userID);


                    fetch(`${BASE_URL}api/save/keyword`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response interest", response)
                        return response.json();
                    }).then(data => {
                        console.log("data interes--", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            displayProfile()

                            if (selectedValue.length == 0) {
                                setSelectedValue([])
                                // localStorage.setItem("data3", selectedValue)
                                console.log("0000", selectedValue)
                            }
                            else {
                                // localStorage.setItem("data3", String(data.data.interest))
                            }




                        }

                        else {

                            toast.error(data?.data?.keyword[0], {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("error in login")
                }






            } catch (error) {
                console.log(error)
            }
        }
    }





    const MultiValueRemove = () => {

    };
    const options = interestList.map((value) => ({ value, label: value }))






    const handleDeletAccount = (e) => {
        e.preventDefault()

        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

                // Specify the email you want to find


                // Find the user with the target email
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken } = targetUser;
                    let fdata = new FormData();


                    fdata.append('provider_token', providerToken);

                    fetch(`${BASE_URL}api/delete/account`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response delete", response)
                        return response.json();
                    }).then(data => {
                        console.log("delete data", data);
                        if (data.code == 200) {

                            toast.success("Account deleted successfully", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            window.location.href = '/';
                            // navigate('/')
                            localStorage.removeItem('accessToken')
                            localStorage.removeItem('UserID')
                            localStorage.removeItem('providerToken')
                            localStorage.removeItem('oAuthToken')
                            localStorage.removeItem('TimeZone')
                            localStorage.removeItem('date2')
                            localStorage.removeItem('date3')
                            localStorage.removeItem('userInterestForm')
                            localStorage.removeItem('selectedCategories')
                            localStorage.removeItem('savedValue')


                            setShow(false)
                        }
                        else if (data.code == 500) {
                            // navigate('/')
                            window.location.href = '/';
                            localStorage.removeItem('accessToken')
                            localStorage.removeItem('UserID')
                            localStorage.removeItem('providerToken')
                            localStorage.removeItem('oAuthToken')
                            localStorage.removeItem('TimeZone')
                            localStorage.removeItem('date2')
                            localStorage.removeItem('date3')
                            localStorage.removeItem('userInterestForm')
                            localStorage.removeItem('selectedCategories')
                            localStorage.removeItem('savedValue')


                            setShow(false)
                        }


                        else {

                            console.log("failed hello")
                        }

                    })

                }
                else {
                    console.log("eorornrk")
                }

            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken } = targetUser;
                    let fdata = new FormData();


                    fdata.append('provider_token', providerToken);

                    fetch(`${BASE_URL}api/delete/account`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response delete", response)
                        return response.json();
                    }).then(data => {
                        console.log("delete data", data);
                        if (data.code == 200) {

                            toast.success("Account deleted successfully", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            window.location.href = '/';
                            // navigate('/')
                            localStorage.removeItem('accessToken')
                            localStorage.removeItem('UserID')
                            localStorage.removeItem('providerToken')
                            localStorage.removeItem('oAuthToken')
                            localStorage.removeItem('TimeZone')
                            localStorage.removeItem('date2')
                            localStorage.removeItem('date3')
                            localStorage.removeItem('userInterestForm')
                            localStorage.removeItem('selectedCategories')
                            localStorage.removeItem('savedValue')


                            setShow(false)
                        }
                        else if (data.code == 500) {
                            // navigate('/')
                            window.location.href = '/';
                            localStorage.removeItem('accessToken')
                            localStorage.removeItem('UserID')
                            localStorage.removeItem('providerToken')
                            localStorage.removeItem('oAuthToken')
                            localStorage.removeItem('TimeZone')
                            localStorage.removeItem('date2')
                            localStorage.removeItem('date3')
                            localStorage.removeItem('userInterestForm')
                            localStorage.removeItem('selectedCategories')
                            localStorage.removeItem('savedValue')


                            setShow(false)
                        }


                        else {

                            console.log("failed hello")
                        }

                    })

                }
                else {
                    console.log("eroirororo logon")
                }

            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleRecipt = async (e) => {
        e.preventDefault()
        navigate('/app/Receiptdownload')

        // try {
        //     const response = await fetch(`https://app.tweetnow.ai/TweetNow/api/download/receipt/${userID}`, {
        //   const response = await fetch(`https://app.tweetnow.ai/TweetNow/api/receipt/{id}`, {
        //         headers: {
        //             Authorization: `Bearer ${tokenLogin}`,
        //             'Content-Type': 'application/json'
        //         }
        //     });
        //     const data = await response.json();
        //     console.log("published/receipt", data);


        // } catch (error) {
        //     console.error('Error fetching buyer data:', error);
        // }

    }


    const [showPayment, setShowPayment] = useState(false);

    const handleClosePayment = () => setShowPayment(false);
    const handleShowPayment = () => setShowPayment(true);


    const handleDeleteSubscription = () => {
        // setShowPayment(true)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#284E79',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (localStorage.getItem("userloggedin") === 'true') {
                    try {
                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                        const targetUser = userDataArray.find(user => user.email === emailTo);

                        if (targetUser) {
                            const { userID, accessToken, providerToken } = targetUser;

                            let fdata = new FormData();
                            fdata.append('provider_token', providerToken);

                            const response = await fetch(`${BASE_URL}api/cancel/subscription`, {
                                method: 'POST',
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            });

                            const data = await response.json();
                            console.log("subscription plan", data);

                            if (data.code === 200) {
                                //  displaySubscriptionDetails()
                                // Swal.fire(
                                //     data.message
                                // )
                                toast.success(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                //  window.location.reload()

                            }
                            else if (data.code == 400) {
                                // Swal.success(
                                //     data.message
                                // )
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });

                            }

                            else {
                                Swal.fire(
                                    data.error_message

                                )
                            }
                        }
                        else {
                            console.log("erororo lkala")
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                else {
                    try {
                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                        const targetUser = userDataArray.find(user => user.email === emailSwitch);

                        if (targetUser) {
                            const { userID, accessToken, providerToken } = targetUser;

                            let fdata = new FormData();
                            fdata.append('provider_token', providerToken);

                            const response = await fetch(`${BASE_URL}api/cancel/subscription`, {
                                method: 'POST',
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            });

                            const data = await response.json();
                            console.log("subscription plan", data);

                            if (data.code === 200) {
                                //  displaySubscriptionDetails()
                                // Swal.fire(
                                //     data.message
                                // )
                                displayProfile()
                                toast.success(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                //setDisplayPlan(data.data)
                                window.location.reload()




                            }
                            else if (data.code == 400) {
                                // Swal.success(
                                //     data.message
                                // )
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });

                            }

                            else {
                                Swal.fire(
                                    data.error_message

                                )
                            }
                        }
                        else {
                            console.log("erororo lkala")
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }


            }
        })
    }


    // const handleCancelModal = async (e) => {
    //     e.preventDefault()
    //     try {
    //         let fdata = new FormData();


    //         fdata.append('provider_token', providerToken);

    //         fetch('https://app.tweetnow.ai/TweetNow/api/cancel/subscription', {
    //             method: 'POST',
    //             body: fdata,
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //             }
    //         }).then(response => {

    //             console.log("response email", response)
    //             return response.json();
    //         }).then(data => {
    //             console.log(" subscription plan", data);
    //             if (data.code == 200) {
    //                 toast.success(data.message, {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });

    //             }


    //             else {

    //                 toast.error(data.error_message, {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });
    //             }

    //         })



    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handleCancelModal = async (e) => {
        e.preventDefault();
        try {
            let fdata = new FormData();
            fdata.append('provider_token', providerToken);

            const response = await fetch(`${BASE_URL}api/cancel/subscription`, {
                method: 'POST',
                body: fdata,
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                },
            });

            const data = await response.json();
            console.log("subscription plan", data);

            if (data.code === 200) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.error(data.error_message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const upgradePlan = () => {
        navigate('/app/payments')
    }





    // useEffect(() => {
    //     const storedCategories = JSON.parse(localStorage.getItem('selectedCategories')) || [];
    //     setSelectedCategories(storedCategories);
    // }, []);


    const [interestCategories, setInterestCategories] = useState([]);

    useEffect(() => {
        const tokenLogin = localStorage.getItem('accessToken'); // Replace this with your actual access token
        // fetchInterestCategories(tokenLogin);
        fetchInterestCategories();
    }, []);

    // const fetchInterestCategories = (tokenLogin) => {
    //     try {
    //         fetch(`${BASE_URL}api/interest/keyword`, {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //             .then(response => response.json())
    //             .then(data => {
    //                 if (data.code === 200) {
    //                     setInterestCategories(data.data.interestList);
    //                 }
    //             });
    //     } catch (error) {
    //         console.log(error.message);
    //     }
    // };

    const fetchInterestCategories = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {


            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);


                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    await fetch(`${BASE_URL}api/interest/keyword`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.code === 200) {
                                setInterestCategories(data.data.interestList);
                            }
                        });
                }
                else {
                    console.log("erirororo")
                }

            } catch (error) {
                console.log(error.message);
            }

        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);


                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    await fetch(`${BASE_URL}api/interest/keyword`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.code === 200) {
                                setInterestCategories(data.data.interestList);
                            }
                        });
                }
                else {
                    console.log("erirororo")
                }

            } catch (error) {
                console.log(error.message);
            }
        }
    }


    console.log("interes", interestCategories)
    const [selectedCate, setSelectedCate] = useState([])
    const [customValue, setCustomValue] = useState('');
    const [selectedCategory2, setSelectedCategory] = useState('');

    // const handleCategoryChange = (event) => {
    //     const selectedCategory = event.target.value;
    //     if (selectedCategory === '__custom__') {

    //         setCustomValue('');
    //         setSelectedCategory(selectedCategory);
    //     } else {
    //         setSelectedCategory(selectedCategory);

    //         if (selectedCategories.length < 100 && !selectedCategories.includes(selectedCategory)) {
    //             const updatedCategories = [...selectedCategories, selectedCategory];
    //             setSelectedCategories(updatedCategories);
    //             localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
    //         }
    //     }

    // };


    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;

        if (selectedCategory === '__custom__') {
            setCustomValue('');
            setSelectedCategory(selectedCategory);
        } else {
            const trimmedCategory = selectedCategory.trim();
            setSelectedCategory(trimmedCategory);

            if (selectedCategories.length < 100 && !selectedCategories.includes(trimmedCategory)) {
                const updatedCategories = [...selectedCategories, trimmedCategory];
                setSelectedCategories(updatedCategories);
                localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
            }
        }
    };

    const handleCategoryChange2 = (event) => {
        const selectedCategory = event.target.value;
        if (selectedCategories.length < 100 && !selectedCategories.includes(selectedCategory)) {
            const updatedCategories = [...selectedCategories, selectedCategory];

            setSelectedCate(updatedCategories)
        }
    };


    console.log("slected calete", selectedCate, selectedCategory2)



    const handleCategoryRemove = (categoryToRemove) => {
        const updatedCategories = selectedCategories.filter(category => category !== categoryToRemove);
        setSelectedCategories(updatedCategories);

        const updatedCategories2 = selectedCate.filter(category => category !== categoryToRemove);
        setSelectedCate(updatedCategories2);
        localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
    };

    const availableCategories = interestCategories.filter(category => !selectedCategories.includes(category));
    const noOptionsLeft = availableCategories.length === 0;




    // const handleDownload = () => {
    //     try {

    //         fetch(`https://app.tweetnow.ai/TweetNow/api/export/${userID}`, {
    //             method: 'GET',

    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         }).then(response => {

    //             console.log("download repsinse", response)
    //             return response.json();
    //         }).then(data => {
    //             console.log("download", data);


    //         })



    //     } catch (error) {
    //         console.log(error.message);
    //     }

    // }
























    console.log("published-subcs", displayPlan)














    const [contextData, setContextData] = useContext(MySideBarContext)
    console.log("contextData", contextData)
    const [dataLoading, setDataLoading] = useState(false)





    const currentDat = new Date();
    const initialDate = currentDat.toLocaleDateString('en-GB');
    const startDate = moment(initialDate, 'DD-MM-YYYY');
    const [currentMonth, setCurrentMonth] = useState(startDate.month());
    const year = startDate.year();
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalText, setModalText] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [sectionTexts, setSectionTexts] = useState([]);
    const [time, setTime] = useState([
        '2:30 PM', '4:23 AM', '5:34 PM'
    ])
    const [dateTimeList, setDateTimeList] = useState([])


    const [dynamictime, setDynamicTime] = useState([])
    const [dynamicQueue, setDynamicQueue] = useState([])
    const [liked, setLiked] = useState(false);
    const [retweet, setReTweet] = useState(false);


    useEffect(() => {


        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };


        if (contextData.QUEUESaved == true) {


            fetchData();
        }
        console.log(contextData.QUEUESaved, 'Queueu SAVED');
    }, [contextData.QUEUESaved])


    useEffect(() => {

        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };

        fetchData();

    }, [])



    const myQueuueTimeSlotDynanmic = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)
                    setDataLoading(false)
                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)
                    setDataLoading(false)
                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };

    const displayDynamicQueue = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)
                        setContextData({ QUEUESaved: false })

                    }
                    setDataLoading(false)
                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)
                        setContextData({ QUEUESaved: false })

                    }
                    setDataLoading(false)
                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };





    // const myQueuueTimeSlotDynanmic = async () => {
    //     try {
    //         setDataLoading(true)
    //         const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published/tweet/list", data.data.timeslot);
    //         setDynamicTime(data.data.timeslot)
    //         setDataLoading(false)

    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }
    // };


    // const displayDynamicQueue = async () => {
    //     try {
    //         setDataLoading(true)
    //         const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published/queue", data);
    //         if (data.code == 200) {
    //             setDynamicQueue(data.data)
    //             setContextData({ QUEUESaved: false })

    //         }
    //         setDataLoading(false)


    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }
    // };


    console.log("dynamic-queue-1", dynamicQueue);



    //THIS IS THE ONE ---------
    useMemo(() => {
        let timeIndex = -1;
        let availableSlot = null;
        // let dateIndex = dateTimeList.findIndex(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
        // console.log(dateIndex, moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'), 'asdsad');
        // if(dateTimeList[dateIndex]){
        //     timeIndex = dateTimeList[dateIndex].time.findIndex(obj => obj.time === contextData?.handleTime)
        // }
        // if (dateTimeList[dateIndex] && dateTimeList[dateIndex].time[timeIndex]){
        // dateTimeList[dateIndex].time[timeIndex].text = contextData?.tweetText
        // dateTimeList[dateIndex].time[timeIndex].image = contextData?.selectedImages
        // dateTimeList[dateIndex].time[timeIndex].gif = contextData?.selectedGifpicker
        //     setDateTimeList([...dateTimeList])
        // }



        if (contextData?.handleDate) {
            let dateFilter = dateTimeList.filter(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
            console.log("dvdsv", dateFilter)
            dateFilter.map(el => {

                timeIndex = el.time.findIndex(obj => obj.time === contextData?.handleTime)

                if (el && el.time[timeIndex]) {
                    el.time[timeIndex].text = contextData?.tweetText
                    el.time[timeIndex].image = contextData?.selectedImages
                    el.time[timeIndex].gif = contextData?.selectedGifpicker
                }

            }

            )
            setDateTimeList([...dateTimeList])
            console.log(dateTimeList, 'dateTimeLista');
            let list = dateTimeList;

            for (let i = 0; i < list.length; i++) {
                const currentTime = moment();
                const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY h:mm A').format('DD/MM/YYYY')
                let blankTextInd = list[i].time.findIndex(obj => obj.text === '');
                if (blankTextInd > -1) {
                    if (list[i] && list[i].time && list[i].time[blankTextInd]?.time && moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY h:mm A').isSameOrAfter(currentTime)) {
                        console.log('future time');
                        const timeFirst = list[i].time[blankTextInd]
                        console.log("!st,,,", timeFirst)
                        setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                        break;
                    }
                }

                console.log(",,,", i);
            }
        } else {
            dateTimeList.some((el) => {
                console.log("e---l", el)
                const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
                if (timeIndex !== -1) {
                    el.time[timeIndex].text = contextData?.tweetText;
                    el.time[timeIndex].image = contextData?.selectedImages;
                    el.time[timeIndex].gif = contextData?.selectedGifpicker;

                    return true; // Stop iterating after updating the first available slot


                }
                return false;
            });

            //     dateTimeList.some((el) => {
            //                 const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
            //                 if (timeIndex !== -1) {
            //                   availableSlot = el.time[timeIndex];
            //                   el.time[timeIndex].text = contextData?.tweetText;
            //                   el.time[timeIndex].image = contextData?.selectedImages;
            //                   el.time[timeIndex].gif = contextData?.selectedGifpicker;
            //                   return true; // Stop iterating after updating the first available slot
            //                 }
            //                 return false;
            //               });

            //       setDateTimeList([...dateTimeList]);




        }
        //    console.log('Available Slot:', availableSlot);

    }, [contextData])
    console.log("contextData", contextData, dateTimeList);


    useEffect(() => {
        const updateCurrentMonth = () => {
            const newMonth = moment().month();
            if (newMonth !== currentMonth) {
                setCurrentMonth(newMonth);
            }
        };

        const intervalId = setInterval(updateCurrentMonth, 60000); // Check every minute for date update

        return () => {
            clearInterval(intervalId); // Clear interval on component unmount
        };
    }, [currentMonth]);



    useEffect(() => {
        console.log('currentDate');

        let list = []
        let currentDate = moment(startDate); // Create a moment object for currentDate
        const endMonth = currentMonth + 2; // Display three months at a time
        const sectionKey = currentDate.format('YYYY-MM-DD');
        const sectionText = sectionTexts[sectionKey] || '';
        console.log('currentDate', currentDate);



        while (currentDate.year() === year && currentDate.month() <= endMonth) {

            //const slotForDays = dynamictime.filter(el => el.weekdays.includes(currentDate.format('dddd')))
            const slotForDays = (dynamictime ?? []).filter(el => el.weekdays.includes(currentDate.format('dddd')));

            // const slottext = dynamicQueue.map(es => es.text)
            console.log(dynamictime, "---dynamictime", slotForDays, "---slotForDays", dynamicQueue, "dynamicQueue", currentDate.format('dddd'), 'asasas');
            let timeslotArr = slotForDays?.map(a => {
                // console.log(a.weekdays, 'aaaaaa');
                // const ATime = moment(a.timeslot,'hh:mm:ss').format('h:mm A ')
                const ATime = a.timeslot
                //const queueItem = dynamicQueue.findIndex(item => item.schedule_time === ATime && item.schedule_date === currentDate.format('D MMMM YYYY'));
                // console.log("qute", queueItem)
                let textValue = ""
                let GivValue = ""
                let ImageValue = ""
                let id = ""
                let is_thread = ""
                let tweet_id = ""
                let thread_tweet_id = ""
                if (dynamicQueue && dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text
                ) {
                    id = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].id
                    textValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text

                    GivValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].gif

                    ImageValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].image

                    console.log("images", ImageValue)

                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }

                else if (dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].text
                ) {
                    let dynamicArrayIndex = dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)
                    let dynamicArray = dynamicQueue[dynamicArrayIndex].map(element => {
                        id = element.id
                        textValue = element.text

                        GivValue = element.gif

                        ImageValue = element.image
                        is_thread = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].is_thread
                        tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].tweet_id
                        thread_tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].thread_tweet_id
                        console.log("is_thread", is_thread)
                        return {
                            id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : '', is_thread: is_thread, tweet_id: tweet_id, thread_tweet_id: thread_tweet_id
                        }

                    })
                    return dynamicArray;




                }
                else {
                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }

                // return {
                //     id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                // }

            }
            )

            const formattedDate = currentDate.format('dddd | MMMM D, YYYY');
            list.push({
                formattedDate: formattedDate,
                time: timeslotArr,
                sectionKey: sectionKey
            })
            currentDate.add(1, 'day'); // Move to the next day

        }

        setDateTimeList([...list])



        for (let i = 0; i < list.length; i++) {
            const currentTime = moment();
            const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY HH:mm').format('DD/MM/YYYY')
            let blankTextInd = list[i].time.findIndex(obj => obj?.text === '' &&
                moment(currentDate + " " + obj?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)
            );
            console.log("listFor", list[i], i, blankTextInd)
            if (blankTextInd > -1) {
                console.log("listFor, !time,,,", list[i].time[blankTextInd]?.time, currentDate, currentTime)
                // if (list[i] && list[i].time && list[i].time[blankTextInd]?.time &&
                //     moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)) {
                console.log('future time', list[i].formattedDate);
                const timeFirst = list[i].time[blankTextInd]
                console.log("listFor, !st,,,", timeFirst, list[i], blankTextInd, i)
                setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                break;
                // }
            }
            console.log(",,,", i, currentDate, currentTime);
        }






        // for (let i = 0; i < list.length; i++) {

        //     const currentTime = moment();
        //     const futureTimeSlotIndex = list[i].time.findIndex(
        //       (obj) => moment(obj.time, "h:mm A").isSameOrAfter(currentTime)
        //     );

        //     if (futureTimeSlotIndex > -1) {
        //       const timeFirst = list[i].time[futureTimeSlotIndex];
        //       console.log("!st,,,", timeFirst);
        //       setContext({ t: timeFirst, formattedDate: list[i].formattedDate });
        //       break;
        //     }
        //     console.log(",,,", i);
        //   }


    }, [dynamictime])

    console.log(dateTimeList, 'dateTimeList');



    return (

        // <div id='content-page' className='content-page' style={{ backgroundColor: checked == true ? 'black' : 'white', paddingLeft: 30 }}>
        <div id='content-page' className='content-page' style={{ backgroundColor: 'white', paddingLeft: 30 }}>
            {console.log("thenem", themeSelect)}
            <Container>
                <div className='setting-header-box'>
                    <h4>
                        Account settings
                    </h4>
                    <hr />
                </div>

                <div className='setting-2nd-box'>
                    <h5>Personal information</h5>
                    <span>Your main account information</span>


                    <div>
                        <form style={{ marginTop: 20 }} onSubmit={handleEmailSubmit}>
                            <label>Email Address</label>
                            <div className='setting-form-box'>
                                <Row>
                                    <Col lg={8} sm={8} md={8}>

                                        <input
                                            className='email-input'
                                            type="email"
                                            value={email}
                                            disabled
                                            onChange={(e) => setEmail(e.target.value)} />
                                    </Col>

                                </Row>

                            </div>
                        </form>

                        <form style={{ marginTop: 20 }} onSubmit={handleTimeSubmit}>
                            <label>Time Zone</label>
                            <div className='setting-form-box'>
                                <Row>
                                    <Col lg={8} sm={8} md={8}>

                                        <Form.Select aria-label="DefaultForm.Sselect example timezone-form"
                                            onChange={handleTimeZoneChange}
                                            value={selectedTimeZone}
                                            className='setting-select'>



                                            <option value={-1}>Select a timezone</option>
                                            {timeZone?.map(opt => (
                                                <option key={opt} value={opt}>
                                                    {opt}
                                                </option>

                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col lg={4} sm={4} md={4}>
                                        <button className='button-save' type="submit">Save</button>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </div>


                    <div className='Ai-Screen-box'>
                        <h5>AI settings</h5>
                        <span>Help the AI to generate better content for you</span>


                        <div>
                            <form style={{ marginTop: 20 }} onSubmit={handleKeyWords}>
                                <label> <span style={{ fontWeight: 900, color: 'black' }}>@{displayProfileDetails?.name}</span> search keywords</label>
                                <div className='setting-form-box'>
                                    <Row>
                                        <Col lg={8} sm={8} md={8}>
                                            {/* <Select
                                                styles={customStyles}
                                                className="dropdown"
                                                placeholder=""
                                                value={options.filter((obj) => selectedValue.includes(obj.value))}
                                                options={options}
                                                onChange={(e) => handleChange(e)}
                                                isMulti
                                                isClearable
                                                components={{ MultiValueRemove }} 

                                            /> */}

                                            <div>

                                                <Form.Select onChange={handleCategoryChange} className="select-interest" disabled={noOptionsLeft}>
                                                    <option value="">Select a category</option>
                                                    {availableCategories.map(category => (

                                                        <option
                                                            key={category}
                                                            value={category}
                                                            disabled={selectedCategories.includes(category)}
                                                        >{category}</option>
                                                    ))}
                                                    <option value="__custom__">Add Custom Value</option>
                                                </Form.Select>



                                            </div>





                                            <div className="form-display-data-setting">
                                                {selectedCategories.map(category => {
                                                    console.log("selcted", category, category.length)

                                                    return (
                                                        <Button
                                                            // variant="warning"
                                                            size="sm"
                                                            className="rounded-pill mb-1 display-data"
                                                            key={category}
                                                            styles={buttonStyles}
                                                        >
                                                            {category}
                                                            <FontAwesomeIcon
                                                                style={{ fontSize: "13px", cursor: "pointer", }}
                                                                onClick={() => handleCategoryRemove(category)}
                                                                className="cross-interest"
                                                                icon={faXmark}
                                                            />
                                                        </Button>

                                                    );
                                                })}


                                                {/* {selectedValue.map((s, i) => {

                                                    return (
                                                        <Button
                                                            variant="warning"
                                                            size="sm"
                                                            className="rounded-pill mb-1 display-data"
                                                            key={i}
                                                            styles={buttonStyles}
                                                        >
                                                            {selectedValue.length == 0 ? "" : s}
                                                            <FontAwesomeIcon
                                                                style={{ fontSize: "13px", cursor: "pointer", }}
                                                              
                                                                onClick={() => removeSelectedValue(s)}
                                                                className="cross-interest"
                                                                icon={faXmark}
                                                            />
                                                        </Button>

                                                    );
                                                })} */}

                                            </div>
                                        </Col>

                                        <Col lg={4} sm={4} md={4}>
                                            <button className='button-save' type="submit">Save</button>
                                        </Col>
                                    </Row>
                                </div>
                            </form>

                            {selectedCategory2 === '__custom__' && (

                                <form onSubmit={handleKeyWords2}>
                                    <label> <span style={{ fontWeight: 900, color: 'black' }}>@{displayProfileDetails?.name}</span> custom category</label>
                                    <div className='setting-form-box'>
                                        <Row>
                                            <Col lg={8} sm={8} md={8}>
                                                <input
                                                    className='email-input'
                                                    type="text"
                                                    placeholder='Enter custom category'

                                                    onChange={handleCategoryChange2}
                                                />
                                            </Col>

                                            <Col lg={4} sm={4} md={4}>
                                                <button className='button-save' type="submit">Save</button>
                                            </Col>
                                        </Row>
                                    </div>
                                </form>
                            )}






                            <form style={{ marginTop: 20 }} onSubmit={handleBioForm}>
                                <label> <span style={{ fontWeight: 900, color: 'black' }}>@{displayProfileDetails?.name}</span> personal description</label>
                                <div className='setting-form-box'>
                                    <Row>
                                        <Col lg={8} sm={8} md={8}>
                                            <input
                                                className='email-input'
                                                type="text"
                                                value={bio}
                                                onChange={(e) => setBio(e.target.value)}
                                            />
                                        </Col>

                                        <Col lg={4} sm={4} md={4}>
                                            <button className='button-save' type="submit">Save</button>
                                        </Col>
                                    </Row>
                                </div>
                            </form>

                            <form style={{ marginTop: 20 }} onSubmit={handleMainTopics}>

                                <label><span style={{ fontWeight: 900, color: 'black' }}>@{displayProfileDetails?.name}</span> I am</label>
                                <div className='setting-form-box'>
                                    <Row>
                                        <Col lg={8} sm={8} md={8}>

                                            <input
                                                className='email-input'
                                                type="text"
                                                value={mainTopics}

                                                onChange={(e) => handleIAmChange(e)}
                                            />
                                        </Col>
                                        <Col lg={4} sm={4} md={4}>
                                            <button className='button-save' type="submit">Save</button>
                                        </Col>
                                    </Row>
                                </div>
                            </form>


                            <form style={{ marginTop: 20 }} onSubmit={handleLanguageSubmit}>
                                <label>Select a default language</label>
                                <div className='setting-form-box'>
                                    <Row>
                                        <Col lg={8} sm={8} md={8}>

                                            <Form.Select aria-label="DefaultForm.Sselect example timezone-form"
                                                onChange={handleLanguageChange}
                                                value={selectedLanguage}
                                                className='setting-select'>



                                                <option value={-1}>Select a language</option>
                                                {Language?.map(opt => (
                                                    <option key={opt} value={opt}>
                                                        {opt}
                                                    </option>

                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col lg={4} sm={4} md={4}>
                                            <button className='button-save' type="submit">Save</button>
                                        </Col>
                                    </Row>
                                </div>
                            </form>

                            <form style={{ marginTop: 70 }} className='slider-ai' onSubmit={handleSilderPost}>
                                <div className='slider-text'>
                                    <p>Choose the freedom you want to give to the AI to come up with new ideas</p>
                                </div>
                                <Row>
                                    <Col lg={8} sm={8} md={8}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', top: -3 }}>
                                            <p>Conservative</p>
                                            <p>Wild</p>
                                        </div>

                                        <Slider
                                            defaultValue={0.0}
                                            aria-label="Default"
                                            valueLabelDisplay="auto"
                                            color="warning"
                                            size="large"
                                            className="slider-settings"
                                            value={sliderValue}
                                            onChange={handleSliderChange}
                                            step={0.1}
                                            max={2}
                                            min={0}
                                            style={{
                                                position: "relative",
                                                top: -20
                                            }}
                                        />
                                    </Col>

                                    <Col lg={4} sm={4} md={4}>
                                        <button className='button-save' type="submit" >Save</button>
                                    </Col>
                                </Row>


                            </form>


                        </div>
                        {/* <Row>
                            <Col lg={8} md={8} sm={8}>

                                <div>

                                    <select onChange={handleCategoryChange} className="select-interest" disabled={noOptionsLeft}>
                                        <option value="">Select a category</option>
                                        {availableCategories.map(category => (
                                            <option key={category} value={category}>{category}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-display-data-user-interset">


                                    {selectedCategories.map(category => {

                                        return (
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                className="rounded-pill mb-1 display-data"
                                                key={category}
                                                styles={buttonStyles}
                                            >
                                                {category}
                                                <FontAwesomeIcon
                                                    style={{ fontSize: "13px", cursor: "pointer", }}
                                                    onClick={() => handleCategoryRemove(category)}
                                                    className="cross-interest"
                                                    icon={faXmark}
                                                />
                                            </Button>

                                        );
                                    })}

                                </div>

                            </Col>

                            <Col>

                                <button onClick={handleKeyWords}>scdscs </button>

                            </Col>
                        </Row> */}


                    </div>

                    <div className='payment-plan-box'>
                        <div className='currentplan-'>
                            <Row>
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <div className='current-plan-text'>
                                        {displayPlan?.subscription_details?.subscription_status !== "canceled" ?
                                            <>
                                                <h4>Current Plan</h4>
                                                <h5>{displayPlan?.subscription_details?.price_value}</h5>
                                                <h5> Amount paid: <span style={{ color: '#284E79' }}>{displayPlan?.subscription_details?.amount_paid}</span></h5>
                                            </>
                                            :
                                            <h4>No current plans active</h4>
                                        }
                                        {/* {displayPlan == [] || displayPlan == undefined || displayPlan == '' || displayPlan == {} ?
                                            <>
                                                <h4>No active plan </h4>
                                            </>
                                            :
                                            <>
                                                <h4>Current Plan</h4>
                                                <h5>{displayPlan?.subscription_details?.price_value}</h5>
                                                <h5> Amount paid: <span style={{ color: '#284E79' }}>{displayPlan?.subscription_details?.amount_paid}</span></h5>
                                            </>
                                        } */}
                                    </div>

                                </Col>

                                <Col lg={4} md={4} sm={6} xs={6}>
                                    <div className='cancel-subs'>

                                        {/* {displayProfileDetails?.is_user_subscribed == 1 ? (
                                            <>
                                                <h4>Unsubscribe</h4>
                                                <button className='donwload-re'
                                                    onClick={handleDeleteSubscription}

                                                ><FontAwesomeIcon icon={faBan} /> Unsubscribe</button>
                                            </>
                                        ) : ''} */}

                                        {displayPlan?.subscription_details?.subscription_status !== "canceled" ? (
                                            <>
                                                <h4>Unsubscribe</h4>
                                                <button className='donwload-re'
                                                    onClick={handleDeleteSubscription}

                                                ><FontAwesomeIcon icon={faBan} /> Unsubscribe</button>
                                            </>
                                        ) : ''}
                                    </div>
                                </Col>

                                <Col lg={4} md={4} sm={6} xs={6}>
                                    <div className='upgrade-subs'>
                                        <h4>Upgrade</h4>
                                        <button className='donwload-re' onClick={upgradePlan}><FontAwesomeIcon icon={faCreditCard} /> Upgrade</button>
                                    </div>
                                </Col>

                            </Row>
                            {/* <div className='current-plan-text'>
                                <h4>Current Plan</h4>
                                <h5>{displayPlan.price_value}</h5>
                                <h5> Amount paid: <span style={{ color: '#ff9100' }}>{displayPlan.amount_paid}</span></h5>
                            </div>
                            <div>
                                <h4>Cancel Subscription</h4>
                                <button className='donwload-re' onClick={handleDeleteSubscription}><FontAwesomeIcon icon={faBan} /> Cancel Subscription</button>
                            </div>
                            <div>
                                <h4>Upgrade</h4>
                                <button className='donwload-re' onClick={upgradePlan}><FontAwesomeIcon icon={faCreditCard} /> Upgrade</button>
                            </div> */}

                        </div>

                    </div>


                    {/* <div className='Delete-box'>
                        <h5>
                            Account deletion
                        </h5>
                        <span>Careful, these actions are permanent</span>

                        <div className='delete-box-contenet'>
                            <button className='button-dele' onClick={handleShow}> <FontAwesomeIcon icon={faTrash} /> Delete my account</button>
                        </div>

                    </div> */}

                    {/* <div className='Dark Mode'>
                        <h5>
                            Theme update
                        </h5>
                        <Switch
                            checked={checked}
                            onChange={handleChangeTheme}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div> */}











                </div>

            </Container >


            <Modal show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title> Deleting Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Are you sure you want to Delete this Account?</h4>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <button onClick={handleDeletAccount} className="delete-account-delete">
                        Yes
                    </button>
                </Modal.Footer>

            </Modal>




            <Modal show={showPayment} onHide={handleClosePayment}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Are you sure you want to cancel the current subscriptions?</h4>

                    <div className='mt-4'>
                        <h6>
                            Product Plan : {displayPlan?.price_value}
                        </h6>
                        <h6>
                            Amount : {displayPlan?.amount_paid}
                        </h6>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePayment}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleCancelModal}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>







            {/* <ToastContainer /> */}
        </div >
    )
}