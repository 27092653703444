import React, { useState, useEffect, useContext, useMemo, useRef } from 'react'
import Masonry from 'react-masonry-css';
import './style.css'
import { Row, Col, Container, Dropdown, Button, Card, Alert, Spinner, Form } from 'react-bootstrap'
import Ellipse from '../../../assets/images/Ellipse.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faRetweet, faCircleInfo, faEllipsisVertical, faStar, faBolt, faLocationDot, faLink, faFaceFrown, faEyeSlash, faGlobe, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import * as moment from 'moment';
import { MySideBarContext, MyContext } from '../../../components/MyQueue/MyContext'
import noimage from '../../../assets/noimage.png'
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../../utilities/Config';



export default function DailyInspiration() {

    const [dailyinspiration, setDailyInspiration] = useState([])
    const [context, setContext] = useContext(MyContext);
    const [languageLoading, setLanguageLoading] = useState(false)

    const breakpointColumnsObj = {
        default: 3,
        1200: 3,
        1000: 2,
        992: 2,
        768: 1
    };
    const tokenLogin = localStorage.getItem('accessToken')
    const userID = localStorage.getItem('UserID')
    const providerToken = localStorage.getItem('providerToken')
    const navigate = useNavigate()
    const popoverRef2 = useRef(null);

    const Language = ["English",
        "Spanish",
        "French",
        "Albanian",
        "German",
        "Portuguese",
        "Vietnamese",
        "Italian",
        "Dutch",
        "Hindi",
        "Ukrainian",
        "Russian",
        "Arabic",
        "Chinese"]

    useEffect(() => {
        // const fetchData = async () => {
        //     await displayDailyins();
        //     await displayProfile();

        // };




        // fetchData();
        displayDailyins();
        displayProfile();
    }, [])


    const handleClickOutside2 = (event) => {
        if (popoverRef2.current && !popoverRef2.current.contains(event.target)) {
            // Click occurred outside the popover, so close it
            setIsOpenDrop(null);
        }
    };

    useEffect(() => {
        // Attach the click event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside2);
        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, []);



    const [displayAImessage, setDisplayAImessage] = useState('')
    const [displayHideDaily, setDisplayHideDaily] = useState({})
    const [loading, setLoading] = useState(false)

    const emailTo = localStorage.getItem('email-to')
    const emailSwitch = localStorage.getItem('emailSwitch')


    const displayDailyins = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    setLoading(true)
                    const response = await fetch(`${BASE_URL}api/tweet/daily/inspiration/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();

                    setDailyInspiration(data)
                    if (data.code == 200) {
                        setDailyInspiration(data)

                        // setDisplayHideDaily(data)

                    }
                    else {
                        setDailyInspiration(data)
                    }
                    setLoading(false)
                }
                else {
                    console.log("sjbhcsbhjcds cdsuhcvsd ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    setLoading(true)
                    const response = await fetch(`${BASE_URL}api/tweet/daily/inspiration/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();

                    setDailyInspiration(data)
                    console.log("jdbscb", data)
                    if (data.code == 200) {
                        setDailyInspiration(data)

                        // setDisplayHideDaily(data)

                    }
                    else {
                        setDailyInspiration(data)
                    }
                    setLoading(false)
                }
                else {
                    console.log("sjbhcsbhjcds cdsuhcvsd ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };

    console.log("dialuy", dailyinspiration)




    const displayProfile = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profile", data.data);
                    if (data.code == 200) {
                        setDisplayAImessage(data.data)

                        if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {
                            // alert("subscribed")
                        }
                        else {
                            navigate('/app/payments')
                        }


                    }
                    else {



                        console.log("failed")


                    }
                }
                else {
                    console.log("scjdsbchjds cdhsuicds vcjsd 1818181818")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/profile", data.data);
                    if (data.code == 200) {
                        setDisplayAImessage(data.data)

                        if (data?.data?.message_for_user_subscription == '' || data?.data?.message_for_user_subscription == null || data?.data?.message_for_user_subscription == undefined) {
                            // alert("subscribed")
                        }
                        else {
                            navigate('/app/payments')
                        }


                    }
                    else {



                        console.log("failed")


                    }
                }
                else {
                    console.log("scjdsbchjds cdhsuicds vcjsd 1818181818")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };

    const handleNavigateAISettings = () => {

        navigate('/dashboard/app/settings')
    }


    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(231);

    const handleLike = () => {
        if (!isLiked) {
            setIsLiked(true);
            setLikeCount(likeCount + 1);
        }
    };

    const handleUnlike = () => {
        if (isLiked) {
            setIsLiked(false);
            setLikeCount(likeCount - 1);
        }
    };



    const handleRetweet = (data) => {
        console.log("data", data)

        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('tweet_id', data);

                    fetch(`${BASE_URL}api/retweet/tweet`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response like", response)
                        return response.json();
                    }).then(data => {
                        console.log("like and dislike", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            // setReTweet(!retweet);
                        }

                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("sdcjkdsucds cdsuhcds222")
                }

            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;


                    let fdata = new FormData();


                    fdata.append('id', userID);
                    fdata.append('tweet_id', data);

                    fetch(`${BASE_URL}api/retweet/tweet`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response like", response)
                        return response.json();
                    }).then(data => {
                        console.log("like and dislike", data);
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            // setReTweet(!retweet);
                        }

                        else {

                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                    })
                }
                else {
                    console.log("sdcjkdsucds cdsuhcds222")
                }

            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleURL = async (dataa, index) => {
        console.log("jbscvjhas", dataa)

        try {
            const response = await fetch(`${BASE_URL}api/visit/count/${dataa.tweet_id}`, {
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log("published/count", data);
            if (data.data)
                setDynamicQueue(data.data)


        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }

    }


    const handleHideApi = (dataa, index) => {
        console.log("data-tweet", dataa)
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();
                    fdata.append('id', userID);
                    fdata.append('tweet_id', dataa.tweet_id)
                    // fdata.append('json_data', JSON.stringify(displayHideDaily.data))

                    fetch(`${BASE_URL}api/hide/tweet`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {
                        console.log('repsonse hide', response);
                        if (response.ok) {
                            console.log('Data deleted successfully.');
                            // window.location.reload()


                            // setDraftData(DraftData.filter(item => item.id !== modalData.id));
                        } else {
                            console.error('Failed to delete data.');

                        }
                        return response.json()
                    }).then(data => {
                        console.log("data.delete", data)
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            setDailyInspiration(data)


                        } else {
                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                        // window.location.reload()
                        // setDailyInspiration(data.data)
                        // displayDailyins()

                    })
                }
                else {
                    console.log("scjkndscjds cdisuh19191919")
                }

            } catch (error) {

            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    let fdata = new FormData();
                    fdata.append('id', userID);
                    fdata.append('tweet_id', dataa.tweet_id)
                    // fdata.append('json_data', JSON.stringify(displayHideDaily.data))

                    fetch(`${BASE_URL}api/hide/tweet`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {
                        console.log('repsonse hide', response);
                        if (response.ok) {
                            console.log('Data deleted successfully.');
                            // window.location.reload()


                            // setDraftData(DraftData.filter(item => item.id !== modalData.id));
                        } else {
                            console.error('Failed to delete data.');

                        }
                        return response.json()
                    }).then(data => {
                        console.log("data.delete", data)
                        if (data.code == 200) {
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            setDailyInspiration(data)


                        } else {
                            toast.error(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }

                        // window.location.reload()
                        // setDailyInspiration(data.data)
                        // displayDailyins()

                    })
                }
                else {
                    console.log("scjkndscjds cdisuh19191919")
                }

            } catch (error) {

            }
        }

    }

    const [isOpenDrop, setIsOpenDrop] = useState(false)
    const [selectedValue, setSelectedValue] = useState(null);

    const handleTranslate = (dataa, index) => {
        console.log("dataaa", dataa)
        try {

            let fdata = new FormData();
            fdata.append('id', userID);
            //fdata.append('tweet_id', dataa.tweet_id)
            fdata.append('text', dataa.tweet_text);
            fdata.append('provider_token', providerToken);
            fdata.append('language', displayAImessage?.user?.ai_language);

            // fdata.append('json_data', JSON.stringify(displayHideDaily.data))

            fetch(`${BASE_URL}api/ai/translate`, {
                method: 'POST',
                body: fdata,
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                }
            }).then(response => {
                console.log('repsonse hide', response);
                if (response.ok) {
                    console.log('Data deleted successfully.');
                    // window.location.reload()


                    // setDraftData(DraftData.filter(item => item.id !== modalData.id));
                } else {
                    console.error('Failed to delete data.');

                }
                return response.json()
            }).then(data => {
                console.log("data.delete", data)
                setContext({ ...context, t: { ...context.t, text: data.content } })




            })

        } catch (error) {

        }

    }










    const [contextData, setContextData] = useContext(MySideBarContext)
    console.log("contextData", contextData)
    const [dataLoading, setDataLoading] = useState(false)





    const currentDat = new Date();
    const initialDate = currentDat.toLocaleDateString('en-GB');
    const startDate = moment(initialDate, 'DD-MM-YYYY');
    const [currentMonth, setCurrentMonth] = useState(startDate.month());
    const year = startDate.year();
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalText, setModalText] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [sectionTexts, setSectionTexts] = useState([]);
    const [time, setTime] = useState([
        '2:30 PM', '4:23 AM', '5:34 PM'
    ])
    const [dateTimeList, setDateTimeList] = useState([])
    const [langDropdownIndex, setLangDropdownIndex] = useState(null)



    const [dynamictime, setDynamicTime] = useState([])
    const [dynamicQueue, setDynamicQueue] = useState([])
    const [liked, setLiked] = useState(false);
    const [retweet, setReTweet] = useState(false);


    useEffect(() => {


        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };


        if (contextData.QUEUESaved == true) {


            fetchData();
        }
        console.log(contextData.QUEUESaved, 'Queueu SAVED');
    }, [contextData.QUEUESaved])


    useEffect(() => {

        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };

        fetchData();

    }, [])







    // const myQueuueTimeSlotDynanmic = async () => {
    //     try {
    //         setDataLoading(true)
    //         const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published/tweet/list", data.data.timeslot);
    //         setDynamicTime(data.data.timeslot)
    //         setDataLoading(false)

    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }
    // };

    const myQueuueTimeSlotDynanmic = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)
                    setDataLoading(false)
                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)
                    setDataLoading(false)
                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };



    const displayDynamicQueue = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)
                        setContextData({ QUEUESaved: false })

                    }
                    setDataLoading(false)
                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)
                        setContextData({ QUEUESaved: false })

                    }
                    setDataLoading(false)
                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };


    // const displayDynamicQueue = async () => {
    //     try {
    //         setDataLoading(true)
    //         const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published/queue", data);
    //         if (data.code == 200) {
    //             setDynamicQueue(data.data)
    //             setContextData({ QUEUESaved: false })

    //         }
    //         setDataLoading(false)


    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }
    // };


    console.log("dynamic-queue-1", dynamicQueue);



    //THIS IS THE ONE ---------
    useMemo(() => {
        let timeIndex = -1;
        let availableSlot = null;
        // let dateIndex = dateTimeList.findIndex(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
        // console.log(dateIndex, moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'), 'asdsad');
        // if(dateTimeList[dateIndex]){
        //     timeIndex = dateTimeList[dateIndex].time.findIndex(obj => obj.time === contextData?.handleTime)
        // }
        // if (dateTimeList[dateIndex] && dateTimeList[dateIndex].time[timeIndex]){
        // dateTimeList[dateIndex].time[timeIndex].text = contextData?.tweetText
        // dateTimeList[dateIndex].time[timeIndex].image = contextData?.selectedImages
        // dateTimeList[dateIndex].time[timeIndex].gif = contextData?.selectedGifpicker
        //     setDateTimeList([...dateTimeList])
        // }



        if (contextData?.handleDate) {
            let dateFilter = dateTimeList.filter(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
            console.log("dvdsv", dateFilter)
            dateFilter.map(el => {

                timeIndex = el.time.findIndex(obj => obj.time === contextData?.handleTime)

                if (el && el.time[timeIndex]) {
                    el.time[timeIndex].text = contextData?.tweetText
                    el.time[timeIndex].image = contextData?.selectedImages
                    el.time[timeIndex].gif = contextData?.selectedGifpicker
                }

            }

            )
            setDateTimeList([...dateTimeList])
            console.log(dateTimeList, 'dateTimeLista');
            let list = dateTimeList;

            for (let i = 0; i < list.length; i++) {
                const currentTime = moment();
                const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY h:mm A').format('DD/MM/YYYY')
                let blankTextInd = list[i].time.findIndex(obj => obj.text === '');
                if (blankTextInd > -1) {
                    if (list[i] && list[i].time && list[i].time[blankTextInd]?.time && moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY h:mm A').isSameOrAfter(currentTime)) {
                        console.log('future time');
                        const timeFirst = list[i].time[blankTextInd]
                        console.log("!st,,,", timeFirst)
                        setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                        break;
                    }
                }

                console.log(",,,", i);
            }
        } else {
            dateTimeList.some((el) => {
                console.log("e---l", el)
                const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
                if (timeIndex !== -1) {
                    el.time[timeIndex].text = contextData?.tweetText;
                    el.time[timeIndex].image = contextData?.selectedImages;
                    el.time[timeIndex].gif = contextData?.selectedGifpicker;

                    return true; // Stop iterating after updating the first available slot


                }
                return false;
            });

            //     dateTimeList.some((el) => {
            //                 const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
            //                 if (timeIndex !== -1) {
            //                   availableSlot = el.time[timeIndex];
            //                   el.time[timeIndex].text = contextData?.tweetText;
            //                   el.time[timeIndex].image = contextData?.selectedImages;
            //                   el.time[timeIndex].gif = contextData?.selectedGifpicker;
            //                   return true; // Stop iterating after updating the first available slot
            //                 }
            //                 return false;
            //               });

            //       setDateTimeList([...dateTimeList]);




        }
        //    console.log('Available Slot:', availableSlot);

    }, [contextData])
    console.log("contextData", contextData, dateTimeList);

    const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
    const popoverRef = useRef(null);


    const togglePopover = (index) => {
        if (openPopoverIndex === index) {
            // Clicking on the same icon again should close the popover
            setOpenPopoverIndex(null);
        } else {
            // Clicking on a different icon should open the popover
            setOpenPopoverIndex(index);
        }
    };

    const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            // Click occurred outside the popover, so close it
            setOpenPopoverIndex(null);
        }
    };

    useEffect(() => {
        // Attach the click event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // useEffect(() => {
    //     let timeIndex = -1;
    //     let availableSlot = null;

    //     dateTimeList.some((el) => {
    //       timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //       if (timeIndex !== 1) {
    //         availableSlot = el.time[timeIndex];
    //         el.time[timeIndex].text = contextData?.tweetText;
    //         el.time[timeIndex].image = contextData?.selectedImages;
    //         el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //         return true; // Stop iterating after updating the first available slot
    //       }
    //       return false;
    //     });

    //     setDateTimeList([...dateTimeList]);

    //     console.log('Available Slot:', availableSlot);
    //   }, [contextData]);

    //   console.log("contextData", contextData, dateTimeList);




    // useEffect(() => {
    //     let availableSlot = null;

    //     if (contextData?.handleDate) {
    //       let dateFilter = dateTimeList.filter(
    //         (obj) =>
    //           moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') ===
    //           moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd')
    //       );

    //       dateFilter.some((el) => {
    //         const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //         if (timeIndex !== -1) {
    //           availableSlot = el.time[timeIndex];
    //           el.time[timeIndex].text = contextData?.tweetText;
    //           el.time[timeIndex].image = contextData?.selectedImages;
    //           el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //           return true; // Stop iterating after updating the first available slot
    //         }
    //         return false;
    //       });

    //       setDateTimeList([...dateTimeList]);
    //     } else {
    //       dateTimeList.some((el) => {
    //         const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //         if (timeIndex !== -1) {
    //           availableSlot = el.time[timeIndex];
    //           el.time[timeIndex].text = contextData?.tweetText;
    //           el.time[timeIndex].image = contextData?.selectedImages;
    //           el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //           return true; // Stop iterating after updating the first available slot
    //         }
    //         return false;
    //       });

    //       setDateTimeList([...dateTimeList]);
    //     }

    //     console.log('Available Slot:', availableSlot);
    //   }, [contextData, dateTimeList]);



    useEffect(() => {
        const updateCurrentMonth = () => {
            const newMonth = moment().month();
            if (newMonth !== currentMonth) {
                setCurrentMonth(newMonth);
            }
        };

        const intervalId = setInterval(updateCurrentMonth, 60000); // Check every minute for date update

        return () => {
            clearInterval(intervalId); // Clear interval on component unmount
        };
    }, [currentMonth]);



    useEffect(() => {
        console.log('currentDate');

        let list = []
        let currentDate = moment(startDate); // Create a moment object for currentDate
        const endMonth = currentMonth + 2; // Display three months at a time
        const sectionKey = currentDate.format('YYYY-MM-DD');
        const sectionText = sectionTexts[sectionKey] || '';
        console.log('currentDate', currentDate);



        while (currentDate.year() === year && currentDate.month() <= endMonth) {

            // const slotForDays = dynamictime.filter(el => el.weekdays.includes(currentDate.format('dddd')))
            const slotForDays = (dynamictime ?? []).filter(el => el.weekdays.includes(currentDate.format('dddd')));

            // const slottext = dynamicQueue.map(es => es.text)
            console.log(dynamictime, "---dynamictime", slotForDays, "---slotForDays", dynamicQueue, "dynamicQueue", currentDate.format('dddd'), 'asasas');
            let timeslotArr = slotForDays?.map(a => {
                // console.log(a.weekdays, 'aaaaaa');
                // const ATime = moment(a.timeslot,'hh:mm:ss').format('h:mm A ')
                const ATime = a.timeslot
                //const queueItem = dynamicQueue.findIndex(item => item.schedule_time === ATime && item.schedule_date === currentDate.format('D MMMM YYYY'));
                // console.log("qute", queueItem)
                let textValue = ""
                let GivValue = ""
                let ImageValue = ""
                let id = ""
                let is_thread = ""
                let tweet_id = ""
                let thread_tweet_id = ""
                if (dynamicQueue && dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text
                ) {
                    id = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].id
                    textValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text

                    GivValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].gif

                    ImageValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].image

                    console.log("images", ImageValue)

                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }

                else if (dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].text
                ) {
                    let dynamicArrayIndex = dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)
                    let dynamicArray = dynamicQueue[dynamicArrayIndex].map(element => {
                        id = element.id
                        textValue = element.text

                        GivValue = element.gif

                        ImageValue = element.image
                        is_thread = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].is_thread
                        tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].tweet_id
                        thread_tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].thread_tweet_id
                        console.log("is_thread", is_thread)
                        return {
                            id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : '', is_thread: is_thread, tweet_id: tweet_id, thread_tweet_id: thread_tweet_id
                        }

                    })
                    return dynamicArray;




                }
                else {
                    return {
                        id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                    }
                }

                // return {
                //     id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                // }

            }
            )

            const formattedDate = currentDate.format('dddd | MMMM D, YYYY');
            list.push({
                formattedDate: formattedDate,
                time: timeslotArr,
                sectionKey: sectionKey
            })
            currentDate.add(1, 'day'); // Move to the next day

        }

        setDateTimeList([...list])



        for (let i = 0; i < list.length; i++) {
            const currentTime = moment();
            const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY HH:mm').format('DD/MM/YYYY')
            let blankTextInd = list[i].time.findIndex(obj => obj?.text === '' &&
                moment(currentDate + " " + obj?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)
            );
            console.log("listFor", list[i], i, blankTextInd)
            if (blankTextInd > -1) {
                console.log("listFor, !time,,,", list[i].time[blankTextInd]?.time, currentDate, currentTime)
                // if (list[i] && list[i].time && list[i].time[blankTextInd]?.time &&
                //     moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)) {
                console.log('future time', list[i].formattedDate);
                const timeFirst = list[i].time[blankTextInd]
                console.log("listFor, !st,,,", timeFirst, list[i], blankTextInd, i)
                setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                break;
                // }
            }
            console.log(",,,", i, currentDate, currentTime);
        }






        // for (let i = 0; i < list.length; i++) {

        //     const currentTime = moment();
        //     const futureTimeSlotIndex = list[i].time.findIndex(
        //       (obj) => moment(obj.time, "h:mm A").isSameOrAfter(currentTime)
        //     );

        //     if (futureTimeSlotIndex > -1) {
        //       const timeFirst = list[i].time[futureTimeSlotIndex];
        //       console.log("!st,,,", timeFirst);
        //       setContext({ t: timeFirst, formattedDate: list[i].formattedDate });
        //       break;
        //     }
        //     console.log(",,,", i);
        //   }


    }, [dynamictime])

    console.log(dateTimeList, 'dateTimeList');








    return (
        <div id='content-page' className='content-page' style={{ backgroundColor: 'white' }}>
            {/* {displayAImessage !== '' ?
                <Alert variant="danger" className='alert-text'>
                    <FontAwesomeIcon icon={faCircleInfo} className='alert-icon' />{displayAImessage ? displayAImessage : ''}. <span className='span-alert' onClick={handleNavigateAISettings}>Please update them here </span>
                </Alert>
                : ''} */}

            {/* {displayAImessage.message_for_AI_Settings !== '' || displayAImessage.message_for_user_subscription !== '' ?
                <Alert variant="danger" className='alert-text'>
                    <FontAwesomeIcon icon={faCircleInfo} className='alert-icon' />
                    {displayAImessage.message_for_AI_Settings || displayAImessage.message_for_user_subscription}. <span className='span-alert' onClick={handleNavigateAISettings}>
                        Please update them here
                    </span>
                </Alert>
                :
                ''
            } */}

            {(displayAImessage?.message_for_AI_Settings !== ''
                && displayAImessage?.message_for_AI_Settings !== undefined
            ) ||
                (displayAImessage?.message_for_user_subscription !== '' &&
                    displayAImessage?.message_for_user_subscription !== undefined) ?

                <Alert variant="danger" className='alert-text'>
                    <FontAwesomeIcon icon={faCircleInfo} className='alert-icon-1' />
                    {displayAImessage?.message_for_AI_Settings || displayAImessage?.message_for_user_subscription}. <span className='span-alert' onClick={handleNavigateAISettings}>
                        Please update them here
                    </span>
                </Alert>
                :
                ''
            }


            <div className='setting-header-box'>
                <h4>
                    Daily inspiration
                </h4>
                <hr />
            </div>

            {loading ? (
                <>
                    <div className='text-center'>

                        <Spinner animation="border" variant="primary" />
                    </div>

                </>) : (

                <>
                    <Container>
                        <div className='dail-masornry'>


                            {dailyinspiration?.code == 200 ?
                                (
                                    <Masonry
                                        breakpointCols={breakpointColumnsObj}
                                        className="my-masonry-grid"
                                        columnClassName="my-masonry-grid_column"
                                    >
                                        {dailyinspiration?.data?.map((data, index) => {


                                            // const tooltipContent = (
                                            //     <Popover id={`popover-${index}`}>
                                            //         <Popover.Header as="h3" className='popover-header-dailyins'>
                                            //             <div className="DailyIns-popover-header">
                                            //                 <div className='DailyIns-popover-image'>
                                            //                     <img src={data?.user_profile?.profile_image_url} className="popover-img-fluid" alt={noimage} />
                                            //                 </div>
                                            //                 <div className='popver-paragrapgh'>
                                            //                     <h6>{data?.user_profile?.name}</h6>
                                            //                     <span>
                                            //                         @{data?.user_profile?.screen_name}
                                            //                     </span>
                                            //                 </div>
                                            //             </div>
                                            //         </Popover.Header>
                                            //         <Popover.Body className='popover-body-dailyIns'>
                                            //             {data?.user_profile?.description}
                                            //             <div className='popover-followers'>
                                            //                 <span> <strong>{data?.user_profile?.followings_count}</strong> Following</span>
                                            //                 <span> <strong>{data?.user_profile?.followers_count}</strong> Followers</span>
                                            //             </div>
                                            //             <div className='popover-followers'>
                                            //                 <span> <FontAwesomeIcon icon={faLocationDot} /> {data?.user_profile?.location}</span>
                                            //                 {/* <span className='popver-link'> <FontAwesomeIcon icon={faLink} onClick={() => handleURL(data, index)} /> */}

                                            //                 <span className='popver-link' ><FontAwesomeIcon icon={faLink} /> <a href={data?.user_profile?.profile_url} target="_blank" rel="noopener noreferrer">Profile Link</a>
                                            //                 </span>
                                            //             </div>
                                            //         </Popover.Body>
                                            //     </Popover>
                                            // );


                                            const handleLikeAndDislike = (e) => {
                                                e.preventDefault()
                                                if (localStorage.getItem("userloggedin") === 'true') {
                                                    try {
                                                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                                        const targetUser = userDataArray.find(user => user.email === emailTo);

                                                        if (targetUser) {
                                                            const { userID, accessToken } = targetUser;

                                                            let fdata = new FormData();


                                                            fdata.append('id', userID);
                                                            fdata.append('tweet_id', data.tweet_id);

                                                            fetch(`${BASE_URL}api/like/tweet`, {
                                                                method: 'POST',
                                                                body: fdata,
                                                                headers: {
                                                                    Authorization: `Bearer ${accessToken}`,
                                                                }
                                                            }).then(response => {

                                                                console.log("response like", response)
                                                                return response.json();
                                                            }).then(data => {
                                                                console.log("like and dislike", data);
                                                                if (data.code == 200) {

                                                                    setLiked(!liked);
                                                                    toast.success(data.message, {
                                                                        position: "top-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "light",
                                                                    });
                                                                }

                                                                else {
                                                                    toast.error(data.message, {
                                                                        position: "top-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "light",
                                                                    });

                                                                }

                                                            })
                                                        }
                                                        console.log("jhbsdcscdedececfuhies  36272727")

                                                    } catch (error) {
                                                        console.log(error)
                                                    }
                                                    console.log("tweet-id", data.tweet_id)
                                                }
                                                else {
                                                    try {
                                                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                                        const targetUser = userDataArray.find(user => user.email === emailSwitch);

                                                        if (targetUser) {
                                                            const { userID, accessToken } = targetUser;

                                                            let fdata = new FormData();


                                                            fdata.append('id', userID);
                                                            fdata.append('tweet_id', data.tweet_id);

                                                            fetch(`${BASE_URL}api/like/tweet`, {
                                                                method: 'POST',
                                                                body: fdata,
                                                                headers: {
                                                                    Authorization: `Bearer ${accessToken}`,
                                                                }
                                                            }).then(response => {

                                                                console.log("response like", response)
                                                                return response.json();
                                                            }).then(data => {
                                                                console.log("like and dislike", data);
                                                                if (data.code == 200) {

                                                                    setLiked(!liked);
                                                                    toast.success(data.message, {
                                                                        position: "top-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "light",
                                                                    });
                                                                }

                                                                else {
                                                                    toast.error(data.message, {
                                                                        position: "top-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "light",
                                                                    });

                                                                }

                                                            })
                                                        }
                                                        console.log("jhbsdcscdedececfuhies  36272727")

                                                    } catch (error) {
                                                        console.log(error)
                                                    }
                                                }
                                            }


                                            const handleTrendingTweetContext = (data) => {
                                                localStorage.removeItem('savedValue')
                                                // setContext({ ...context, t: { ...context.t, text: data.tweet_text } })
                                                setContext({ ...context, t: { ...context.t, text: data.tweet_text, full_text: data.tweet_text } })


                                            }

                                            const handleOpenDrop = (dataa, i) => {
                                                console.log("heeelo index", index, "iii", i)
                                                if (index == i) {
                                                    setIsOpenDrop(!isOpenDrop)
                                                    setLangDropdownIndex(i)

                                                }

                                            }


                                            const handleSelectValue = (dataa, value) => {
                                                localStorage.removeItem('savedValue')
                                                setSelectedValue(value);
                                                setIsOpenDrop(false); // Close the dropdown after selecting a value
                                                if (localStorage.getItem("userloggedin") === 'true') {
                                                    try {
                                                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                                        const targetUser = userDataArray.find(user => user.email === emailTo);

                                                        if (targetUser) {
                                                            const { userID, accessToken, providerToken } = targetUser;



                                                            setLanguageLoading(true)


                                                            let fdata = new FormData();
                                                            fdata.append('id', userID);
                                                            //fdata.append('tweet_id', dataa.tweet_id)
                                                            fdata.append('text', dataa.tweet_text);
                                                            fdata.append('provider_token', providerToken);
                                                            fdata.append('language', value);

                                                            // fdata.append('json_data', JSON.stringify(displayHideDaily.data))

                                                            fetch(`${BASE_URL}api/ai/translate`, {
                                                                method: 'POST',
                                                                body: fdata,
                                                                headers: {
                                                                    Authorization: `Bearer ${accessToken}`,
                                                                }
                                                            }).then(response => {
                                                                console.log('repsonse hide', response);
                                                                if (response.ok) {
                                                                    console.log('Data deleted successfully.');
                                                                    // window.location.reload()


                                                                    // setDraftData(DraftData.filter(item => item.id !== modalData.id));
                                                                } else {
                                                                    console.error('Failed to delete data.');

                                                                }
                                                                return response.json()
                                                            }).then(data => {
                                                                console.log("data.delete", data)
                                                                // setContext({ ...context, t: { ...context.t, text: data.content } })
                                                                // setContext({ ...context, t: { ...context.t, text: data.content, thread: null } })

                                                                setContext({ ...context, t: { ...context.t, text: data.content, full_text: data.content, thread: null } })


                                                                setLanguageLoading(false)

                                                                localStorage.setItem('savedValue', data.content);

                                                            })
                                                        }
                                                        else {
                                                            console.log("cdjdscjds cdisuhcs csiu8888")
                                                        }

                                                    } catch (error) {

                                                    }
                                                }
                                                else {
                                                    try {
                                                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                                                        const targetUser = userDataArray.find(user => user.email === emailSwitch);

                                                        if (targetUser) {
                                                            const { userID, accessToken, providerToken } = targetUser;



                                                            setLanguageLoading(true)


                                                            let fdata = new FormData();
                                                            fdata.append('id', userID);
                                                            //fdata.append('tweet_id', dataa.tweet_id)
                                                            fdata.append('text', dataa.tweet_text);
                                                            fdata.append('provider_token', providerToken);
                                                            fdata.append('language', value);

                                                            // fdata.append('json_data', JSON.stringify(displayHideDaily.data))

                                                            fetch(`${BASE_URL}api/ai/translate`, {
                                                                method: 'POST',
                                                                body: fdata,
                                                                headers: {
                                                                    Authorization: `Bearer ${accessToken}`,
                                                                }
                                                            }).then(response => {
                                                                console.log('repsonse hide', response);
                                                                if (response.ok) {
                                                                    console.log('Data deleted successfully.');
                                                                    // window.location.reload()


                                                                    // setDraftData(DraftData.filter(item => item.id !== modalData.id));
                                                                } else {
                                                                    console.error('Failed to delete data.');

                                                                }
                                                                return response.json()
                                                            }).then(data => {
                                                                console.log("data.delete", data)
                                                                // setContext({ ...context, t: { ...context.t, text: data.content } })
                                                                setContext({ ...context, t: { ...context.t, text: data.content, full_text: data.content, thread: null } })
                                                                if (data.code == 200) {

                                                                }
                                                                else if (data.code == 500) {
                                                                    toast.error(data.error_message, {
                                                                        position: "top-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "light",
                                                                    });
                                                                }
                                                                else {
                                                                    toast.error(data.message, {
                                                                        position: "top-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "light",
                                                                    });
                                                                }



                                                                setLanguageLoading(false)

                                                                localStorage.setItem('savedValue', data.content);

                                                            })
                                                        }
                                                        else {
                                                            console.log("cdjdscjds cdisuhcs csiu8888")
                                                        }

                                                    } catch (error) {

                                                    }
                                                }
                                            };
                                            console.log("selcted value", selectedValue)

                                            console.log("jhsvcvhjscda", data)






                                            return (
                                                <>
                                                    <Card className='DailyIns-card'>
                                                        <Card.Header as="h5">
                                                            <div className="DailyIns-card-header">
                                                                <div className='DailyIns-card-image'>
                                                                    {/* <OverlayTrigger
                                                                      
                                                                        trigger="click"
                                                                        placement="right"
                                                                        overlay={tooltipContent}

                                                                    >
                                                                        <img src={data?.user_profile?.profile_image_url} className="DailyIns-img-fluid" alt="" />
                                                                    </OverlayTrigger> */}

                                                                    <img src={data?.user_profile?.profile_image_url} className="DailyIns-img-fluid" alt="" onClick={() => togglePopover(index)} />

                                                                    {openPopoverIndex === index && (
                                                                        <div className="popover-dail" ref={popoverRef}>
                                                                            <div className="DailyIns-popover-header">
                                                                                <div className='DailyIns-popover-image'>
                                                                                    <img src={data?.user_profile?.profile_image_url} className="popover-img-fluid" alt={noimage} />
                                                                                </div>
                                                                                <div className='popver-paragrapgh'>
                                                                                    <h6>{data?.user_profile?.name}</h6>
                                                                                    <span>
                                                                                        <a href={data?.user_profile?.profile_url} target="_blank" rel="noopener noreferrer">       @{data?.user_profile?.screen_name} </a>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <p>{data?.user_profile?.description}</p>
                                                                            <div className='popover-followers'>
                                                                                <span> <strong>{data?.user_profile?.followings_count}</strong> Following</span>
                                                                                <span> <strong>{data?.user_profile?.followers_count}</strong> Followers</span>
                                                                            </div>
                                                                            <div className='popover-followers'>
                                                                                <span> <FontAwesomeIcon icon={faLocationDot} /> {data?.user_profile?.location}</span>
                                                                                {/* <span className='popver-link'> <FontAwesomeIcon icon={faLink} onClick={() => handleURL(data, index)} /> */}

                                                                                <span className='popver-link' ><FontAwesomeIcon icon={faLink} /> <a href={data?.tweet_url} target="_blank" rel="noopener noreferrer">Tweet Link</a>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                </div>

                                                                <div className='dailyIns-span'>
                                                                    <span>{moment(data.tweet_created_at).format('DD/MM/YYYY')}</span>
                                                                    <FontAwesomeIcon icon={faEyeSlash}

                                                                        onClick={() => handleHideApi(data, index)}
                                                                        style={{ fontSize: 12 }}

                                                                    />

                                                                    {/* {(index == langDropdownIndex) && languageLoading ? <Spinner animation="border" variant="primary" size="sm" style={{ marginLeft: 10 }} /> : <FontAwesomeIcon icon={faLanguage} onClick={() => handleOpenDrop(data, index)} style={{ fontSize: 12, paddingLeft: 10 }} />} */}
                                                                </div>
                                                                {((isOpenDrop) && (index == langDropdownIndex)) ? (

                                                                    <div className='over-drop' ref={popoverRef2}>

                                                                        <span className='tweet-span'>
                                                                            {Language?.map(opt => (
                                                                                <li key={opt} value={opt} onClick={() => handleSelectValue(data, opt)} style={{
                                                                                    // backgroundColor: selectedValue === opt ? 'dodgerblue' : 'transparent',
                                                                                    color: selectedValue === opt ? 'dodgerblue' : 'black',
                                                                                }}>
                                                                                    {opt}

                                                                                </li>

                                                                            ))}
                                                                        </span>
                                                                    </div>

                                                                ) : ""}


                                                            </div>


                                                        </Card.Header>
                                                        <Card.Body>

                                                            <Card.Text >
                                                                {data.tweet_text}


                                                                {/* <FontAwesomeIcon icon={faCircleInfo} />
                                                <span style={{ color: 'black' }}> They should be easy to scan for relevant and actionable information. Elements, like text and images,
                                                    should be placed on them in a way that clearly
                                                    indicates hierarchy.</span> */}


                                                            </Card.Text>
                                                            <Row>
                                                                {data?.media && data?.media?.map((item, index2) => {
                                                                    return (
                                                                        <>
                                                                            <Col lg={4} md={4} sm={4}>
                                                                                <img src={item} className='daily-image-card' />
                                                                            </Col>
                                                                        </>
                                                                    )

                                                                })}



                                                            </Row>
                                                        </Card.Body>


                                                        <Card.Footer className="DailyIns-Card-footer">

                                                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 10, paddingLeft: 10, paddingTop: 10, paddingBottom: 10 }}>
                                                                <div>
                                                                    <span><FontAwesomeIcon icon={faHeart} style={{ color: 'red' }} onClick={handleLikeAndDislike} /> {data.favourites}</span>


                                                                    <span><FontAwesomeIcon icon={faRetweet} style={{ color: 'green', paddingLeft: 10 }}
                                                                        // onClick={handleRetweet} 
                                                                        onClick={() => handleRetweet(data?.tweet_id)}
                                                                    /> {data.retweets}</span>
                                                                </div>

                                                                <div className='DailyIns-Card-footer-right'>
                                                                    {(index == langDropdownIndex) && languageLoading ?
                                                                        <Spinner animation="border" variant="primary" size="sm" style={{ marginRight: 10 }} /> :
                                                                        <FontAwesomeIcon icon={faLanguage} onClick={() => handleOpenDrop(data, index)} style={{ fontSize: 12, marginRight: 10 }} />}

                                                                    <button onClick={() => handleTrendingTweetContext(data)}>Edit</button>
                                                                    {/* <button onClick={() => setContext({ ...context, t: { ...context.t, text: data.tweet_text } })}>Edit</button> */}

                                                                    {/* <FontAwesomeIcon icon={faEllipsisVertical} className='DailyIns-Card-footer-right-icon' /> */}

                                                                </div>

                                                            </div>
                                                        </Card.Footer>

                                                    </Card>
                                                </>
                                            )

                                        })}





                                    </Masonry>
                                )
                                :

                                (

                                    <div className='text-center daily-notext'>

                                        <h5>
                                            <FontAwesomeIcon icon={faCircleInfo} className='icon-text' />   {dailyinspiration.message}
                                        </h5>
                                        <div>

                                        </div>


                                    </div>
                                )

                            }
                        </div>

                    </Container >
                </>
            )}




        </div >
    )
}