import React, { useState, useEffect, useContext, useMemo } from 'react'
import './QueueStyel.css'
import { Row, Col, Modal, Button, Spinner } from 'react-bootstrap'
import moment from 'moment';
import MonthlySlots from './MonthlySlots';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { faEye, faPenNib, faPenToSquare, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { MyContext, MySideBarContext } from './MyContext';
import TestDrag from './TestDrag';
import { BASE_URL } from '../../utilities/Config';

export default function MyQueue() {
    const [contextData, setContextData] = useContext(MySideBarContext)
    console.log("contextData", contextData)
    const [dataLoading, setDataLoading] = useState(false)

    const emailTo = localStorage.getItem('email-to')
    const emailSwitch = localStorage.getItem('emailSwitch')

    const userLoggedin = localStorage.getItem('userloggedin')


    const currentDat = new Date();
    const initialDate = currentDat.toLocaleDateString('en-GB');
    const startDate = moment(initialDate, 'DD-MM-YYYY');
    const [currentMonth, setCurrentMonth] = useState(startDate.month());
    const year = startDate.year();
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalText, setModalText] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [sectionTexts, setSectionTexts] = useState([]);
    const [time, setTime] = useState([
        '2:30 PM', '4:23 AM', '5:34 PM'
    ])
    const [dateTimeList, setDateTimeList] = useState([])

    const userID = localStorage.getItem('UserID')
    const tokenLogin = localStorage.getItem('accessToken')
    const timeZone = localStorage.getItem('TimeZone')
    const [dynamictime, setDynamicTime] = useState([])
    const [dynamicQueue, setDynamicQueue] = useState([])


    useEffect(() => {


        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };


        if (contextData.QUEUESaved == true) {


            fetchData();
        }
        console.log(contextData.QUEUESaved, 'Queueu SAVED');
    }, [contextData.QUEUESaved])


    useEffect(() => {

        const fetchData = async () => {
            await displayDynamicQueue();
            await myQueuueTimeSlotDynanmic();
        };

        fetchData();

    }, [])







    // const myQueuueTimeSlotDynanmic = async () => {
    //     try {
    //         setDataLoading(true)
    //         const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published/tweet/list", data.data.timeslot);
    //         setDynamicTime(data.data.timeslot)
    //         setDataLoading(false)

    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }
    // };


    const myQueuueTimeSlotDynanmic = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)
                    setDataLoading(false)
                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/tweet/list", data.data.timeslot);
                    setDynamicTime(data.data.timeslot)
                    setDataLoading(false)
                }
                else {
                    console.log("eroro in myQueuueTimeSlotDynanmic ")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };






    const [context, setContext] = useContext(MyContext);
    // const displayDynamicQueue = async () => {
    //     try {
    //         setDataLoading(true)
    //         const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("published/queue", data);
    //         if (data.code == 200) {
    //             setDynamicQueue(data.data)
    //             setContextData({ QUEUESaved: false })

    //         }
    //         setDataLoading(false)


    //     } catch (error) {
    //         console.error('Error fetching buyer data:', error);
    //     }
    // };


    const displayDynamicQueue = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)
                        setContextData({ QUEUESaved: false })

                    }
                    setDataLoading(false)
                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    setDataLoading(true)
                    const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("published/queue", data);
                    if (data.code == 200) {
                        setDynamicQueue(data.data)
                        setContextData({ QUEUESaved: false })

                    }
                    setDataLoading(false)
                }
                else {
                    console.log("error in dynamic queue")
                }

            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };







    console.log("dynamic-queue-1", dynamicQueue);



    //THIS IS THE ONE ---------
    useMemo(() => {
        let timeIndex = -1;
        let availableSlot = null;
        // let dateIndex = dateTimeList.findIndex(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
        // console.log(dateIndex, moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'), 'asdsad');
        // if(dateTimeList[dateIndex]){
        //     timeIndex = dateTimeList[dateIndex].time.findIndex(obj => obj.time === contextData?.handleTime)
        // }
        // if (dateTimeList[dateIndex] && dateTimeList[dateIndex].time[timeIndex]){
        // dateTimeList[dateIndex].time[timeIndex].text = contextData?.tweetText
        // dateTimeList[dateIndex].time[timeIndex].image = contextData?.selectedImages
        // dateTimeList[dateIndex].time[timeIndex].gif = contextData?.selectedGifpicker
        //     setDateTimeList([...dateTimeList])
        // }



        if (contextData?.handleDate) {
            let dateFilter = dateTimeList.filter(obj => moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') === moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd'))
            console.log("dvdsv", dateFilter)
            dateFilter.map(el => {

                timeIndex = el.time.findIndex(obj => obj.time === contextData?.handleTime)

                if (el && el.time[timeIndex]) {
                    el.time[timeIndex].text = contextData?.tweetText
                    el.time[timeIndex].image = contextData?.selectedImages
                    el.time[timeIndex].gif = contextData?.selectedGifpicker

                }

            }

            )
            setDateTimeList([...dateTimeList])
            console.log(dateTimeList, 'dateTimeLista');
            let list = dateTimeList;

            for (let i = 0; i < list.length; i++) {
                const currentTime = moment();
                const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY h:mm A').format('DD/MM/YYYY')
                let blankTextInd = list[i].time.findIndex(obj => obj.text === '');
                if (blankTextInd > -1) {
                    if (list[i] && list[i].time && list[i].time[blankTextInd]?.time && moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY h:mm A').isSameOrAfter(currentTime)) {
                        console.log('future time');
                        const timeFirst = list[i].time[blankTextInd]
                        console.log("!st,,,", timeFirst, list[i], blankTextInd)
                        setContext({ t: timeFirst, formattedDate: list[i].formattedDate })
                        break;
                    }
                }

                console.log(",,,", i);
            }
        } else {
            dateTimeList.some((el) => {
                console.log("e---l", el)
                const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
                if (timeIndex !== -1) {
                    el.time[timeIndex].text = contextData?.tweetText;
                    el.time[timeIndex].image = contextData?.selectedImages;
                    el.time[timeIndex].gif = contextData?.selectedGifpicker;

                    return true; // Stop iterating after updating the first available slot


                }
                return false;
            });

            //     dateTimeList.some((el) => {
            //                 const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
            //                 if (timeIndex !== -1) {
            //                   availableSlot = el.time[timeIndex];
            //                   el.time[timeIndex].text = contextData?.tweetText;
            //                   el.time[timeIndex].image = contextData?.selectedImages;
            //                   el.time[timeIndex].gif = contextData?.selectedGifpicker;
            //                   return true; // Stop iterating after updating the first available slot
            //                 }
            //                 return false;
            //               });

            //       setDateTimeList([...dateTimeList]);




        }
        //    console.log('Available Slot:', availableSlot);

    }, [contextData])
    console.log("contextData", contextData, dateTimeList);

    // useEffect(() => {
    //     let timeIndex = -1;
    //     let availableSlot = null;

    //     dateTimeList.some((el) => {
    //       timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //       if (timeIndex !== 1) {
    //         availableSlot = el.time[timeIndex];
    //         el.time[timeIndex].text = contextData?.tweetText;
    //         el.time[timeIndex].image = contextData?.selectedImages;
    //         el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //         return true; // Stop iterating after updating the first available slot
    //       }
    //       return false;
    //     });

    //     setDateTimeList([...dateTimeList]);

    //     console.log('Available Slot:', availableSlot);
    //   }, [contextData]);

    //   console.log("contextData", contextData, dateTimeList);




    // useEffect(() => {
    //     let availableSlot = null;

    //     if (contextData?.handleDate) {
    //       let dateFilter = dateTimeList.filter(
    //         (obj) =>
    //           moment(obj.formattedDate, 'dddd | MMMM D, YYYY').format('dddd') ===
    //           moment(contextData?.handleDate, 'dddd | MMMM D, YYYY').format('dddd')
    //       );

    //       dateFilter.some((el) => {
    //         const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //         if (timeIndex !== -1) {
    //           availableSlot = el.time[timeIndex];
    //           el.time[timeIndex].text = contextData?.tweetText;
    //           el.time[timeIndex].image = contextData?.selectedImages;
    //           el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //           return true; // Stop iterating after updating the first available slot
    //         }
    //         return false;
    //       });

    //       setDateTimeList([...dateTimeList]);
    //     } else {
    //       dateTimeList.some((el) => {
    //         const timeIndex = el.time.findIndex((obj) => !obj.text); // Find the first available slot
    //         if (timeIndex !== -1) {
    //           availableSlot = el.time[timeIndex];
    //           el.time[timeIndex].text = contextData?.tweetText;
    //           el.time[timeIndex].image = contextData?.selectedImages;
    //           el.time[timeIndex].gif = contextData?.selectedGifpicker;
    //           return true; // Stop iterating after updating the first available slot
    //         }
    //         return false;
    //       });

    //       setDateTimeList([...dateTimeList]);
    //     }

    //     console.log('Available Slot:', availableSlot);
    //   }, [contextData, dateTimeList]);



    useEffect(() => {
        const updateCurrentMonth = () => {
            const newMonth = moment().month();
            if (newMonth !== currentMonth) {
                setCurrentMonth(newMonth);
            }
        };

        const intervalId = setInterval(updateCurrentMonth, 60000); // Check every minute for date update

        return () => {
            clearInterval(intervalId); // Clear interval on component unmount
        };
    }, [currentMonth]);



    useEffect(() => {
        console.log('currentDate');

        let list = []
        let currentDate = moment(startDate);
        const endMonth = currentMonth + 2;
        const sectionKey = currentDate.format('YYYY-MM-DD');
        const sectionText = sectionTexts[sectionKey] || '';
        console.log('currentDate', currentDate, endMonth, year + 2);



        while (currentDate.year() === year && currentDate.month() <= endMonth) {

            // const currentDate = moment(); // Use the current date as the starting point
            // const endMonth = currentDate.clone().add(60, 'days'); // Display slots for the next 60 days
            // const sectionKey = currentDate.format('YYYY-MM-DD');
            // const sectionText = sectionTexts[sectionKey] || '';
            // console.log('currentDate', currentDate);

            // while (currentDate.isSameOrBefore(endMonth, 'day')) {

            const formattedDate = currentDate.format('dddd | MMMM D, YYYY');
            console.log("formated date", dynamictime)
            // const slotForDays = dynamictime.filter(el => el.weekdays.includes(currentDate.format('dddd')))
            // const slotForDays = (dynamictime ?? []).filter(el => el.weekdays.includes(currentDate.format('dddd')));
            const slotForDays = dynamictime

            // const slottext = dynamicQueue.map(es => es.text)

            let timeslotArr = slotForDays?.map(a => {
                console.log(a.timeslot, 'aaaaaa');
                // const ATime = moment(a.timeslot,'hh:mm:ss').format('h:mm A ')


                let currentDate2 = new Date(); // use the current date
                let inputTime = a.timeslot; // replace this with the time you have

                // Parse the input time and set it to the current date
                let inputTimeParts = inputTime?.split(":");
                if (inputTimeParts) {
                    console.log("inputTimeParts==>", inputTimeParts);
                    currentDate2.setHours(parseInt(inputTimeParts[0], 10));
                    currentDate2.setMinutes(parseInt(inputTimeParts[1], 10));

                    if (inputTimeParts.length === 3) {
                        currentDate2.setSeconds(parseInt(inputTimeParts[2], 10));
                    }

                } else {
                    // Handle the case where inputTime is not in the expected format
                    console.log('Input time is not in the expected format');
                }

                // Convert the time to the America/New_York time zone
                let newYorkTime = currentDate2.toLocaleString("en-US", { timeZone: timeZone ? timeZone : 'IST', hour12: false, hour: "numeric", minute: "numeric", second: "numeric" });

                console.log(a.timeslot, "aaaaaa New York time: " + newYorkTime);





                //const ATime = a.timeslot

                const ATime = newYorkTime


                let textValue = ""
                let GivValue = ""
                let ImageValue = ""
                let VideoValue = ""
                let id = ""
                let is_thread = ""
                let tweet_id = ""
                let thread_tweet_id = ""
                let fullText = ""
                let imageCode = ""

                if (dynamicQueue && dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text
                ) {
                    id = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].id
                    textValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].text
                    fullText = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].full_text
                    imageCode = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].image_code

                    GivValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].gif

                    ImageValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].image

                    VideoValue = dynamicQueue[dynamicQueue.findIndex(element => element.schedule_date == currentDate.format('DD-MM-YYYY') && element.schedule_time === ATime)].video

                    console.log("images", ImageValue, ATime, formattedDate, 'formattedDateformattedDate', textValue)

                    return {
                        id: id,
                        time: ATime,
                        text: textValue,
                        date: "",
                        image: ImageValue ? ImageValue : '',
                        gif: GivValue ? GivValue : '',
                        video: VideoValue ? VideoValue : '',
                        full_text: fullText ? fullText : '',
                        image_code: imageCode ? imageCode : ''

                    }
                }

                else if (dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime) > -1
                    && dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].text
                ) {
                    let dynamicArrayIndex = dynamicQueue && dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)
                    let dynamicArray = dynamicQueue[dynamicArrayIndex].map(element => {
                        id = element.id
                        textValue = element.text
                        fullText = element.full_text
                        imageCode = element.image_code

                        GivValue = element.gif

                        ImageValue = element.image
                        VideoValue = element.video

                        is_thread = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].is_thread
                        tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].tweet_id
                        thread_tweet_id = dynamicQueue[dynamicQueue.findIndex(element => element[0]?.schedule_date == currentDate.format('DD-MM-YYYY') && element[0]?.schedule_time === ATime)][0].thread_tweet_id
                        console.log("is_thread", is_thread, ATime, formattedDate, 'formattedDateformattedDate')
                        return {
                            id: id,
                            time: ATime,
                            text: textValue,
                            full_text: fullText,
                            image_code: imageCode,
                            date: "",
                            image: ImageValue ? ImageValue : '',
                            gif: GivValue ? GivValue : '',
                            video: VideoValue ? VideoValue : '',
                            is_thread: is_thread,
                            tweet_id: tweet_id,
                            thread_tweet_id: thread_tweet_id
                        }

                    })
                    return dynamicArray;




                }
                else {
                    console.log(ATime, 'ATimeelse', formattedDate, 'formattedDateformattedDate');
                    return {
                        id: id,
                        time: ATime,
                        text: textValue, date: "",
                        image: ImageValue ? ImageValue : '',
                        gif: GivValue ? GivValue : '',
                        video: VideoValue ? VideoValue : '',
                        full_text: fullText ? fullText : '',
                        image_code: imageCode ? imageCode : ''
                    }
                }

                // return {
                //     id: id, time: ATime, text: textValue, date: "", image: ImageValue ? ImageValue : '', gif: GivValue ? GivValue : ''
                // }

            }
            )
            console.log(formattedDate, timeslotArr, 'beforeaddinlist');
            list.push({
                formattedDate: formattedDate,
                time: timeslotArr,
                sectionKey: sectionKey
            })
            currentDate.add(1, 'day'); // Move to the next day

        }

        setDateTimeList([...list])


        for (let i = 0; i < list.length; i++) {
            const currentTime = moment();
            const currentDate = moment(list[i].formattedDate, 'dddd | MMMM D, YYYY HH:mm').format('DD/MM/YYYY')
            let blankTextInd = list[i].time.findIndex(obj => obj?.text === '' &&
                moment(currentDate + " " + obj?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)
            );
            console.log("listFor", list[i], i, blankTextInd)
            if (blankTextInd > -1) {
                console.log("listFor, !time,,,", list[i].time[blankTextInd]?.time, currentDate, currentTime)
                // if (list[i] && list[i].time && list[i].time[blankTextInd]?.time &&
                //     moment(currentDate + " " + list[i].time[blankTextInd]?.time, 'DD/MM/YYYY HH:mm').isSameOrAfter(currentTime)) {
                console.log('future time', list[i].formattedDate);
                const timeFirst = list[i].time[blankTextInd]
                console.log("listFor, !st,,,", timeFirst, list[i], blankTextInd, i)
                setContext({ t: timeFirst, formattedDate: list[i].formattedDate })

                break;
                // }
            }
            console.log(",,,", i, currentDate, currentTime);
        }






        // for (let i = 0; i < list.length; i++) {

        //     const currentTime = moment();
        //     const futureTimeSlotIndex = list[i].time.findIndex(
        //         (obj) => moment(obj.time, "h:mm A").isSameOrAfter(currentTime)
        //     );

        //     if (futureTimeSlotIndex > -1) {
        //         const timeFirst = list[i].time[futureTimeSlotIndex];
        //         console.log("!st,,,", timeFirst);
        //         setContext({ t: timeFirst, formattedDate: list[i].formattedDate });
        //         break;
        //     }
        //     console.log(",,,", i);
        // }


    }, [dynamictime])

    console.log(dateTimeList, 'dateTimeList');



    const handleHitButton = () => {
        setContext({
            hitButton: true
        })
    }



    // const handleDragEnd = async (result) => {
    //     console.log(result, 'handleDragEndresult');
    //     if (!result.destination) return; // Item dropped outside the droppable area

    //     // const { source, destination } = result;
    //     // const updatedTimeSlots = [...dateTimeList]; // Create a copy of the original timeSlots array
    //     // // Get the source and destination indexes
    //     // const sourceIndex = source.index;
    //     // const destinationIndex = destination.index;
    //     // console.log(updatedTimeSlots[source.droppableId.split('-')[1]], 'handleDragEndresultaa', sourceIndex, destinationIndex);

    //     // // Retrieve the source and destination time objects
    //     // const sourceTime = updatedTimeSlots[source.droppableId.split('-')[1]].time[sourceIndex];
    //     // const destinationTime = updatedTimeSlots[destination.droppableId.split('-')[1]].time[destinationIndex];

    //     // // Swap the text values between the source and destination time objects
    //     // const sourceText = sourceTime.text;
    //     // sourceTime.text = destinationTime.text;
    //     // destinationTime.text = sourceText;

    //     // const sourceImage = sourceTime.image;
    //     // sourceTime.image = destinationTime.image;
    //     // destinationTime.image = sourceImage;

    //     // // Swap the gif values between the source and destination time objects
    //     // const sourceGif = sourceTime.gif;
    //     // sourceTime.gif = destinationTime.gif;
    //     // destinationTime.gif = sourceGif;
    //     // console.log(updatedTimeSlots[destination.droppableId.split('-')[1]], 'handleDragEndresultbb');

    //     // // Update the state with the rearranged items
    //     // setDateTimeList(updatedTimeSlots);
    //     // callPostAPI(updatedTimeSlots);

    // };





    return (
        <>
            {dynamictime ? (
                dataLoading == true ? (
                    <div className='text-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : (
                    <TestDrag dateTimeList={dateTimeList} />

                ))
                : (
                    <div className="text-center schedule-text">
                        <p>You have not defined a queue schedule yet. Please hit the button to do so.</p>
                        <button onClick={handleHitButton}> <FontAwesomeIcon icon={faPencil} /> &nbsp; Edit queue planning</button>
                    </div>
                )

            }



            {/* {dateTimeList.map((EL, i) =>

                <>
                    <span key={EL.formattedDate} style={{ color: 'black' }}>{EL.formattedDate}</span>


                    {EL.time && EL.time.length > 0 ?

                        <div className='queue-schedule-list-1' >


                            <MonthlySlots formattedDate={EL.formattedDate} sectionKey={EL.sectionKey} time={EL.time} />



                        </div>
                        :
                        <div className='queue-schedule-list' >




                        </div>

                    }

                </>
            )
            } */}





        </>



    )
}
