import React, { useState, useEffect } from 'react'
import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import PaymentForm from './PaymentForm';

import { useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../../../../utilities/Config';

export default function Payments() {
    const [selectedElement, setSelectedElement] = useState(null);
    const tokenLogin = localStorage.getItem('accessToken')
    const navigate = useNavigate()
    const [displayPayment, setDisplayPayment] = useState([])
    const [loader, setLoader] = useState(false)

    const emailTo = localStorage.getItem('email-to')
    const emailSwitch = localStorage.getItem('emailSwitch')


    const { priceId } = useParams();
    const handleBack = () => {
        navigate('/dashboard/app/notification')

    }

    // const handleOptionPayment = () => {
    //     navigate("/app/paymentform/:paymentid")
    // }

    const handleOptionPayment = (option) => {

        navigate(`/app/paymentform/${option.price_id
            }/${option.price_name}`);
    };


    // const displayPayment = [
    //     {
    //         id: '1',
    //         label: 'Intro',
    //         nickname: 'Intro',
    //         price: '12.50',
    //         type: 'month (yearly billing only)',
    //         option: ['Tweet and thread scheduling'
    //             , 'Analytics',
    //             'Unlimited accounts',
    //             'Custom tweet inspirations',
    //             'Auto-retweet',
    //             'Hide URL previews',
    //         ]

    //     },
    //     {
    //         id: '2',
    //         label: 'Base',
    //         nickname: 'Base',
    //         type: 'month (yearly)',
    //         price: '29',
    //         addprice: '49',
    //         addition: '/month (monthly)',
    //         option: ['Tweet and thread scheduling', 'Analytics', 'Unlimited accounts', 'Custom tweet inspirations', 'Auto-retweet', 'Hide URL previews', 'Daily AI-written tweets',
    //             'AI Re-write tweets',
    //             'AI Finish writing a tweet',
    //             'AI Thread ideas']

    //     },



    // ];


    useEffect(() => {
        handleDisplayPayment()
    }, [])



    const handleDisplayPayment = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {

            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    setLoader(true)
                    const response = await fetch(`${BASE_URL}api/fetch/products`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("kakaka", data);
                    setDisplayPayment(data.data.price)
                    // Find the relevant price object based on priceId
                    // const selectedPrice = data.data.Prices.find(
                    //     (price) => price.price_id === priceId
                    // );
                    // console.log("selec", selectedPrice)
                    setLoader(false)
                }
                else {
                    console.log("erorror log")
                }
            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    setLoader(true)
                    const response = await fetch(`${BASE_URL}api/fetch/products`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("kakaka", data);
                    setDisplayPayment(data.data.price)
                    // Find the relevant price object based on priceId
                    // const selectedPrice = data.data.Prices.find(
                    //     (price) => price.price_id === priceId
                    // );
                    // console.log("selec", selectedPrice)
                    setLoader(false)
                }
                else {
                    console.log("erorror log")
                }
            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    }




    return (
        <div className='payment-screen-body' style={{ backgroundColor: 'white', height: '62rem' }}>


            <Container>
                <div className='text-center payment-header-box'>
                    <h2>   <FontAwesomeIcon icon={faArrowLeft} onClick={handleBack} /> | The Right Plan for Your Business</h2>
                    <p>
                        We have several powerful plans to showcase your business and get discovered
                        as a creative entrepreneurs. Everything you need.
                    </p>
                </div>
                {loader ?
                    <div className='text-center'>
                        <Spinner animation="border" variant="primary" size="lg" />
                    </div> :

                    <div className='payment-menu'>
                        <Row>
                            {displayPayment.length > 0 ? displayPayment?.map((option) => {

                                if (option.is_disable === 1) {
                                    // Skip rendering the disabled option
                                    return null;
                                }
                                return (

                                    <Col lg={4} md={4} sm={6} xs={6}>

                                        <div
                                            className={`payment-menu-bar ${selectedElement === option.price_id ? 'selected' : ''}`}
                                            onClick={() => setSelectedElement(option.price_id)}
                                            key={option.price_id}
                                        >

                                            <div>
                                                <p className='product-title'>{option.price_name}</p>
                                            </div>

                                            <div>

                                                <p className={`product-amount ${selectedElement === option.price_id ? 'selected' : ''}`}> {option.price_nickname}</p>
                                            </div>


                                            <div className='container-payment'>
                                                <button className={`payment-button ${selectedElement === option.price_id ? 'selected' : ''}`} onClick={() => handleOptionPayment(option)}>
                                                    Choose
                                                </button>
                                            </div>



                                            <div className='payment-menu-listing'>
                                                <span> Includes:</span>
                                                <ul>
                                                    {option.features_description?.split(',').map((item, index) => {
                                                        console.log("hajaja", item)
                                                        return (
                                                            <>
                                                                <li>
                                                                    <FontAwesomeIcon icon={faCheck} />  {item}
                                                                </li>
                                                            </>
                                                        )
                                                    })}


                                                    {/* <li>
                                                <FontAwesomeIcon icon={faCheck} />  {option.nickname}
                                            </li> */}

                                                </ul>
                                            </div>




                                            {/* <div className='container-payment'>
                                        <button className={`payment-button ${selectedElement === option.description ? 'selected' : ''}`} onClick={() => handleOptionPayment(option)}>
                                            Choose
                                        </button>
                                    </div> */}

                                        </div>


                                    </Col>
                                )
                            }) : (<div className='payment-no-response'>

                                <h4> No Product available at this moment</h4>
                            </div>)}


                        </Row>

                    </div>

                }
                {/* <div className='text-center'>
                    <button className='payment-back text-center' onClick={handleBack}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </div> */}


            </Container>



        </div>
    )
}