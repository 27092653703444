import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Card, Image, Row, Col, Button, Form, Table, Alert, Container } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faFilm, faCaretDown, faSmile, faClock, faAngleDown, faCalendar, faBolt, faCircleInfo, faSortDown, faCaretUp, faWandMagicSparkles, faForwardFast, faMugHot, faMinimize, faUserTie, faMaximize, faSpellCheck, faHandFist, faHandBackFist, faMugSaucer, faCoffee, faFileMedical } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MyContext, MySideBarContext, MyDraftContext, MyThemeContext } from '../../../MyQueue/MyContext';

import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';


import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
// import { Picker } from 'emoji-mart';
// import 'emoji-mart/css/emoji-mart.css';

// import EmojiPicker from 'react-emoji-picker';

import Picker from 'emoji-picker-react';
import { useNavigate } from 'react-router-dom'
import gifimage from '../../../../assets/images/gif2.png'
import Swal from "sweetalert2";
import { BASE_URL } from '../../../../utilities/Config';
import ProgressBar from 'react-bootstrap/ProgressBar';


//image
import user1 from '../../../../assets/images/user/01.jpg'
import user2 from '../../../../assets/images/user/02.jpg'
import user3 from '../../../../assets/images/user/03.jpg'
import user4 from '../../../../assets/images/user/04.jpg'
import user5 from '../../../../assets/images/user/11.jpg'
import user6 from '../../../../assets/images/user/12.jpg'
import './StyleVerticalNav.css'
import Ellipse from '../../../../assets/images/Ellipse.png'




// import ReactGiphySearchbox from 'react-giphy-searchbox'
import ReactGiphySearchbox from "react-giphy-searchbox";
import * as moment from 'moment';
import SettingOffCanvas from '../../../setting/SettingOffCanvas';
import { formatDate, preventDefault } from '@fullcalendar/react';
import ThreadOverLay from './ThreadOverLay';
// import File from '../../../../views/dashboard/app/file';
// import ProgressBar from "@ramonak/react-progress-bar";


import { DateTime } from 'luxon';







const RightSidebar = ({ borderColor, cont, handleComposeClick }) => {
    const [contextData, setContextData] = useContext(MySideBarContext);
    const [context, setContext] = useContext(MyContext);
    // const [themeContext, setThemeContext] = useContext(MyThemeContext)

    const [queueTime, setQueueTime] = useState()
    // const [contextDraft, setContextDraft] = useContext(MyDraftContext);

    // const { bame } = useContext(MyContext);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenThread, setIsOpenThread] = useState(false);

    const minirightsidebar = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 968) {
            document.getElementById('rightSidebar').classList.toggle('right-sidebar');
            document.body.classList.toggle('right-sidebar-close');
        }
    }


    const emailSwitch = localStorage.getItem('emailSwitch')
    const emailTo = localStorage.getItem('email-to')
    const userLoggedin = localStorage.getItem('userloggedin')


    // text
    const [tweetText, setTweetText] = useState('');
    const [timeZoneV, setTimeZoneV] = useState('')
    const [mediaId, setMediaID] = useState('')

    // GIF  
    const [selectedGif, setSelectedGif] = useState(null);
    const [selectedGifpicker, setSelectedGifPicker] = useState(null);
    const [selectedGifOb, setSelectedGifOb] = useState({})
    const [selectedGifpickerArr, setSelectedGifPickerArr] = useState([]);

    const [NewGif, setNewGif] = useState(null)
    const gifInputRef = useRef(null);

    const [showGif, setShowGif] = useState(false)
    const [showGif2, setShowGif2] = useState(false)



    const [showthread, setShowthread] = useState(false);
    const [tweetArray, setTweetArray] = useState([]);
    const [resultArray, setResultArray] = useState([]);
    const textareaRef = useRef(null);
    const [thread, setThread] = useState([])

    const [selectedImageIndex, setSelectedImageIndex] = useState();
    const [selectedGifIndex, setSelectedGifIndex] = useState();
    const [selectionStartVal, setselectionStartVal] = useState(0)
    const [selectionStartVal2, setselectionStartVal2] = useState(0)
    const [loaderQueue, setLoaderQueue] = useState(false)
    const [loaderDraft, setLoaderDraft] = useState(false)

    const [loadingDraftThread, setLoadingDraftThread] = useState(false)
    const [loadingQueueThread, setLoadingQueueThread] = useState(false)
    const [loadingTweetThread, setLoadingTweetThread] = useState(false)
    const [loadingSchduleThread, setLoadingSchduleThread] = useState(false)

    //images
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedImages2, setSelectedImages2] = useState([]);
    const [selectedImages3, setSelectedImages3] = useState([]);
    const [arrImg, setArrImg] = useState([])
    const [arrImg2, setArrImg2] = useState([])
    const imageInputRef = useRef(null);
    const fileInputRef = useRef(null);
    const popoverRef = useRef(null);
    const popoverRefThread = useRef(null);

    //emoji 
    const [selectedEmoji, setSelectedEmoji] = useState(null);

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const [loadingimpoveText, setLoadingImproveText] = useState(false)
    const [loadingKeepWriting, setLoadingKeepWriting] = useState(false)
    const [loadingMoreAssertive, setLoadingMoreAssertive] = useState(false)
    const [loadingAddEmoji, setLoadingAddEmoji] = useState(false)
    const [loadingMoreCasual, setLoadingMoreCasual] = useState(false)
    const [loadingMakeShorter, setLoadingMakeShorter] = useState(false)
    const [loadingMoreFormal, setLoadingMoreFormal] = useState(false)
    const [loadingExpandTweet, setLoadingExpandTweet] = useState(false)
    const [loadingFixGrammer, setLoadingFixGrammer] = useState(false)


    // const handleEmojiSelect = (event) => {

    //     setTweetText(tweetText + event.emoji);

    // };




    const [selectedFile, setSelectedFile] = useState([]);
    const [videoPreviews, setVideoPreviews] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState([]);
    const [videoPreviews2, setVideoPreviews2] = useState([]);
    const [thread2, setThread2] = useState([{ text: "", image: [{ img: '', file: 'fff' }] }]);


    const [progress, setProgress] = useState(0);

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };



    // const handleFileChange = (e) => {


    //     const files = Array.from(e.target.files);
    //     const newImages = [...selectedFile];

    //     if (selectedFile.length + files.length > 5) {
    //         alert('Only 5 images can be uploaded at a time.');
    //         return;
    //     }


    //     files.forEach((file) => {
    //         const reader = new FileReader();

    //         reader.onload = () => {
    //             newImages.push({ img: reader.result, imgFile: e.target.files[0] });
    //             setSelectedFile(newImages);
    //         };

    //         reader.readAsDataURL(file);
    //     });


    // };






    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];

    //     if (file) {
    //         if (file.type === "video/mp4" || file.type === "video/quicktime") {
    //             const reader = new FileReader();

    //             reader.onload = (event) => {
    //                 setVideoPreviews(event.target.result);
    //             };

    //             reader.readAsDataURL(file);
    //         } else {
    //             alert("Please upload a valid MP4 or MOV video file.");
    //         }
    //     }
    // }




    const handleFileChange = async (e) => {
        handleRemoveImage()
        handleRemoveGif()
        setVideoUrl('')
        let imgnm = randomImageName(5)
        let imgnm2 = imgnm
        const file = e.target.files[0];


        if (file) {
            if (file.type === "video/mp4" || file.type === "video/quicktime") {
                const reader = new FileReader();

                reader.onload = (event) => {

                    setVideoPreviews({ preview: event.target.result, imgnm, type: "video", file: file });
                };

                reader.readAsDataURL(file);
                setImgnm2Text(imgnm)


                const formData = new FormData();
                formData.append('media', file);

                try {
                    const response = await fetch('https://upload.twitter.com/2/media/upload', {
                        method: 'POST',
                        body: formData,
                        headers: {

                            'Authorization': `Bearer ${tokenLogin}`,
                        },
                    });

                    // Handle the response, extract media_id, etc.

                } catch (error) {
                    console.error('Error uploading video:', error);
                }
            } else {
                alert("Please upload a valid MP4 or MOV video file.");
            }
        } else {
            // Clear the video preview when no file is selected
            setVideoPreviews(null);
            // setImgnm2Text("");
        }
    };





    // const handleFileChange = async (e) => {
    //     handleRemoveImage();
    //     handleRemoveGif();
    //     setVideoUrl('');
    //     let imgnm = randomImageName(5)
    //     let imgnm2 = imgnm
    //     const file = e.target.files[0];

    //     if (file) {
    //         if (file.type === 'video/mp4' || file.type === 'video/quicktime') {

    //             const reader = new FileReader();

    //             reader.onload = (event) => {
    //                 console.log("event==>", event);
    //                 setVideoPreviews({ preview: event.target.result, imgnm, type: "video", file: file });
    //             };
    //             // const formData = new FormData();
    //             // formData.append('media', file);

    //             // try {
    //             //     const response = await fetch('https://upload.twitter.com/1.1/media/upload.json', {
    //             //         method: 'POST',
    //             //         body: formData,
    //             //         headers: {
    //             //             'Content-Type': 'multipart/form-data',
    //             //             'Authorization': `Bearer ${tokenLogin}`,
    //             //         },
    //             //     });

    //             //     // Handle the response, extract media_id, etc.
    //             //     console.log(response.data);
    //             // } catch (error) {
    //             //     console.error('Error uploading video:', error);
    //             // }
    //         } else {
    //             alert('Please upload a valid MP4 or MOV video file.');
    //         }
    //     } else {
    //         // Clear the video preview when no file is selected
    //         setVideoPreviews(null);
    //     }
    // };

    // const uploadVideoToTwitter = async (file) => {
    //     try {
    //         const reader = new FileReader();

    //         return new Promise((resolve, reject) => {
    //             reader.onloadend = async () => {
    //                 const base64data = reader.result.split(',')[1];

    //                 try {
    //                     const formData = new FormData();
    //                     // formData.append('media_data', base64data);
    //                     formData.append('media', file);

    //                     const response = await fetch('https://upload.twitter.com/1.1/media/upload.json', {
    //                         method: 'POST',
    //                         body: formData,
    //                         headers: {
    //                             Authorization: `Bearer ${tokenLogin}`,
    //                             'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    //                         },
    //                     });

    //                     const data = await response.json();

    //                     if (response.ok) {
    //                         resolve(data.media_id_string);
    //                     } else {
    //                         console.error('Error uploading video to Twitter:', data);
    //                         reject(`Error uploading video to Twitter: ${data.errors[0].message}`);
    //                     }
    //                 } catch (error) {
    //                     console.error('Error uploading video to Twitter:', error);
    //                     reject(`Error uploading video to Twitter: ${error.message}`);
    //                 }
    //             };

    //             reader.readAsDataURL(file);
    //         });
    //     } catch (error) {
    //         console.error('Error reading file:', error);
    //         throw new Error(`Error reading file: ${error.message}`);
    //     }
    // };




    const handleIconClick = () => {
        // Trigger the hidden file input when the icon is clicked
        fileInputRef.current.click();
    };




    const handleOpenAIoptions = (e) => {

        e.preventDefault()
        setIsOpenAI(!isOpenAI);

    }


    const handleEmojiSelect = (event) => {

        const emoji = event.emoji;
        const textArea = textareaRef.current;
        const startPos = textArea.selectionStart;
        const endPos = textArea.selectionEnd;

        const newText =
            tweetText.substring(0, startPos) +
            emoji +
            tweetText.substring(endPos, tweetText.length);

        setTweetText(newText);

        // Set the cursor position after the inserted emoji
        textArea.selectionStart = startPos + emoji.length;
        textArea.selectionEnd = startPos + emoji.length;
        textArea.focus();



        const updatedTextThread = newText.split('\n\n\n').map((text, index1) => {
            const item = {
                value: text.substring(0, 280),
                subValue: newText.includes('\n\n\n'),
                gif: '',
                image: selectedImages,
                id: '',
                threadInd: index1
            };


            // if (index1 === selectedImageIndex) {
            //     item.image = selectedImageIndex; // Assign the selected image to the item

            // }
            if (item.subValue == true) {
                setShowthread(true)
            }

            console.log(item);
            return item;





        });

        if (newText == '') {
            setShowthread(false)
            {
                setContext({
                    ...context,
                    t: { ...context.t, thread: null, image: null, gif: null, text: '', }


                })
            }


        }




        setTextThread(updatedTextThread)






    };


    // Function to toggle emoji picker visibility
    const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
        setShowGif(false)
        // setShowEmojiPicker(false)
    };

    console.log("const", cont)


    //other  
    const [Autosaved, setAutoSaved] = useState(false)
    const tokenLogin = localStorage.getItem('accessToken')
    const providerToken = localStorage.getItem('providerToken')
    const providerID = localStorage.getItem('providerID')
    const OauthToken = localStorage.getItem('oAuthToken')
    const timeZone = localStorage.getItem('TimeZone')
    const userID = localStorage.getItem('UserID')


    const navigate = useNavigate()


    // drop doewn

    const [isOpenAI, setIsOpenAI] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');

    //modal

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    // edit queue modal

    const [showQueue, setShowQueue] = useState(false);

    const handleCloseQueue = () => setShowQueue(false);
    const handleShowQueue = (e) => {
        e.preventDefault()
        setShowthread(false)
        setShowQueue(true)

    };


    // date time handler

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('')
    const [newDate, setNewDate] = useState()



    const handleUpgrade = () => {

        navigate('/app/payments')
    }
    const timefor = moment(cont?.t?.time, 'hh:mm:ss').format('hh:mm A')



    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [selectedDateTime2, setSelectedDateTime2] = useState(null);

    const handleDateChange = (date) => {

        const currentDate = new Date(); // Current date and time


        const selectedDate = new Date(date);
        selectedDate.setSeconds(0); // Reset the seconds to avoid inconsistencies


        if (selectedDate < currentDate) {
            // alert("Please select the available time and date slot")
            Swal.fire({
                icon: "error",
                // title: "Please select the available time and date slot",
                text: "Please select the available time and date slot",
                showConfirmButton: false,
                timer: 2000,
                width: 600,
                height: 300,


            });
            setSelectedDateTime(null); // Clear the date date and time

            return;
        }

        setSelectedDateTime(date);




    };
    const handleDateChange2 = (date) => {

        const currentDate = new Date(); // Current date and time


        const selectedDate = new Date(date);
        selectedDate.setSeconds(0); // Reset the seconds to avoid inconsistencies


        if (selectedDate < currentDate) {
            //alert("Please select the available time and date slot")
            Swal.fire({
                icon: "error",

                text: "Please select the available time and date slot",
                showConfirmButton: false,
                timer: 2000,
                width: 600,
                height: 300,


            });

            setSelectedDateTime2(null); // Clear the date date and time
            return;
        }

        setSelectedDateTime2(date);



    };

    const filterPastDates = (date) => {
        const currentDate = new Date();
        return date > currentDate;
    };

    const getDate = (dateTime) => {
        return dateTime ? dateTime?.toLocaleDateString('en-GB') : '';
    };


    const getTime = (dateTime) => {
        if (!dateTime) return '';

        const currentDate = new Date();
        const selectedDate = new Date(dateTime);

        if (selectedDate < currentDate) {
            alert("Please select the available time and date slot")
            setSelectedDateTime(null);
            return ''
        }; // Return empty string for past times

        return dateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

        //   return dateTime ? dateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' }) : '';
    };

    const selectedDat = getDate(selectedDateTime);
    const selectedTim = getTime(selectedDateTime);


    const getDate2 = (dateTime) => {
        return dateTime ? dateTime?.toLocaleDateString('en-GB') : '';
    };


    const getTime2 = (dateTime) => {
        if (!dateTime) return '';

        const currentDate = new Date();
        const selectedDate = new Date(dateTime);

        if (selectedDate < currentDate) {
            alert("Please select the available time and date slot")
            setSelectedDateTime2(null);
            return ''
        }; // Return empty string for past times

        return dateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

        //   return dateTime ? dateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' }) : '';
    };

    const selectedDat2 = getDate2(selectedDateTime2);
    const selectedTim2 = getTime2(selectedDateTime2);



    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            className="custom-input"
            value={value ? value.toLocaleString() : ''}
            onClick={onClick}
            ref={ref}
            placeholder="Select date and time"

        />
    ));


    const CustomInput2 = React.forwardRef(({ value, onClick }, ref) => (
        <input
            className="custom-input"
            value={value ? value.toLocaleString() : ''}
            onClick={onClick}
            ref={ref}
            placeholder="Select date and time"

        />
    ));



    //Emojis handle
    const [inputStr, setInputStr] = useState("");
    const [showPicker, setShowPicker] = useState(false);



    const handleEmojiClick = (e, emojiObject) => {

        setInputStr(prevInput => prevInput + emojiObject.emoji);
        setShowPicker(false);

    };


    const [textthread, setTextThread] = useState([])
    const [characterCount, setCharacterCount] = useState(0);
    const [imgnm2Text, setImgnm2Text] = useState("");

    // const handleTextareaFocus = () => {
    //     const textarea = textareaRef.current;
    //     const cursorPosition = textarea.selectionStart;
    //     const textBeforeCursor = textarea.value.substring(0, cursorPosition);

    //     // Create a hidden div to measure the text size accurately
    //     const hiddenDiv = document.createElement('div');
    //     hiddenDiv.style.position = 'absolute';
    //     hiddenDiv.style.top = '-9999px';
    //     hiddenDiv.style.left = '-9999px';
    //     hiddenDiv.style.width = textarea.clientWidth + 'px';
    //     hiddenDiv.style.font = window.getComputedStyle(textarea).font;
    //     hiddenDiv.textContent = textBeforeCursor;
    //     document.body.appendChild(hiddenDiv);

    //     const index = hiddenDiv.clientHeight / parseFloat(window.getComputedStyle(textarea).lineHeight);

    //     // Remove the hidden div
    //     document.body.removeChild(hiddenDiv);
    //     // alert('Cursor is inside the textarea at index: ' + index);
    //     console.log('Cursor is inside the textarea at index: ' + index);



    // };

    const handleTextChange = (e, index) => {
        let textData = ""
        if (e.target) {
            textData = e.target.value
        } else {
            textData = e
        }
        const inputString = textData;
        e.target && adjustTextareaHeight(e.target); // remove this resizing text area if needed

        e.target && resetTextareaHeight(e.target)
        const inputWords = inputString.trim().split(/\s+/);
        const currentWordCount = inputString.length;

        setTweetText(inputString)
        if (showthread == false) {

            setCharacterCount(inputString.length);
        } else {
            setCharacterCount('');
        }

        // if (showthread == false) {
        //     if (currentWordCount => 280) {
        //         setTweetText(inputString);
        //         setWordCount(currentWordCount);
        //     }
        // }
        // else {
        //     setTweetText(inputString)
        // }

        //  else {
        //     setTweetText(inputWords.slice(0, MAX_WORDS).join(" "));
        // }



        // console.log("eevent.ate", textData)

        // if (textData) {
        //     localStorage.setItem('savedValue', textData);
        //     setIsSaved(true);
        // } else {
        //     localStorage.removeItem('savedValue');
        //     setIsSaved(false);
        // }

        const updatedTextThread = textData.split('\n\n\n').map((text, index1) => {
            const item = {
                // value: text.substring(0, 280),
                value: text.substring(0, 100000000),
                subValue: textData.includes('\n\n\n'),
                gif: '',
                image: selectedImages,
                id: '',
                threadInd: index1
            };






            if (index1 === selectedImageIndex) {
                item.image = selectedImageIndex; // Assign the selected image to the item

            }
            if (item.subValue == true) {
                setShowthread(true)
            }

            console.log(item);
            return item;


        });

        console.log("Curr", selectedImages)

        setThreadnew(updatedTextThread)

        if (textData == '') {
            setShowthread(false)
            {
                setContext({
                    ...context,
                    t: { ...context.t, thread: null, image: null, gif: null, text: '', }


                })
            }


        }

        setTextThread(updatedTextThread)



        let pureText = tweetText
        {
            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                let imgnam = tweetText.substring(imgind, imgind + 13);
                pureText = pureText.replace(imgnam, "")

            })
        }
        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);
        console.log("wifth", withoutGif)



        localStorage.setItem('savedValue', withoutGif);

        // setIsSaved(true);
    }

    function findNextIndex(sentence, word) {
        let indFound = sentence?.indexOf(word, 0);
        let occurrences = [];
        for (let i = 0; i < sentence?.length; i++) {
            if (indFound > -1) {
                occurrences.push(indFound);
                indFound = sentence?.indexOf(word, indFound + 1);
            } else {
                break;
            }
        }
        return occurrences;
    }


    console.log("context-text", textthread)







    const adjustTextareaHeight = (textarea) => {

        textarea.style.height = 'auto';

        //textarea.style.height = `${textarea.scrollHeight}px`;
        textarea.style.height = `${Math.min(textarea.scrollHeight, 500)}px`; // Set a maximum height of 200px


    };

    const handleResetHeight = (textarea) => {
        textarea.style.height = '150px'; // Set the original height
    };



    const resetTextareaHeight = () => { }



    const calculateHeight = () => {
        const lineHeight = 20; // Adjust this based on your font size and line height
        const maxRows = 25; // Maximum number of visible rows
        const defaultHeight = 200;


        const maxHeight = lineHeight * maxRows;

        const lineCount = tweetText.split('\n').length;
        const calculatedHeight = lineHeight * Math.max(1, lineCount);
        return Math.min(defaultHeight + calculatedHeight, maxHeight);
        // return Math.min(calculatedHeight, maxHeight);
    };



    const [selectedItem, setSelectedItem] = useState(null);

    const [isSaved, setIsSaved] = useState(true);

    useEffect(() => {
        const savedValue = localStorage.getItem('savedValue');
        console.log("useeffect", savedValue);
        if (savedValue) {
            setTweetText(savedValue);
            setIsSaved(true);
            setCharacterCount(savedValue.length)
        }
    }, []);

    useEffect(() => {
        console.log("useeffect", contextData.DraftSaved);
        if (contextData.DraftSaved == true) {
            setTweetText('')
            setShowthread(false)

        }

    }, [contextData.DraftSaved])


    useEffect(() => {
        displayProfile()

    }, [])

    const displayProfile = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {

            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("profile-data", data.data.user)

                    if (data.code == 200) {

                        setDisplayProfileDetails(data.data.user)
                        setTimeZoneV(data.data.user.timezone)


                    }
                    else {
                        setDisplayProfileDetails()


                        console.log("failed")


                    }
                }
                else {
                    console.log(`User with email ${emailTo} not found`);
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
        else {
            try {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/user/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    console.log("profile-data", data.data.user)

                    if (data.code == 200) {

                        setDisplayProfileDetails(data.data.user)
                        setTimeZoneV(data.data.user.timezone)


                    }
                    else {
                        setDisplayProfileDetails()


                        console.log("failed")


                    }
                }
                else {
                    console.log(`User with email ${emailTo} not found`);
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };





    useEffect(() => {
        console.log("conetxt-dta", contextData.publishedPost)
        if (contextData.publishedPost) {
            setTweetText('')
            setShowthread(false)
            setContextData({ publishedPost: false })
            handleResetHeight(document.querySelector('textarea'));
        }
        else if (contextData.QUEUESaved) {
            setTweetText('')
            setShowthread(false)
            setContextData({ QUEUESaved: false })
            handleResetHeight(document.querySelector('textarea'));
        }




    }, [contextData.publishedPost, contextData.QUEUESaved])

    function getFileNameFromUrl(url) {
        const parts = url.split('/');
        return parts[parts.length - 1];
    }

    const [selectedGifpicker2, setSelectedGifPicker2] = useState([])
    const [imageUrl, setImageUrl] = useState([])
    const [GifUrl, setGifUrl] = useState([])
    const [VideoUrl, setVideoUrl] = useState("")





    const convertToTimeZone = (time, targetTimeZone) => {
        const options = {
            timeZone: targetTimeZone,
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        };

        return new Date(time).toLocaleString('en-US', options);
    };



    useMemo(async () => {

        console.log("useMemo");
        if (cont.t?.thread && cont.t?.thread.length > 0) {

            const newTextValues = cont.t.thread.map((it) => it.text);

            const updatedTweetText = [...newTextValues].join("\n\n\n");
            setTweetText(updatedTweetText);

            // const newTextValues = cont.t.thread.map((it) => it.full_text);

            // const updatedTweetText = [...newTextValues].join("\n\n\n");
            // setTweetText(updatedTweetText);

            if (updatedTweetText) {


                const updatedTextThread = cont.t.thread.map((ele, index1) => {
                    const item = {
                        value: ele.text,
                        subValue: ele.text.includes('\n\n\n'),
                        gif: '',
                        image: ele.selectedImages,
                        id: ele.id
                    };


                    if (index1 === selectedImageIndex) {
                        item.image = selectedImageIndex; // Assign the selected image to the item

                    }
                    if (item.subValue == true) {
                        setShowthread(true)
                    }

                    return item;



                });


                setTextThread(updatedTextThread)

                setShowthread(true)


            }


        }
        else {
            setShowthread(false)


            if (!localStorage.getItem('savedValue')) {
                // setTweetText(cont.t ? cont?.t?.text : (cont?.text ? cont?.text : tweetText));
                setTweetText(cont.t ? cont?.t?.full_text : (cont?.text ? cont?.text : tweetText));
                setCharacterCount(cont?.t ? cont?.t?.text?.length : (cont.text ? cont?.text?.length : tweetText?.length));
            }
            else {

            }

        }



        // if (cont?.t?.time == undefined || cont?.t?.time == '') {
        //     setQueueTime(cont?.t?.thread[0]?.time)
        // }
        // else {
        //     setQueueTime(cont?.t ? cont?.t?.time : '')
        // }



        setQueueTime(cont?.t ? cont?.t?.time : '')





        const schDate = cont?.t ? moment(cont?.t?.schedule_date, 'DD-MM-YYYY').format("DD/MM/YYYY") : ''
        const schTime = cont?.t ? moment(cont?.t?.schedule_time, 'hh:mm:ss').format("hh:mm A") : ''

        console.log("schdlue Time", schDate, schTime)



        setImageUrl(cont?.t ? cont?.t?.image : '')
        setGifUrl(cont?.t ? cont?.t?.gif : [])
        setVideoUrl(cont.t ? cont?.t?.video : '')




        if (cont?.t?.image) {
            setNewGif('')
            setSelectedGifPicker("")
        }
        else if (cont?.t?.gif) {
            setNewGif('')
            // setArrImg('')
            setSelectedGifPicker('')

        }


        if (cont?.t) {
            setArrImg([])
            setSelectedImages('')
            setSelectedGifPicker('')
        }

        if (cont?.hitButton) {
            setShowQueue(true)
        }


        const selectedImagesw = Array.isArray(cont?.t?.image) ? cont?.t?.image.map((image) => ({ img: image })) : [];

        setSelectedImages(selectedImagesw);
        setSelectedImages3(selectedImagesw);



        const selectedGigw = Array.isArray(cont?.t?.gif) ? cont?.t?.gif?.map((gif) => ({ g: gif })) : [];
        setSelectedGifPicker2(selectedGigw);

        // if (selectedGigw.length > 0) {
        //     try {
        //         const response = await fetch(selectedGigw[0].g);
        //         const blob = await response.blob();
        //         const file = new File([blob], 'selectedGif.gif');
        //         console.log("gif++++", file);
        //         setNewGif(file);
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }


        // setArrImg(selectedImagesw)



        // try {
        //     const files = [];

        //     for (let i = 0; i < selectedImagesw.length; i++) {
        //         const image = selectedImagesw[i];
        //         const response = await fetch(image.img);
        //         const blob = await response.blob();
        //         const file = new File([blob], `selectedGif_${i}.jpeg`);
        //         files.push(file);
        //     }



        //     // setArrImg(files);
        // } catch (error) {
        //     console.log(error);
        // }
    }, [cont]);





    console.log("Dcdscs", queueTime)






    // // Create a date object with the current time
    // let currentDate = new Date();

    // // Convert the time to the America/New_York time zone
    // let newYorkTime = currentDate.toLocaleString("en-US", { timeZone: "America/New_York" });

    // console.log("New York time: " + newYorkTime, "Dcdcfe", currentDate);





    // Create a date object with the current date and the time you have
    let currentDate = new Date(); // use the current date
    let inputTime = queueTime; // replace this with the time you have

    // Parse the input time and set it to the current date
    let inputTimeParts = inputTime?.split(":");
    if (inputTimeParts && inputTimeParts.length === 2) {
        currentDate.setHours(parseInt(inputTimeParts[0], 10));
        currentDate.setMinutes(parseInt(inputTimeParts[1], 10));
    } else {
        // Handle the case where inputTime is not in the expected format
        console.log('Input time is not in the expected format');
    }

    // Convert the time to the America/New_York time zone
    let newYorkTime = currentDate.toLocaleString("en-US", { timeZone: timeZone ? timeZone : 'IST', hour12: true, timeStyle: "short" });

    console.log("New York time: " + newYorkTime);





    console.log("Sdcds",timeZone)




    const handleAutoSaved = (e) => {
        e.preventDefault()
        if (isSaved) {
            // Remove the value from localStorage
            localStorage.removeItem('savedValue');
            // setTweetText('');
            setIsSaved(false);

        } else {

            // Save the value to localStorage
            console.log("e. dcbhdsc", e.target.value)
            localStorage.setItem('savedValue', e.target.value);
            setIsSaved(true);

        }


    };


    const MAX_WORDS = 280;
    // const remainingWords = tweetText?.trim() ? tweetText.trim().split(/\s+/).length : 0;
    const remainingWords = tweetText ? tweetText.length : 0;


    const [wordCount, setWordCount] = useState(0);
    //image handle
    let imageName = '';
    function randomImageName(len, type) {
        var alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';


        for (var i = len; i > 0; i--) {
            imageName += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
        }


        return `[ ${type}: ` + imageName + `]`;

    }

    // importantfuntion below

    // const handleImageSelect = (e) => {

    //     let imgnm = randomImageName(5)

    //     setVideoPreviews({ preview: '', type: "video", file: '' });

    //     handleRemoveGif()
    //     setVideoUrl('')
    //     const files = Array.from(e.target.files);
    //     const newImages = [...selectedImages];

    //     if (selectedImages.length + files.length > 5) {
    //         alert('Only 5 images can be uploaded at a time.');
    //         return;
    //     }

    //     files.forEach((file) => {
    //         const reader = new FileReader();

    //         reader.onload = () => {
    //             newImages.push({ img: reader.result, name: imgnm, imgFile: e.target.files[0] });
    //             setSelectedImages(newImages);
    //         };

    //         reader.readAsDataURL(file);
    //     });

    //     setArrImg(a => [...a, e.target.files[0]])

    //     setSelectedImageIndex(e.target.files)


    //     let a = tweetText?.substring(0, selectionStartVal + 1) + imgnm + tweetText?.substring(selectionStartVal + 1)
    //     // setTweetText(a.replace(imgnm, ""))

    //     const updatedTextThread = a.split('\n\n\n').map((text, index1) => {
    //         const item = {
    //             value: text,
    //             subValue: a.includes('\n\n\n'),
    //             gif: '',
    //             image: null
    //         };


    //         if (index1 === selectedImageIndex) {
    //             item.image = selectedImageIndex; // Assign the selected image to the item


    //         }
    //         if (item.subValue == true) {
    //             setShowthread(true)
    //         }

    //         return item;



    //     });



    //     setTextThread(updatedTextThread)

    // };


    const handleImageSelect = (event) => {
        const files = event.target.files;
        // let imgnm = randomImageName(5)
        let cursorPosition = document.getElementById('t1').selectionStart
        //setTweetText(tweetText.slice(0, cursorPosition) + imgnm + tweetText.slice(cursorPosition, tweetText.length + 1))

        // Separate images and videos based on file type
        const newSelectedImages = [];
        let newSelectedVideo = null;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.startsWith('image/') && newSelectedImages.length < 4) {

                if (file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg") {

                    setVideoPreviews({ preview: '', type: "video", file: '' });

                    handleRemoveGif()
                    setVideoUrl('')

                    // let imgnm = randomImageName(5, 'img')
                    // console.log("image name ", imgnm)
                    // setTweetText(tweetText.slice(0, cursorPosition) + imgnm + tweetText.slice(cursorPosition, tweetText.length + 1))

                    // let str = tweetText;

                    // let imgnm2 = imgnm


                    let str = tweetText;
                    let imgnm = randomImageName(5, 'img')

                    let cursorPosition = document.getElementById('t1').selectionStart

                    let withoutVdo = getTextWithoutImg(str, `[ vid: `);
                    let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                    console.log({ withoutGif, tweetText });
                    if (withoutGif?.length >= 0) {
                        str = withoutGif;
                        str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

                    } else {
                        str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

                    }
                    setTweetText(str)

                    let imgnm2 = imgnm



                    const files = Array.from(event.target.files);
                    const newImages = [...selectedImages]


                    files.forEach((file) => {


                        const reader = new FileReader();

                        reader.onload = () => {
                            newImages.push({ img: reader.result, name: imgnm, name2: imgnm2, imgFile: event.target.files[0], type: 'img' });
                            setSelectedImages(newImages);
                        };

                        reader.readAsDataURL(file);
                    });
                    console.log('Currimg', selectedImages)

                    setArrImg(a => [...a, event.target.files[0]])

                    setSelectedImageIndex(event.target.files)
                    newSelectedImages.push(file);


                    // const fileInput = document.getElementById('image-upload'); // Replace with your actual input ID
                    // if (fileInput) {
                    //     fileInput.value = '';
                    // }





                }
                else {

                    toast.error("Please upload jpg and png formate image file", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

            } else if (file.type.startsWith('video/') && !newSelectedVideo) {
                // handleRemoveImage()
                handleRemoveAll()
                handleRemoveGif()
                setVideoUrl('')

                const file = event.target.files[0];
                console.log("files", file)


                // let imgnm = randomImageName(5, 'vid')

                // setTweetText(tweetText.slice(0, cursorPosition) + imgnm + tweetText.slice(cursorPosition, tweetText.length + 1))

                // let str = tweetText;


                let str = tweetText ?? '';
                let imgnm = randomImageName(5, 'vid')

                let cursorPosition = document.getElementById('t1').selectionStart
                let withoutimg = getTextWithoutImg(str, `[ img: `);
                let withoutVdo = getTextWithoutImg(withoutimg, `[ vid: `);
                let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                console.log({ withoutGif, tweetText });
                if (withoutGif?.length >= 0) {
                    str = withoutGif;
                    str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

                } else {
                    str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

                }
                setTweetText(str)

                let imgnm2 = imgnm

                if (file) {
                    const fileInput = document.getElementById("image-upload"); // replace with your actual file input ID
                    fileInput.value = ''; // Clear the input value
                    fileInput.click(); // Trigger the file input click event

                    if (file.type === "video/mp4" || file.type === "video/quicktime") {
                        const reader = new FileReader();

                        reader.onload = (event) => {
                            console.log("event==>", event);
                            //handleVideoAPIUpload(file)
                            setVideoPreviews({ preview: event.target.result, name: imgnm, name2: imgnm2, type: "video", file: file });

                        };
                        // let a = tweetText.substring(0, selectionStartVal + 1) + imgnm + tweetText.substring(selectionStartVal + 1)
                        // setTweetText(a)
                        // // setTweetText(a.replace(imgnm, ""))

                        reader.readAsDataURL(file);
                        // setImgnm2Text(imgnm)




                    } else {

                        toast.error("Please upload a valid MP4 or MOV video file.", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                } else {
                    // Clear the video preview when no file is selected
                    setVideoPreviews(null);
                    // setImgnm2Text("");
                }
                newSelectedVideo = file;
            }
        }

        // setSelectedImages(newSelectedImages);
        setVideoPreviews({ preview: event.target.result, type: "video", file: newSelectedVideo });

    };

    console.log("selec image", selectedImages, arrImg)

    function getTextWithoutImg(sentence, word) {
        let indFound = sentence?.indexOf(word);
        let formateedSentence = sentence;
        for (let i = 0; i < sentence?.length; i++) {
            if (indFound > -1) {
                let imgNm = sentence?.substring(indFound, indFound + 13);
                indFound = sentence?.indexOf(word, indFound + 1);
                formateedSentence = formateedSentence?.replace(imgNm, "");
            } else {
                break;
            }
        }
        console.log("getTextWithoutImg", { indFound, word, sentence, tweetText, formateedSentence });

        return formateedSentence;
    }
    function replaceVdoName(sentence, word, newimgnm) {
        let indFound = sentence.indexOf(word);
        let formateedSentence = sentence;
        for (let i = 0; i < sentence.length; i++) {
            if (indFound > -1) {
                let imgNm = sentence.substring(indFound, indFound + 13);
                indFound = sentence.indexOf(word, indFound + 1);
                formateedSentence = formateedSentence.replace(imgNm, newimgnm);
            } else {
                break;
            }
        }
        return formateedSentence;
    }
    const removeMediaFromStr = (strr, cursorPosition, type) => {
        let strOnCursor = ""
        let str = strr
        let strToReturn = ""
        let indexes = []
        for (let i = 0; i <= str.length - 1; i++) {
            if (str[i] === "\n") {
                indexes.push(i)
            }
        }
        // for (let i = 0; i <= indexes.length; i++) {

        let aaaTest = indexes.forEach((el, i) => {
            if (strToReturn === "") {
                if (
                    (indexes[i] < cursorPosition && indexes[i + 1] >= cursorPosition)
                ) {
                    strOnCursor = str.slice(indexes[i], indexes[i + 1])

                    let strOnCursorWoMedia = getTextWithoutImg(strOnCursor, `[ ${type}: `);
                    console.log(str, "removeMediaFromStr", strOnCursorWoMedia, i, cursorPosition)
                    strToReturn = str.slice(0, indexes[i]) + strOnCursorWoMedia + str.slice(indexes[i + 1])
                    console.log({ strOnCursor, str, indexes, strToReturn, cursorPosition })
                    return;
                } else if (
                    0 <= cursorPosition && indexes[0] >= cursorPosition
                ) {
                    strOnCursor = str.slice(0, indexes[0])

                    let strOnCursorWoMedia = getTextWithoutImg(strOnCursor, `[ ${type}: `);
                    console.log(str, "removeMediaFromStr", strOnCursorWoMedia, i, cursorPosition)
                    strToReturn = strOnCursorWoMedia + str.slice(indexes[0])
                    console.log({ strOnCursor, str, indexes, strToReturn, cursorPosition })
                    return;
                } else if (
                    (indexes[indexes.length - 1] <= cursorPosition)
                ) {
                    strOnCursor = str.slice(indexes[indexes.length - 1])

                    let strOnCursorWoMedia = getTextWithoutImg(strOnCursor, `[ ${type}: `);
                    console.log(str, "removeMediaFromStr", strOnCursorWoMedia, i, cursorPosition)
                    strToReturn = str.slice(0, indexes[indexes.length - 1]) + strOnCursorWoMedia
                    console.log({ strOnCursor, str, indexes, strToReturn, cursorPosition })
                    return;
                } else {
                    console.log("else removeMediaFromStr", indexes[i], i, indexes[i + 1], cursorPosition, indexes[0],
                        (indexes[i] < cursorPosition && indexes[i + 1] > cursorPosition)
                        , (0 <= cursorPosition && indexes[0] > cursorPosition)
                        , indexes[indexes.length - 1] <= cursorPosition)
                }

                // }
            }
        })
        console.log({ aaaTest, strOnCursor, str, indexes, strToReturn, cursorPosition })

        return strToReturn;
    }


    const replaceVdoInThread = (strr, cursorPosition, type, imgnm) => {
        let strOnCursor = ""
        let str = strr
        let strToReturn = ""
        let indexes = []
        for (let i = 0; i <= str.length - 1; i++) {
            if (str[i] === "\n") {
                indexes.push(i)
            }
        }
        // for (let i = 0; i <= indexes.length; i++) {

        let aaaTest = indexes.forEach((el, i) => {
            if (strToReturn === "") {
                if (
                    (indexes[i] < cursorPosition && indexes[i + 1] >= cursorPosition)
                ) {
                    strOnCursor = str.slice(indexes[i], indexes[i + 1])
                    if (strOnCursor.includes(type)) {
                        let strOnCursorWoMedia = replaceVdoName(strOnCursor, `[ ${type}: `, imgnm);
                        console.log(str, "replaceVdoInThread", strOnCursorWoMedia, i, cursorPosition)
                        strToReturn = str.slice(0, indexes[i]) + strOnCursorWoMedia + str.slice(indexes[i + 1])

                    } else {
                        strToReturn = ""
                    }
                    console.log({ strOnCursor, str, indexes, strToReturn, cursorPosition })
                    return;
                } else if (
                    0 <= cursorPosition && indexes[0] >= cursorPosition
                ) {
                    strOnCursor = str.slice(0, indexes[0])
                    if (strOnCursor.includes(type)) {

                        let strOnCursorWoMedia = replaceVdoName(strOnCursor, `[ ${type}: `, imgnm);
                        console.log(str, "replaceVdoInThread", strOnCursorWoMedia, i, cursorPosition)
                        strToReturn = strOnCursorWoMedia + str.slice(indexes[0])

                    } else {
                        strToReturn = ""
                    }
                    console.log({ strOnCursor, str, indexes, strToReturn, cursorPosition })
                    return;
                } else if (
                    (indexes[indexes.length - 1] <= cursorPosition)
                ) {
                    strOnCursor = str.slice(indexes[indexes.length - 1])
                    if (strOnCursor.includes(type)) {

                        let strOnCursorWoMedia = replaceVdoName(strOnCursor, `[ ${type}: `, imgnm);
                        console.log(str, "replaceVdoInThread", strOnCursorWoMedia, i, cursorPosition)
                        strToReturn = str.slice(0, indexes[indexes.length - 1]) + strOnCursorWoMedia

                    } else {
                        strToReturn = ""
                    }
                    console.log({ strOnCursor, str, indexes, strToReturn, cursorPosition }, strOnCursor.includes(type))
                    return;
                } else {
                    console.log("else replaceVdoInThread", indexes[i], i, indexes[i + 1], cursorPosition, indexes[0],
                        (indexes[i] < cursorPosition && indexes[i + 1] > cursorPosition)
                        , (0 <= cursorPosition && indexes[0] > cursorPosition)
                        , indexes[indexes.length - 1] <= cursorPosition)
                }

                // }
            }
        })
        console.log({ aaaTest, strOnCursor, str, indexes, strToReturn, cursorPosition, type })

        return strToReturn;
    }




    const [inputKey, setInputKey] = useState(0);


    const handleImageSelect2 = (event) => {
        const files = event.target.files;

        // Separate images and videos based on file type
        const newSelectedImages = [];
        let newSelectedVideo = null;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.startsWith('image/')) {
                if (file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg") {
                    let imgnm = randomImageName(5, 'img')
                    let cursorPosition = document.getElementById('t1').selectionStart
                    setTweetText(tweetText.slice(0, cursorPosition) + imgnm + tweetText.slice(cursorPosition, tweetText.length + 1))

                    let str = tweetText;

                    str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)


                    let removedVidStr = removeMediaFromStr(str, cursorPosition, "vid")
                    console.log(removedVidStr, 'removedVidStrremovedVidStrremovedVidStr removeMediaFromStr')

                    let removedGifStr = removeMediaFromStr(removedVidStr, cursorPosition, "gif")

                    setTweetText(removedGifStr)
                    setInputKey((prevKey) => prevKey + 1);

                    // setVideoPreviews2({ preview: '', type: "video", file: '' });
                    // setVideoPreviews2([]);
                    let imgnm2 = imgnm

                    // handleRemoveGif()
                    // setVideoUrl('')
                    const files = Array.from(event.target.files);
                    const newImages = [...selectedImages2]
                    files.forEach((file) => {
                        const reader = new FileReader();

                        reader.onload = () => {
                            newImages.push({ img: reader.result, name: imgnm, name2: imgnm2, imgFile: event.target.files[0], type: 'img' });
                            setSelectedImages2(newImages);
                        };

                        reader.readAsDataURL(file);
                    });
                    console.log('Currimg', selectedImages2)

                    setArrImg2(a => [...a, event.target.files[0]])

                    setSelectedImageIndex(event.target.files)
                    newSelectedImages.push(file);




                    // let a = removedGifStr?.substring(0, selectionStartVal + 1) + imgnm + removedGifStr?.substring(selectionStartVal + 1)
                    // setTweetText(a.replace(imgnm, ""))

                    const updatedTextThread = removedGifStr.split('\n\n\n').map((text, index1) => {
                        const item = {
                            value: text,
                            subValue: removedGifStr.includes('\n\n\n'),
                            gif: '',
                            image: null
                        };


                        if (index1 === selectedImageIndex) {
                            item.image = selectedImageIndex; // Assign the selected image to the item


                        }
                        if (item.subValue == true) {
                            setShowthread(true)
                        }

                        return item;



                    });



                    setTextThread(updatedTextThread)







                }
                else {

                    toast.error("Please upload jpg and png formate image file", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

            } else if (file.type.startsWith('video/') && !newSelectedVideo) {
                // handleRemoveImage()
                // handleRemoveAll()
                // handleRemoveGif()
                // setVideoUrl('')

                const file = event.target.files[0];
                console.log("files", file)

                if (file) {
                    if (file.type === "video/mp4" || file.type === "video/quicktime") {
                        let str = tweetText;
                        let imgnm = randomImageName(5, 'vid')

                        let cursorPosition = document.getElementById('t1').selectionStart
                        let vdoExist = replaceVdoInThread(tweetText, cursorPosition, "vid", imgnm)
                        console.log({ vdoExist, tweetText });
                        if (vdoExist?.length > 0) {
                            str = vdoExist;
                            // str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

                        } else {
                            str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

                        }



                        let removedVidStr = removeMediaFromStr(str, cursorPosition, "img")
                        console.log(removedVidStr, 'removedVidStrremovedVidStrremovedVidStr removeMediaFromStr')

                        let removedGifStr = removeMediaFromStr(removedVidStr, cursorPosition, "gif")

                        setTweetText(removedGifStr)
                        setInputKey((prevKey) => prevKey + 1);

                        const reader = new FileReader();
                        let imgnm2 = imgnm
                        // reader.onload = (event) => {
                        //     console.log("event==>", event);

                        //     setVideoPreviews({ preview: event.target.result, name: imgnm, name2: imgnm2, type: "video", file: file });
                        // };

                        // reader.readAsDataURL(file);
                        const files = Array.from(event.target.files);
                        const newVideo = [...videoPreviews2]


                        files.forEach((file) => {
                            const reader = new FileReader();

                            reader.onload = (event) => {
                                newVideo.push({ preview: event.target.result, name: imgnm, name2: imgnm2, imgFile: file, type: 'video' });
                                setVideoPreviews2(newVideo);
                            };

                            reader.readAsDataURL(file);
                        });



                        // let a = removedGifStr?.substring(0, selectionStartVal + 1) + imgnm + removedGifStr?.substring(selectionStartVal + 1)
                        // setTweetText(a.replace(imgnm, ""))

                        const updatedTextThread = removedGifStr.split('\n\n\n').map((text, index1) => {
                            const item = {
                                value: text,
                                subValue: removedGifStr.includes('\n\n\n'),
                                gif: '',
                                image: null
                            };


                            if (index1 === selectedImageIndex) {
                                item.image = selectedImageIndex; // Assign the selected image to the item


                            }
                            if (item.subValue == true) {
                                setShowthread(true)
                            }

                            return item;



                        });


                        setTextThread(updatedTextThread)



                    } else {

                        toast.error("Please upload a valid MP4 or MOV video file.", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                } else {
                    // Clear the video preview when no file is selected
                    setVideoPreviews2(null);
                    // setImgnm2Text("");
                }
                newSelectedVideo = file;
            }
        }

        // setSelectedImages(newSelectedImages);
        // setVideoPreviews2({ preview: event.target.result, type: "video", file: newSelectedVideo });

    };
    console.log("selectedImages", videoPreviews, videoPreviews2)

    const handleVideoAPIUpload = async (file) => {

        try {

            // setLoading2(true);
            let fdata = new FormData();


            fdata.append('video', file);

            fdata.append('provider_token', providerToken);

            fetch(`${BASE_URL}api/video/upload`, {
                method: 'POST',
                body: fdata,
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                }
            }).then(response => {


                return response.json()
            }).then(data => {
                console.log("tweet000", data);


                if (data.code == 200) {

                    toast.success(data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setMediaID(data)


                }


                else {

                    toast.error(data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });


                }

                // setLoading2(false);
            })



        } catch (error) {
            console.log(error)

        }
    }


    const handleRemoveImage = (index) => {

        //const newImages = [...selectedImages];
        const newImages = [...selectedImages3];
        const newArrImg = [...arrImg];
        if (imageUrl) {


            const newArrImgURL = [...imageUrl]
            newArrImgURL.splice(index, 1);
            setImageUrl(newArrImgURL)
        }

        newImages.splice(index, 1); // Remove image from selectedImages array
        newArrImg.splice(index, 1); // Remove corresponding image file from arrImg array


        //setSelectedImages(newImages); // Update selectedImages state

        setSelectedImages3(newImages); // Update selectedImages state
        setArrImg(newArrImg); // Update arrImg state




    };

    const handleRemoveVideo = () => {
        setVideoUrl('')
    }
    const handleRemoveVideo1 = () => {
        setVideoPreviews({ preview: '', type: "video", file: '' });
    }






    const handleRemoveAll = () => {
        setSelectedImages([])
        setArrImg([])
    }

    const handleItemClickAIKeepWriting = (selectedItem) => {

        setSelectedItem(selectedItem);


        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {

            if (localStorage.getItem("userloggedin") === 'true') {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingKeepWriting(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/continue/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')
                                // alert(JSON.stringify(data.message))
                            }
                            setLoadingKeepWriting(false)

                        })
                    }
                    else {

                    }


                } catch (error) {
                    console.log(error)
                }
            }
            else {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);
                console.log("trarget", targetUser)
                if (targetUser) {
                    const { userID, accessToken } = targetUser;
                    try {

                        setLoadingKeepWriting(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/continue/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')
                                // alert(JSON.stringify(data.message))
                            }
                            setLoadingKeepWriting(false)

                        })



                    } catch (error) {
                        console.log(error)
                    }
                }
                else {

                }
            }
        }



    };

    const handleItemClickAIMoreAssertive = (selectedItem) => {
        // Update the state with the selected item
        setSelectedItem(selectedItem);

        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {
            if (localStorage.getItem("userloggedin") === 'true') {

                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);
                    console.log("trarget", targetUser)
                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingMoreAssertive(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/assertive/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')
                                // alert(JSON.stringify(data.message))
                            }
                            setLoadingMoreAssertive(false)
                        })
                    }
                    else {

                    }



                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);
                    console.log("trarget", targetUser)
                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingMoreAssertive(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/assertive/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')
                                // alert(JSON.stringify(data.message))
                            }
                            setLoadingMoreAssertive(false)
                        })
                    }
                    else {

                    }



                } catch (error) {
                    console.log(error)
                }
            }
        }

    };

    const handleItemClickAIImproveTweet = (selectedItem) => {
        // Update the state with the selected item
        setSelectedItem(selectedItem);


        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {
            if (localStorage.getItem("userloggedin") === 'true') {
                try {

                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingImproveText(true)

                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/improve/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.code == 200) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)

                            }
                            setLoadingImproveText(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {

                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingImproveText(true)

                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/improve/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data, data.content.length);
                            if (data.code == 200) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText(data.content)

                            }
                            setLoadingImproveText(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
        }



    };

    const handleItemClickAIAddEmoji = (selectedItem) => {
        // Update the state with the selected item
        setSelectedItem(selectedItem);


        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {

            if (localStorage.getItem("userloggedin") === 'true') {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);
                    console.log("trarget", targetUser)
                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingAddEmoji(true)

                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/add/emoji`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingAddEmoji(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);
                    console.log("trarget", targetUser)
                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingAddEmoji(true)

                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/add/emoji`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingAddEmoji(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }

        }


    };

    const handleItemClickAIMoreCasual = (selectedItem) => {
        // Update the state with the selected item
        setSelectedItem(selectedItem);


        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {

            if (localStorage.getItem("userloggedin") === 'true') {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);
                    console.log("trarget", targetUser)
                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingMoreCasual(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/casual/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingMoreCasual(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);
                    console.log("trarget", targetUser)
                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingMoreCasual(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/casual/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingMoreCasual(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
        }


    };

    const handleItemClickAIMakeShorter = (selectedItem) => {
        // Update the state with the selected item
        setSelectedItem(selectedItem);


        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {
            if (localStorage.getItem("userloggedin") === 'true') {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingMakeShorter(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/short/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingMakeShorter(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingMakeShorter(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/short/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingMakeShorter(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
        }


    };

    const handleItemClickAIMoreFormal = (selectedItem) => {
        // Update the state with the selected item
        setSelectedItem(selectedItem);


        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {
            if (localStorage.getItem("userloggedin") === 'true') {
                try {

                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingMoreFormal(true)

                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/formal/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingMoreFormal(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {

                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingMoreFormal(true)

                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/formal/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingMoreFormal(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
        }


    };

    const handleItemClickAIExpandTweet = (selectedItem) => {
        // Update the state with the selected item
        setSelectedItem(selectedItem);


        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {
            if (localStorage.getItem("userloggedin") === 'true') {
                try {

                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;

                        setLoadingExpandTweet(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/expand/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingExpandTweet(false)
                        })

                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {

                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;

                        setLoadingExpandTweet(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/expand/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingExpandTweet(false)
                        })

                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
        }


    };

    const handleItemClickAIFixGrammer = (selectedItem) => {
        // Update the state with the selected item
        setSelectedItem(selectedItem);


        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {
            if (localStorage.getItem("userloggedin") === 'true') {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingFixGrammer(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/grammar/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingFixGrammer(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken } = targetUser;
                        setLoadingFixGrammer(true)
                        let fdata = new FormData();


                        fdata.append('id', userID)
                        fdata.append('text', tweetText);


                        fetch(`${BASE_URL}api/ai/grammar/writing`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response API", response)
                            return response.json()
                        }).then(data => {
                            console.log("tweet API text", data);
                            if (data.content) {
                                setTweetText(data.content)
                                setCharacterCount(data.content.length)
                                localStorage.setItem('savedValue', data.content);
                            }

                            else {
                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setTweetText('')

                            }
                            setLoadingFixGrammer(false)
                        })
                    }
                    else {

                    }

                } catch (error) {
                    console.log(error)
                }
            }
        }


    };



    console.log("Selected Item:", selectedItem);



    const [schImage, setSchImage] = useState([])
    const [schGif, setSchGif] = useState()




    // GIF handle 





    const handleGifSelect = (file) => {
        handleRemoveAll()
        if (selectedGif) {
            alert('Only one GIF can be uploaded at a time.');
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedGif(reader.result);
        };
        reader.readAsDataURL(file);
    };


    const handleGifopen = () => {
        setShowEmojiPicker(false)
        setShowGif(prevInput => !prevInput);
        // setShowPicker(false);

    };
    const handleGifopen2 = () => {
        setShowEmojiPicker(false)
        setShowGif2(prevInput => !prevInput);
        // setShowPicker(false);

    };


    const deleteCall = () => {
        setTweetText('')
        setTextThread([])
        setSelectedGifPickerArr([])
        setSelectedImages([])
    }

    const handleGifPicker = async (gif, index) => {

        setVideoPreviews({ preview: '', type: "video", file: '' });


        handleRemoveImage()
        handleRemoveGif()
        setImageUrl([])
        setVideoUrl('')
        // setGifUrl('')




        let imgnm = randomImageName(5, 'gif')
        handleRemoveAll()

        setSelectedGifPicker(gif.images.original.url);

        let cursorPosition = document.getElementById('t1').selectionStart
        // let newStr = tweetText.slice(0, cursorPosition) + imgnm + tweetText.slice(cursorPosition, tweetText.length + 1)
        let str = tweetText
        console.log({ str }, 'BB');

        let withoutGIF = getTextWithoutImg(str, `[ gif: `);
        let withoutVdo = getTextWithoutImg(withoutGIF, `[ vid: `);
        let withoutImg = getTextWithoutImg(withoutVdo, `[ img: `);


        console.log({ withoutImg, tweetText, imgnm }, 'AA');
        if (withoutImg?.length >= 0) {
            str = withoutImg;
            str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

        } else {
            str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

        }
        console.log({ withoutImg, tweetText, imgnm, str }, 'BB');

        setTweetText(str)




        // //   newGifsPicker.push({ img: reader.result, name: imgnm });
        // setSelectedGifPickerArr(a => [{ gif: gif.images.original.url, name: imgnm }]);


        setShowGif(prevInput => !prevInput);
        try {
            const response = await fetch(gif.images.original.url);
            const blob = await response.blob();
            const file = new File([blob], 'selectedGif.gif');

            setNewGif(file)
            setSelectedGifOb({
                gif: gif.images.original.url,
                name: imgnm,
                type: 'gif',
                filest: file

            })


        } catch (error) {
            console.log(error, "error gif");
        }

        console.log("6", gif)

        setSelectedGifIndex(gif.images.original.url)




        // let a = tweetText.substring(0, selectionStartVal + 1) + imgnm + tweetText.substring(selectionStartVal + 1)
        // // setTweetText(a)
        // setTweetText(a.replace(imgnm, ""))
        // const updatedTextThread = a.split('\n\n\n').map((text, index1) => {
        //     const item = {
        //         value: text,
        //         subValue: a.includes('\n\n\n'),
        //         gif: '',
        //         image: null
        //     };


        //     if (index1 === selectedGifIndex) {
        //         item.gif = selectedGifIndex; // Assign the selected image to the item


        //     }
        //     if (item.subValue == true) {
        //         setShowthread(true)
        //     }
        //     console.log(item);
        //     return item;



        // });

        // setTextThread(updatedTextThread)


    };


    const [laodingGifPic, setLoadingGifPic] = useState(false)

    console.log("lKAJSJSJS", NewGif, selectedGifpicker, selectedGifOb)


    // const handleGifPicker = async (gif, index) => {

    //     setVideoPreviews({ preview: '', type: "video", file: '' });


    //     handleRemoveImage()
    //     handleRemoveGif()
    //     setImageUrl([])
    //     setVideoUrl('')
    //     // setGifUrl('')

    //     let imgnm = randomImageName(5, 'gif')
    //     handleRemoveAll()
    //     let cursorPosition = document.getElementById('t1').selectionStart
    //     // let newStr = tweetText.slice(0, cursorPosition) + imgnm + tweetText.slice(cursorPosition, tweetText.length + 1)
    //     let str = tweetText
    //     let vdoExist = replaceVdoInThread(tweetText, cursorPosition, "gif", imgnm)
    //     console.log({ vdoExist, tweetText });
    //     if (vdoExist?.length > 0) {
    //         str = vdoExist;
    //         // str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

    //     } else {
    //         str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

    //     }


    //     let removedImg = removeMediaFromStr(str, cursorPosition, "img")
    //     if (removedImg.length > 0)
    //         str = removedImg
    //     let removedVdo = removeMediaFromStr(str, cursorPosition, "vdo")
    //     if (removedVdo.length > 0)
    //         str = removedVdo





    //     setTweetText(str)

    //     setSelectedGifPicker(gif.images.original.url);
    //     console.log({ cursorPosition, tweetText, imgnm, removedImg, removedVdo, str }, "handleGifPicker")



    //     if (!selectedGifpickerArr.some((selectedGif) => selectedGif.gif === gif.images.original.url)) {
    //         const response = await fetch(gif.images.original.url);
    //         const blob = await response.blob();
    //         const file = new File([blob], 'selectedGif.gif');



    //         setSelectedGifPickerArr([
    //             ...selectedGifpickerArr,
    //             {
    //                 gif: gif.images.original.url,
    //                 type: 'gif',
    //                 name: imgnm,
    //                 filest: file
    //             },
    //         ]);
    //     }



    //     setShowGif(prevInput => !prevInput);
    //     try {
    //         const response = await fetch(gif.images.original.url);
    //         const blob = await response.blob();
    //         const file = new File([blob], 'selectedGif.gif');


    //         setNewGif(file)


    //     } catch (error) {
    //         console.log(error);
    //     }

    //     setSelectedGifIndex(gif.images.original.url)


    //     // let a = tweetText.substring(0, selectionStartVal + 1) + imgnm + tweetText.substring(selectionStartVal + 1)
    //     // setTweetText(a)
    //     // setTweetText(a)
    //     const updatedTextThread = removedVdo.split('\n\n\n').map((text, index1) => {
    //         setLoadingGifPic(true)
    //         const item = {
    //             value: text,
    //             subValue: removedVdo.includes('\n\n\n'),
    //             gif: '',
    //             image: null
    //         };


    //         if (index1 === selectedGifIndex) {
    //             item.gif = selectedGifIndex; // Assign the selected image to the item


    //         }
    //         if (item.subValue == true) {
    //             setShowthread(true)
    //         }
    //         setLoadingGifPic(false)
    //         console.log(item);
    //         return item;



    //     });

    //     setTextThread(updatedTextThread)
    //     console.log({ cursorPosition, tweetText, imgnm, removedVdo, updatedTextThread }, "handleGifPicker")


    // };


    const handleGifPicker2 = async (gif, index) => {

        setVideoPreviews({ preview: '', type: "video", file: '' });


        handleRemoveImage()
        handleRemoveGif()
        setImageUrl([])
        setVideoUrl('')
        // setGifUrl('')

        let imgnm = randomImageName(5, 'gif')
        handleRemoveAll()
        let cursorPosition = document.getElementById('t1').selectionStart
        // let newStr = tweetText.slice(0, cursorPosition) + imgnm + tweetText.slice(cursorPosition, tweetText.length + 1)
        let str = tweetText
        let vdoExist = replaceVdoInThread(tweetText, cursorPosition, "gif", imgnm)
        console.log({ vdoExist, tweetText });
        if (vdoExist?.length > 0) {
            str = vdoExist;
            // str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

        } else {
            str = str.slice(0, cursorPosition) + imgnm + str.slice(cursorPosition, str.length + 1)

        }


        let removedImg = removeMediaFromStr(str, cursorPosition, "img")
        if (removedImg.length > 0)
            str = removedImg

        let removedVdo = removeMediaFromStr(str, cursorPosition, "vid")


        if (removedVdo.length > 0)
            str = removedVdo


        setTweetText(str)

        // setSelectedGifPicker(gif.images.original.url);
        console.log({ cursorPosition, tweetText, imgnm, removedImg, removedVdo, str }, "handleGifPicker")



        // if (!selectedGifpickerArr.some((selectedGif) => selectedGif.gif === gif.images.original.url)) {
        // if (!selectedGifpickerArr.some((selectedGif) => selectedGif.gif === gif.images.original.url && selectedGif.index === index)
        // ) {

        const response = await fetch(gif.images.original.url);
        const blob = await response.blob();
        const file = new File([blob], 'selectedGif.gif');



        setSelectedGifPickerArr([
            ...selectedGifpickerArr,
            {
                gif: gif.images.original.url,
                type: 'gif',
                name: imgnm,
                filest: file
            },
        ]);
        // }



        setShowGif2(prevInput => !prevInput);
        try {
            const response = await fetch(gif.images.original.url);
            const blob = await response.blob();
            const file = new File([blob], 'selectedGif.gif');


            //setNewGif(file)


        } catch (error) {
            console.log(error);
        }

        setSelectedGifIndex(gif.images.original.url)


        // let a = tweetText.substring(0, selectionStartVal + 1) + imgnm + tweetText.substring(selectionStartVal + 1)
        // setTweetText(a)
        // setTweetText(a)
        const updatedTextThread = removedVdo.split('\n\n\n').map((text, index1) => {
            setLoadingGifPic(true)
            const item = {
                value: text,
                subValue: removedVdo.includes('\n\n\n'),
                gif: '',
                image: null
            };


            if (index1 === selectedGifIndex) {
                item.gif = selectedGifIndex; // Assign the selected image to the item


            }
            if (item.subValue == true) {
                setShowthread(true)
            }
            setLoadingGifPic(false)
            console.log(item);
            return item;



        });

        setTextThread(updatedTextThread)
        console.log({ cursorPosition, tweetText, imgnm, removedVdo, updatedTextThread }, "handleGifPicker")


    };




    console.log("context-data-gif", selectedGifpickerArr)






    const handleRemoveGif = (index) => {

        if (GifUrl) {


            const updatedGifUrls = [...GifUrl];


            updatedGifUrls.splice(index, 1);
            console.log("::::::::::", updatedGifUrls)
            setGifUrl([...updatedGifUrls]);
            // {
            //     setContext({
            //         ...context,
            //         t: { ...context.t, image: '', gif: '' },
            //         type: "draft"

            //     })
            // }
        }


        setSelectedGifPicker(null);
        setSelectedGifPicker2(null)
        setSelectedGifPickerArr([])
        setNewGif(null)
        if (gifInputRef.current) {
            gifInputRef.current.value = '';
        }
    }



    console.log("jsxbjh", GifUrl)


    //handle dropdown

    const handleItemClick = (value) => {
        // Perform the respective functionality based on the selected value
        if (value === 'Option 1') {
            handleShow()
        } else if (value === 'Option 2') {
            // Perform functionality for Option 2
            PostNext()
        }

        setSelectedValue(value); // Update the selected value
        setIsOpen(false); // Close the dropdown
    };

    const toggleDropdown = (e) => {
        e.preventDefault()

        setIsOpen(!isOpen);
    };
    const toggleDropdownThread = (e) => {
        e.preventDefault()
        setIsOpen(false)

        setIsOpenThread(!isOpenThread);
    };


    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)

    const handleAPITweet = (e) => {
        e.preventDefault()

        if (tweetText == '') {

            toast.error('Please write a post first', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;

        }
        else {
            try {
                setLoading(true);

                let fdata = new FormData();


                fdata.append('id', userID)
                fdata.append('text', tweetText);


                fetch(`${BASE_URL}api/ai/tweet`, {
                    method: 'POST',
                    body: fdata,
                    headers: {
                        Authorization: `Bearer ${tokenLogin}`,
                    }
                }).then(response => {

                    console.log("response API", response)
                    return response.json()
                }).then(data => {
                    console.log("tweet API text", data);
                    if (data.content) {
                        setTweetText(data.content)
                    }

                    else {

                        // alert(JSON.stringify(data.message))
                    }
                    setLoading(false);
                })



            } catch (error) {
                console.log(error)
            }
        }
    }


    // Hnadle Submit
    const [loader, setLoader] = useState(0)
    const [loadingpro, setLoadingpro] = useState(false)





    const handleTweetSubmit = (e) => {
        e.preventDefault();
        if (localStorage.getItem("userloggedin") === 'true') {
            let pureText = tweetText
            {
                findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                    let imgnam = tweetText.substring(imgind, imgind + 13);
                    pureText = pureText.replace(imgnam, "")

                })
            }
            let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
            let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

            if (withoutGif.length > 280) {
                toast.error('Text limit exceeded', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken, secretKey, providerToken } = targetUser;
                        setLoading2(true);
                        let fdata = new FormData();


                        const linkRegex = /(https?:\/\/[^\s]+)/g;
                        const formattedText = tweetText.replace(linkRegex, (_, link) => `"${link}"`);
                        // if (isChecked) {

                        //     fdata.append('text', formattedText);

                        // }
                        // else {
                        //     fdata.append('text', tweetText);
                        // }


                        //fdata.append('text', tweetText);


                        let pureText = tweetText
                        {
                            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                pureText = pureText.replace(imgnam, "")

                            })
                        }
                        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                        fdata.append('text', withoutGif);

                        fdata.append('full_text', tweetText);

                        // arrImg?.map(a => {
                        //     fdata.append('image[]', a);
                        // })

                        findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            let imgEle = selectedImages.find(el => el.name === imgnam)
                            console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages, cont?.t?.image_code)

                            if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                // fdata.append('image[]', imgEle.imgFile)
                                fdata.append('image[image_file][]', imgEle.imgFile)
                                fdata.append('image[image_code][]', imgEle.name)


                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)


                            }

                        })


                        let urlAr = cont.t && cont.t.image && cont.t.image?.map((image, index) => {
                            return cont.t && tweetText?.includes(cont.t.image_code[index])
                                ? image
                                : null
                        }).filter(f => f);
                        fdata.append('image_url', urlAr ? urlAr : '')
                        console.log(urlAr, 'image url', imageUrl, typeof imageUrl);




                        {
                            cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                    fdata.append('gif_url', gif)
                                    : null
                            })
                        }

                        if (cont.t && cont.t.video) {
                            if (cont.t && tweetText?.includes(cont.t.image_code)) {
                                fdata.append('video_url', VideoUrl ? VideoUrl : '');
                            }
                        }



                        findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                //fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('image[image_code][]', selectedGifOb?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                fdata.append('video', videoPreviews.file)
                                fdata.append('image[image_code][]', videoPreviews?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        fdata.append('provider_token', providerToken)
                        fdata.append('oauth_token_secret', secretKey)
                        fdata.append('id', '')
                        // fdata.append('gif[]', NewGif ? NewGif : '');
                        //fdata.append('image_url', imageUrl ? imageUrl : '')
                        //fdata.append('gif_url', GifUrl ? GifUrl : '')
                        // fdata.append('video', videoPreviews?.file ? videoPreviews?.file : '')
                        // fdata.append('video_url', VideoUrl ? VideoUrl : '')
                        // fdata.append('gif', selectedGifpicker);

                        fetch(`${BASE_URL}api/tweetnow`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {


                            return response.json()
                        }).then(data => {
                            console.log("tweet000", data);


                            if (data.code == 200) {

                                toast.success(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });

                                setContextData({ publishedPost: true })

                                // handleRemoveImage()
                                handleRemoveAll()
                                setTweetText('');
                                //setArrImg('')
                                setSelectedImages3([])


                                handleRemoveGif()
                                setNewGif('')
                                setSelectedGifOb('')
                                setCharacterCount(0)
                                setVideoPreviews({ preview: '', type: "video", file: '' });
                                setVideoUrl('')

                                handleResetHeight(document.querySelector('textarea'));

                                const imageInputRef = document.getElementById('image-upload');
                                if (imageInputRef) {
                                    imageInputRef.value = '';
                                }

                            }


                            else {

                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });

                                // alert(JSON.stringify(data.message))
                            }

                            setLoading2(false);
                        })
                    }
                    else {

                    }



                } catch (error) {
                    console.log(error)

                }
            }
        }
        else {

            let pureText = tweetText
            {
                findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                    let imgnam = tweetText.substring(imgind, imgind + 13);
                    pureText = pureText.replace(imgnam, "")

                })
            }
            let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
            let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

            if (withoutGif.length > 280) {
                toast.error('Text limit exceeded', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            else {


                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken, secretKey, providerToken } = targetUser;
                        setLoading2(true);
                        let fdata = new FormData();


                        const linkRegex = /(https?:\/\/[^\s]+)/g;
                        const formattedText = tweetText.replace(linkRegex, (_, link) => `"${link}"`);
                        // if (isChecked) {

                        //     fdata.append('text', formattedText);

                        // }
                        // else {
                        //     fdata.append('text', tweetText);
                        // }


                        //fdata.append('text', tweetText);


                        let pureText = tweetText
                        {
                            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                pureText = pureText.replace(imgnam, "")

                            })
                        }
                        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                        fdata.append('text', withoutGif);

                        fdata.append('full_text', tweetText);

                        // arrImg?.map(a => {
                        //     fdata.append('image[]', a);
                        // })

                        findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            let imgEle = selectedImages.find(el => el.name === imgnam)
                            console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages, cont?.t?.image_code)

                            if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                // fdata.append('image[]', imgEle.imgFile)
                                fdata.append('image[image_file][]', imgEle.imgFile)
                                fdata.append('image[image_code][]', imgEle.name)


                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)


                            }

                        })


                        let urlAr = cont.t && cont.t.image && cont.t.image?.map((image, index) => {
                            return cont.t && tweetText?.includes(cont.t.image_code[index])
                                ? image
                                : null
                        }).filter(f => f);
                        fdata.append('image_url', urlAr ? urlAr : '')
                        console.log(urlAr, 'image url', imageUrl, typeof imageUrl);




                        {
                            cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                    fdata.append('gif_url', gif)
                                    : null
                            })
                        }

                        if (cont.t && cont.t.video) {
                            if (cont.t && tweetText?.includes(cont.t.image_code)) {
                                fdata.append('video_url', VideoUrl ? VideoUrl : '');
                            }
                        }



                        findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                //fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('image[image_code][]', selectedGifOb?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                fdata.append('video', videoPreviews.file)
                                fdata.append('image[image_code][]', videoPreviews?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        fdata.append('provider_token', providerToken)
                        fdata.append('oauth_token_secret', secretKey)
                        fdata.append('id', '')
                        // fdata.append('gif[]', NewGif ? NewGif : '');
                        //fdata.append('image_url', imageUrl ? imageUrl : '')
                        //fdata.append('gif_url', GifUrl ? GifUrl : '')
                        // fdata.append('video', videoPreviews?.file ? videoPreviews?.file : '')
                        // fdata.append('video_url', VideoUrl ? VideoUrl : '')
                        // fdata.append('gif', selectedGifpicker);

                        fetch(`${BASE_URL}api/tweetnow`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {


                            return response.json()
                        }).then(data => {
                            console.log("tweet000", data);


                            if (data.code == 200) {

                                toast.success(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });

                                setContextData({ publishedPost: true })

                                // handleRemoveImage()
                                handleRemoveAll()
                                setTweetText('');
                                //setArrImg('')
                                setSelectedImages3([])


                                handleRemoveGif()
                                setNewGif('')
                                setSelectedGifOb('')
                                setCharacterCount(0)
                                setVideoPreviews({ preview: '', type: "video", file: '' });
                                setVideoUrl('')

                                handleResetHeight(document.querySelector('textarea'));

                                const imageInputRef = document.getElementById('image-upload');
                                if (imageInputRef) {
                                    imageInputRef.value = '';
                                }

                            }


                            else {

                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });

                                // alert(JSON.stringify(data.message))
                            }

                            setLoading2(false);
                        })
                    }
                    else {

                    }



                } catch (error) {
                    console.log(error)

                }
            }
        }

    };


    // const handleTweetSubmit = (e) => {
    //     e.preventDefault();

    //     // setProgress(0);
    //     let minVal = 0
    //     const maxVal = 99
    //     let randomValue = 0;
    //     let successFlag = false; // Flag to track API success

    //     // const randomValue =
    //     //     minVal + Math.floor(Math.random() * (maxVal - minVal + 1))
    //     // setLoader(randomValue)
    //     // minVal = randomValue + 1

    //     // setTimeout(randomValue, 100)
    //     // setTimeout(randomValue, 300)
    //     // setTimeout(randomValue, 600)
    //     // setTimeout(randomValue, 1000)

    //     const updateRandomValue = () => {
    //         if (randomValue < maxVal && !successFlag) {
    //             randomValue += 1;
    //             setLoader(randomValue);
    //             setTimeout(updateRandomValue, 100); // Schedule the next update
    //         }
    //     };

    //     updateRandomValue(); // Start the update process

    //     console.log("random anan", randomValue, loader)

    //     try {
    //         setLoadingpro(true)
    //         setLoading2(true);
    //         let fdata = new FormData();

    //         fdata.append('text', tweetText);

    //         arrImg?.map(a => {
    //             fdata.append('image[]', a);
    //         })

    //         fdata.append('provider_token', providerToken)
    //         fdata.append('oauth_token_secret', OauthToken)
    //         fdata.append('id', '')
    //         fdata.append('gif[]', NewGif ? NewGif : '');
    //         fdata.append('image_url', imageUrl ? imageUrl : '')
    //         fdata.append('gif_url', GifUrl ? GifUrl : '')
    //         fdata.append('video', videoPreviews?.file ? videoPreviews?.file : '')
    //         fdata.append('video_url', VideoUrl ? VideoUrl : '')
    //         // fdata.append('gif', selectedGifpicker);

    //         fetch(`${BASE_URL}api/tweetnow`, {
    //             method: 'POST',
    //             body: fdata,
    //             headers: {
    //                 Authorization: `Bearer ${tokenLogin}`,
    //             }
    //         }).then(response => {


    //             return response.json()
    //         }).then(data => {
    //             console.log("tweet000", data);



    //             if (data.code == 200) {


    //                 setLoader(100)
    //                 successFlag = true; // Set the success flag

    //                 toast.success(data.message, {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });

    //                 setContextData({ publishedPost: true })
    //                 // handleRemoveImage()
    //                 handleRemoveAll()
    //                 setTweetText('');
    //                 // setArrImg('')

    //                 handleRemoveGif()
    //                 setNewGif('')
    //                 setCharacterCount(0)
    //                 setVideoPreviews({ preview: '', type: "video", file: '' });
    //                 setVideoUrl('')

    //             }


    //             else {

    //                 toast.error(data.message, {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });

    //                 // alert(JSON.stringify(data.message))
    //             }

    //             setLoading2(false);
    //             setLoadingpro(false)
    //         })



    //     } catch (error) {
    //         console.log(error)

    //     }




    // };








    const [loadingSet, setLoadingSet] = useState(false)

    const handleScheduleTweet = (e) => {
        e.preventDefault();

        let pureText = tweetText
        {
            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                let imgnam = tweetText.substring(imgind, imgind + 13);
                pureText = pureText.replace(imgnam, "")

            })
        }
        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

        if (withoutGif.length > 280) {
            setShow(false)
            toast.error('Text limit exceeded', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {

            if (localStorage.getItem("userloggedin") === 'true') {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken, providerToken } = targetUser;
                        setLoadingSet(true)
                        let fdata = new FormData();


                        const linkRegex = /(https?:\/\/[^\s]+)/g;
                        const formattedText = tweetText.replace(linkRegex, (_, link) => `"${link}"`);



                        // if (isChecked) {

                        //     fdata.append('text', tweetText);

                        // }
                        // else {
                        //     fdata.append('text', formattedText);
                        // }
                        // // fdata.append('text', tweetText);
                        // arrImg.map(a => {
                        //     fdata.append('image[]', a);
                        // })

                        let pureText = tweetText
                        {
                            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                pureText = pureText.replace(imgnam, "")

                            })
                        }
                        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                        fdata.append('text', withoutGif);

                        fdata.append('full_text', tweetText);


                        findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            let imgEle = selectedImages.find(el => el.name === imgnam)
                            console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages, cont?.t?.image_code)

                            if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                // fdata.append('image[]', imgEle.imgFile)
                                fdata.append('image[image_file][]', imgEle.imgFile)
                                fdata.append('image[image_code][]', imgEle.name)


                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)


                            }

                        })

                        let urlAr = cont.t && cont.t.image && cont.t.image?.map((image, index) => {
                            return cont.t && tweetText?.includes(cont.t.image_code[index])
                                ? image
                                : null
                        }).filter(f => f);
                        fdata.append('image_url', urlAr ? urlAr : '')





                        {
                            cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                    fdata.append('gif_url', gif)
                                    : null
                            })
                        }

                        if (cont.t && cont.t.video) {
                            if (cont.t && tweetText?.includes(cont.t.image_code)) {
                                fdata.append('video_url', VideoUrl ? VideoUrl : '');
                            }
                        }



                        findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                //fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('image[image_code][]', selectedGifOb?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                fdata.append('video', videoPreviews.file)
                                fdata.append('image[image_code][]', videoPreviews?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })



                        fdata.append('provider_token', providerToken)
                        if (cont?.type == "schduleLater") {

                            fdata.append('id', cont.t?.id ? cont.t?.id : '')
                        }
                        else {
                            fdata.append('id', '')
                        }
                        fdata.append('time', selectedTim ? selectedTim : moment(cont?.t?.schedule_time, 'hh:mm:ss').format("hh:mm A"))
                        fdata.append('date', selectedDat ? selectedDat : moment(cont?.t?.schedule_date, "DD-MM-YYYY").format('DD/MM/YYYY'))
                        fdata.append('day', selectedDat ? moment(selectedDat, 'DD-MM-YYYY').format('dddd') : cont?.t?.schedule_day)
                        fdata.append('timezone', timeZoneV ? timeZoneV : '')
                        // fdata.append('gif[]', NewGif);
                        // fdata.append('video_url', VideoUrl ? VideoUrl : '')
                        // fdata.append('video', videoPreviews ? videoPreviews?.file : '')

                        fetch(`${BASE_URL}api/schedule/tweet`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response schedule tweet", response)
                            return response.json()
                        }).then(data => {
                            console.log("scheduele tweet data -----", data);
                            if (data.code == 200) {

                                // window.location.reload()
                                // alert(JSON.stringify(data.message))
                                toast.success(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setContextData({ QUEUESaved: true })
                                setTweetText('');
                                setArrImg([])
                                handleRemoveImage()
                                handleRemoveGif()
                                setCharacterCount(0)
                                setVideoPreviews({ preview: '', type: "video", file: '' });
                                handleResetHeight(document.querySelector('textarea'));
                                setSelectedDateTime('')

                                handleClose()



                            }


                            else {
                                handleClose()

                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });


                                // alert(JSON.stringify(data.message))
                            }
                            setLoadingSet(false)
                        })

                    }
                    else {

                    }



                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken, providerToken } = targetUser;
                        setLoadingSet(true)
                        let fdata = new FormData();


                        const linkRegex = /(https?:\/\/[^\s]+)/g;
                        const formattedText = tweetText.replace(linkRegex, (_, link) => `"${link}"`);



                        // if (isChecked) {

                        //     fdata.append('text', tweetText);

                        // }
                        // else {
                        //     fdata.append('text', formattedText);
                        // }
                        // // fdata.append('text', tweetText);
                        // arrImg.map(a => {
                        //     fdata.append('image[]', a);
                        // })

                        let pureText = tweetText
                        {
                            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                pureText = pureText.replace(imgnam, "")

                            })
                        }
                        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                        fdata.append('text', withoutGif);

                        fdata.append('full_text', tweetText);


                        findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            let imgEle = selectedImages.find(el => el.name === imgnam)
                            console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages, cont?.t?.image_code)

                            if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                // fdata.append('image[]', imgEle.imgFile)
                                fdata.append('image[image_file][]', imgEle.imgFile)
                                fdata.append('image[image_code][]', imgEle.name)


                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)


                            }

                        })

                        let urlAr = cont.t && cont.t.image && cont.t.image?.map((image, index) => {
                            return cont.t && tweetText?.includes(cont.t.image_code[index])
                                ? image
                                : null
                        }).filter(f => f);
                        fdata.append('image_url', urlAr ? urlAr : '')





                        {
                            cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                    fdata.append('gif_url', gif)
                                    : null
                            })
                        }

                        if (cont.t && cont.t.video) {
                            if (cont.t && tweetText?.includes(cont.t.image_code)) {
                                fdata.append('video_url', VideoUrl ? VideoUrl : '');
                            }
                        }



                        findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                //fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('image[image_code][]', selectedGifOb?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                fdata.append('video', videoPreviews.file)
                                fdata.append('image[image_code][]', videoPreviews?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })



                        fdata.append('provider_token', providerToken)
                        if (cont?.type == "schduleLater") {

                            fdata.append('id', cont.t?.id ? cont.t?.id : '')
                        }
                        else {
                            fdata.append('id', '')
                        }
                        fdata.append('time', selectedTim ? selectedTim : moment(cont?.t?.schedule_time, 'hh:mm:ss').format("hh:mm A"))
                        fdata.append('date', selectedDat ? selectedDat : moment(cont?.t?.schedule_date, "DD-MM-YYYY").format('DD/MM/YYYY'))
                        fdata.append('day', selectedDat ? moment(selectedDat, 'DD-MM-YYYY').format('dddd') : cont?.t?.schedule_day)
                        fdata.append('timezone', timeZoneV ? timeZoneV : '')
                        // fdata.append('gif[]', NewGif);
                        // fdata.append('video_url', VideoUrl ? VideoUrl : '')
                        // fdata.append('video', videoPreviews ? videoPreviews?.file : '')

                        fetch(`${BASE_URL}api/schedule/tweet`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        }).then(response => {

                            console.log("response schedule tweet", response)
                            return response.json()
                        }).then(data => {
                            console.log("scheduele tweet data -----", data);
                            if (data.code == 200) {

                                // window.location.reload()
                                // alert(JSON.stringify(data.message))
                                toast.success(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setContextData({ QUEUESaved: true })
                                setTweetText('');
                                setArrImg([])
                                handleRemoveImage()
                                handleRemoveGif()
                                setCharacterCount(0)
                                setVideoPreviews({ preview: '', type: "video", file: '' });
                                handleResetHeight(document.querySelector('textarea'));
                                setSelectedDateTime('')

                                handleClose()



                            }


                            else {
                                handleClose()

                                toast.error(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });


                                // alert(JSON.stringify(data.message))
                            }
                            setLoadingSet(false)
                        })

                    }
                    else {

                    }



                } catch (error) {
                    console.log(error)
                }
            }
        }



    }

    const [DraftData, setDraftData] = useState([])


    const displayDraft = async () => {
        try {

            const response = await fetch(`${BASE_URL}api/draft/list/${userID}`, {
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();

            setDraftData(data)

        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }
    };






    const handleDraft = (e) => {
        e.preventDefault();

        // if (isAPIProcessing) {
        //     return; // Exit the function if the API call is ongoing
        // }

        let pureText = tweetText
        {
            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                let imgnam = tweetText.substring(imgind, imgind + 13);
                pureText = pureText.replace(imgnam, "")

            })
        }
        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

        if (tweetText == '') {
            toast.error("Text is required for draft", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            return;
        }
        else if (tweetText == null || tweetText == undefined) {
            toast.error("Text is required for draft", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            return;
        }
        else if (withoutGif.length > 280) {
            toast.error("Text limit exceeded", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }


        else {
            if (localStorage.getItem("userloggedin") === 'true') {
                // try {
                //     const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                //     const targetUser = userDataArray.find(user => user.email === emailTo);

                //     if (targetUser) {
                //         const { userID, accessToken, secretKey, providerToken } = targetUser;

                //         setLoaderDraft(true)
                //         // setAPIProcessing(true); // Set the API call status to true

                //         let fdata = new FormData();


                //         fdata.append('text', tweetText);
                //         arrImg.map(a => {
                //             fdata.append('image[]', a);
                //         })
                //         // if (arrImg) {
                //         //     arrImg.forEach(imageData => {
                //         //         // Ensure imageData is a valid File or Blob object
                //         //         if (imageData instanceof File || imageData instanceof Blob) {
                //         //             fdata.append('image[]', imageData);
                //         //         }
                //         //     });
                //         // }
                //         fdata.append('provider_token', providerToken)
                //         fdata.append('gif[]', NewGif ? NewGif : '');
                //         fdata.append('video', videoPreviews ? videoPreviews?.file : '');
                //         if (cont?.type == 'queue') {
                //             fdata.append('id', '')
                //         }
                //         else if (cont?.type == 'draft') {
                //             fdata.append('id', cont?.t ? cont.t?.id : '')
                //         }
                //         else {
                //             fdata.append('id', '')
                //         }

                //         fdata.append('image_url', imageUrl ? imageUrl : '')
                //         fdata.append('gif_url', GifUrl ? GifUrl : '')
                //         fdata.append('video_url', VideoUrl ? VideoUrl : '')


                //         fetch(`${BASE_URL}api/draft`, {
                //             method: 'POST',
                //             body: fdata,
                //             headers: {
                //                 Authorization: `Bearer ${accessToken}`,
                //             }
                //         })
                //             .then(response => response.json())
                //             .then(data => {
                //                 console.log("queue dtaa", data)
                //                 if (data.code === 200) {
                //                     // alert(JSON.stringify(data.message));
                //                     setContextData({ tweetText, selectedGifpicker, selectedImages });
                //                     toast.success(data.message, {
                //                         position: "top-center",
                //                         autoClose: 5000,
                //                         hideProgressBar: false,
                //                         closeOnClick: true,
                //                         pauseOnHover: true,
                //                         draggable: true,
                //                         progress: undefined,
                //                         theme: "light",
                //                     });

                //                     setContextData({ DraftSaved: true })
                //                     setTweetText('')
                //                     handleRemoveGif()
                //                     handleRemoveImage()
                //                     {
                //                         setContext({
                //                             ...cont,
                //                             t: { ...cont.t, thread: null, image: null, gif: null, text: null, id: '' },


                //                         })
                //                     }
                //                     setVideoPreviews({ preview: '', type: "video", file: '' });
                //                     handleResetHeight(document.querySelector('textarea'));

                //                 } else if (data.code == 500) {
                //                     // alert(JSON.stringify(data.message));
                //                     toast.error(data.error_message, {
                //                         position: "top-center",
                //                         autoClose: 5000,
                //                         hideProgressBar: false,
                //                         closeOnClick: true,
                //                         pauseOnHover: true,
                //                         draggable: true,
                //                         progress: undefined,
                //                         theme: "light",
                //                     });

                //                 }
                //                 else {
                //                     toast.error(data.message, {
                //                         position: "top-center",
                //                         autoClose: 5000,
                //                         hideProgressBar: false,
                //                         closeOnClick: true,
                //                         pauseOnHover: true,
                //                         draggable: true,
                //                         progress: undefined,
                //                         theme: "light",
                //                     });
                //                 }
                //                 setLoaderDraft(false)
                //             })
                //             .catch(error => {
                //                 console.log(error);
                //             })
                //         // .finally(() => {
                //         //     setAPIProcessing(false); // Reset the API call status
                //         // });

                //     }
                //     else {

                //     }

                // } catch (error) {
                //     console.log(error);
                //     // setAPIProcessing(false); // Reset the API call status in case of an error
                // }
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken, secretKey, providerToken } = targetUser;

                        setLoaderDraft(true)
                        // setAPIProcessing(true); // Set the API call status to true

                        let fdata = new FormData();


                        // fdata.append('text', tweetText);

                        let pureText = tweetText
                        {
                            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                pureText = pureText.replace(imgnam, "")

                            })
                        }
                        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                        fdata.append('text', withoutGif);

                        fdata.append('full_text', tweetText);

                        // arrImg.map(a => {
                        //     fdata.append('image[]', a);
                        // })

                        findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            let imgEle = selectedImages.find(el => el.name === imgnam)
                            console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages, cont?.t?.image_code)

                            if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                // fdata.append('image[]', imgEle.imgFile)
                                fdata.append('image[image_file][]', imgEle.imgFile)
                                fdata.append('image[image_code][]', imgEle.name)


                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)


                            }

                        })

                        let urlAr = cont.t && cont.t.image && cont.t.image?.map((image, index) => {
                            return cont.t && tweetText?.includes(cont.t.image_code[index])
                                ? image
                                : null
                        }).filter(f => f);
                        fdata.append('image_url', urlAr ? urlAr : '')





                        {
                            cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                    fdata.append('gif_url', gif)
                                    : null
                            })
                        }

                        if (cont.t && cont.t.video) {
                            if (cont.t && tweetText?.includes(cont.t.image_code)) {
                                fdata.append('video_url', VideoUrl ? VideoUrl : '');
                            }
                        }



                        findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                //fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('image[image_code][]', selectedGifOb?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                fdata.append('video', videoPreviews.file)
                                fdata.append('image[image_code][]', videoPreviews?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })


                        fdata.append('provider_token', providerToken)
                        //fdata.append('gif[]', NewGif ? NewGif : '');
                        //fdata.append('video', videoPreviews ? videoPreviews?.file : '');
                        if (cont?.type == 'queue') {
                            fdata.append('id', '')
                        }
                        else if (cont?.type == 'draft') {
                            fdata.append('id', cont?.t ? cont.t?.id : '')
                        }
                        else {
                            fdata.append('id', '')
                        }

                        //fdata.append('image_url', imageUrl ? imageUrl : '')
                        // fdata.append('gif_url', GifUrl ? GifUrl : '')
                        // fdata.append('video_url', VideoUrl ? VideoUrl : '')


                        fetch(`${BASE_URL}api/draft`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log("queue dtaa", data)
                                if (data.code === 200) {
                                    // alert(JSON.stringify(data.message));
                                    setContextData({ tweetText, selectedGifpicker, selectedImages });
                                    toast.success(data.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                    setContextData({ DraftSaved: true })
                                    setTweetText('')
                                    handleRemoveGif()
                                    handleRemoveImage()
                                    {
                                        setContext({
                                            ...cont,
                                            t: { ...cont.t, thread: null, image: null, gif: null, text: null, id: '' },


                                        })
                                    }
                                    setVideoPreviews({ preview: '', type: "video", file: '' });
                                    handleResetHeight(document.querySelector('textarea'));

                                    const imageInputRef = document.getElementById('image-upload');
                                    if (imageInputRef) {
                                        imageInputRef.value = '';
                                    }

                                } else if (data.code == 500) {
                                    // alert(JSON.stringify(data.message));
                                    toast.error(data.error_message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                                else {
                                    toast.error(data.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }
                                setLoaderDraft(false)
                            })
                            .catch(error => {
                                console.log(error);
                            })
                        // .finally(() => {
                        //     setAPIProcessing(false); // Reset the API call status
                        // });

                    }
                    else {

                    }

                } catch (error) {
                    console.log(error);
                    // setAPIProcessing(false); // Reset the API call status in case of an error
                }
            }
            else {
                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken, secretKey, providerToken } = targetUser;

                        setLoaderDraft(true)
                        // setAPIProcessing(true); // Set the API call status to true

                        let fdata = new FormData();


                        // fdata.append('text', tweetText);

                        let pureText = tweetText
                        {
                            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                pureText = pureText.replace(imgnam, "")

                            })
                        }
                        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                        fdata.append('text', withoutGif);

                        fdata.append('full_text', tweetText);

                        // arrImg.map(a => {
                        //     fdata.append('image[]', a);
                        // })

                        findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            let imgEle = selectedImages.find(el => el.name === imgnam)
                            console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages, cont?.t?.image_code)

                            if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                // fdata.append('image[]', imgEle.imgFile)
                                fdata.append('image[image_file][]', imgEle.imgFile)
                                fdata.append('image[image_code][]', imgEle.name)


                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)


                            }

                        })

                        let urlAr = cont.t && cont.t.image && cont.t.image?.map((image, index) => {
                            return cont.t && tweetText?.includes(cont.t.image_code[index])
                                ? image
                                : null
                        }).filter(f => f);
                        fdata.append('image_url', urlAr ? urlAr : '')





                        {
                            cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                    fdata.append('gif_url', gif)
                                    : null
                            })
                        }

                        if (cont.t && cont.t.video) {
                            if (cont.t && tweetText?.includes(cont.t.image_code)) {
                                fdata.append('video_url', VideoUrl ? VideoUrl : '');
                            }
                        }



                        findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                //fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('image[image_code][]', selectedGifOb?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                fdata.append('video', videoPreviews.file)
                                fdata.append('image[image_code][]', videoPreviews?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })


                        fdata.append('provider_token', providerToken)
                        //fdata.append('gif[]', NewGif ? NewGif : '');
                        //fdata.append('video', videoPreviews ? videoPreviews?.file : '');
                        if (cont?.type == 'queue') {
                            fdata.append('id', '')
                        }
                        else if (cont?.type == 'draft') {
                            fdata.append('id', cont?.t ? cont.t?.id : '')
                        }
                        else {
                            fdata.append('id', '')
                        }

                        //fdata.append('image_url', imageUrl ? imageUrl : '')
                        // fdata.append('gif_url', GifUrl ? GifUrl : '')
                        // fdata.append('video_url', VideoUrl ? VideoUrl : '')


                        fetch(`${BASE_URL}api/draft`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log("queue dtaa", data)
                                if (data.code === 200) {
                                    // alert(JSON.stringify(data.message));
                                    setContextData({ tweetText, selectedGifpicker, selectedImages });
                                    toast.success(data.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                    setContextData({ DraftSaved: true })
                                    setTweetText('')
                                    handleRemoveGif()
                                    handleRemoveImage()
                                    {
                                        setContext({
                                            ...cont,
                                            t: { ...cont.t, thread: null, image: null, gif: null, text: null, id: '' },


                                        })
                                    }
                                    setVideoPreviews({ preview: '', type: "video", file: '' });
                                    handleResetHeight(document.querySelector('textarea'));

                                    const imageInputRef = document.getElementById('image-upload');
                                    if (imageInputRef) {
                                        imageInputRef.value = '';
                                    }

                                } else if (data.code == 500) {
                                    // alert(JSON.stringify(data.message));
                                    toast.error(data.error_message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                                else {
                                    toast.error(data.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }
                                setLoaderDraft(false)
                            })
                            .catch(error => {
                                console.log(error);
                            })
                        // .finally(() => {
                        //     setAPIProcessing(false); // Reset the API call status
                        // });

                    }
                    else {

                    }

                } catch (error) {
                    console.log(error);
                    // setAPIProcessing(false); // Reset the API call status in case of an error
                }
            }
        }




    };

    console.log("loaderDrat", loaderDraft)



    const handleKeyDown = (e) => {

        setselectionStartVal(e.target.selectionStart)
        setselectionStartVal2(e.target.selectionStart2)

    }

    useEffect(() => {

        // if (showthread || handleComposeClick) {
        setTimeout(() => {
            textareaRef.current?.focus(); // Set focus back to the textarea after opening the modal
        }, 0);
        //   }
    }, [showthread, handleComposeClick]);


    const handleKeyPress = (e) => {

        const updatedTextThread = e.target.value.split('\n\n\n').map((text, index1) => {

            const item = {
                value: text,
                subValue: e.target.value.includes('\n\n\n'),
                gif: '',
                image: selectedImages,

            };


            if (index1 === selectedImageIndex) {
                item.image = selectedImageIndex; // Assign the selected image to the item

            }

            if (item.subValue == true) {
                setShowthread(true)

            }



            return item;



        });


        setTextThread(updatedTextThread)

        console.log("item-item", textthread.value)
    };







    const handleClosethread = () => setShowthread(false);
    const handleShowthread = (event) => {
        event.preventDefault();
        setShowthread(true);
    }


    const handleTime = cont.t ? cont.t.time : ''
    const handleDate = cont.formattedDate ? cont.formattedDate : ''


    const [isAPIProcessing, setAPIProcessing] = useState(false);

    const displayDynamicQueue = async () => {
        try {
            const response = await fetch(`${BASE_URL}api/queue/list/${userID}`, {
                headers: {
                    Authorization: `Bearer ${tokenLogin}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log("published/queue", data);
            if (data.data)
                // setDynamicQueue(data.data)
                console.log("dcec")


        } catch (error) {
            console.error('Error fetching buyer data:', error);
        }
    };


    const handleOpenQueue = (e) => {
        e.preventDefault();
        let pureText = tweetText
        {
            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                let imgnam = tweetText.substring(imgind, imgind + 13);
                pureText = pureText.replace(imgnam, "")

            })
        }
        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

        if (!cont?.t?.time && !cont?.formattedDate) {
            toast.error("Please first create a queue slot", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }


        else if (withoutGif.length > 280) {
            toast.error('Text limit exceeded', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }


        else {

            if (localStorage.getItem("userloggedin") === 'true') {
                if (isAPIProcessing) {
                    return; // Exit the function if the API call is ongoing
                }
                let minVal = 0
                const maxVal = 99
                let randomValue = 0;
                let successFlag = false; // Flag to track API success


                const updateRandomValue = () => {
                    if (randomValue < maxVal && !successFlag) {
                        randomValue += 1;
                        setLoader(randomValue);
                        setTimeout(updateRandomValue, 100); // Schedule the next update
                    }
                };

                updateRandomValue(); // Start the update process

                // try {
                //     const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                //     const targetUser = userDataArray.find(user => user.email === emailTo);

                //     if (targetUser) {
                //         const { userID, accessToken, secretKey, providerToken } = targetUser;
                //         setAPIProcessing(true); // Set the API call status to true

                //         // setLoaderQueue(true)
                //         setLoadingpro(true)

                //         let fdata = new FormData();

                //         const linkRegex = /(https?:\/\/[^\s]+)/g;
                //         const formattedText = tweetText.replace(linkRegex, (_, link) => `"${link}"`);
                //         // if (isChecked) {

                //         //     fdata.append('text', formattedText);

                //         // }
                //         // else {
                //         //     fdata.append('text', tweetText);
                //         // }
                //         fdata.append('text', tweetText);

                //         arrImg.map(a => {
                //             fdata.append('image[]', a);
                //         })
                //         fdata.append('provider_token', providerToken)
                //         // fdata.append('video_media_id', mediaId ? mediaId?.data?.media_id : '')
                //         fdata.append('time', moment(cont.t.time, 'hh:mm:ss').format('hh:mm A'))
                //         // fdata.append('time', cont.t.time)
                //         // fdata.append('time', moment(timefor, 'h:mm A').format('HH:mm:ss'))
                //         fdata.append('date', moment(cont.formattedDate).format('DD-MM-YYYY'))
                //         fdata.append('day', moment(cont.formattedDate).format('dddd'))
                //         if (timeZoneV == null || timeZoneV == '' || timeZoneV == undefined) {
                //             fdata.append('timezone', '')
                //         }
                //         else {
                //             fdata.append('timezone', timeZoneV ? timeZoneV : '')
                //         }

                //         fdata.append('gif[]', NewGif ? NewGif : '');

                //         if (cont?.type == 'queue') {

                //             fdata.append('id', cont.t?.id ? cont.t?.id : '')
                //         }
                //         else {
                //             fdata.append('id', '')
                //         }

                //         fdata.append('image_url', imageUrl ? imageUrl : '')
                //         fdata.append('gif_url', GifUrl ? GifUrl : '')
                //         fdata.append('video_url', VideoUrl ? VideoUrl : '')
                //         fdata.append('video', videoPreviews ? videoPreviews?.file : '')
                //         fdata.append('is_thread', 0)

                //         fetch(`${BASE_URL}api/queue/tweet`, {
                //             method: 'POST',
                //             body: fdata,
                //             headers: {
                //                 Authorization: `Bearer ${accessToken}`,
                //             }
                //         })
                //             .then(response => response.json())
                //             .then(data => {
                //                 console.log("queue dtaa", data)
                //                 if (data.code == 200) {
                //                     // alert(JSON.stringify(data.message));
                //                     // setContextData({ tweetText, handleTime, handleDate, selectedGifpicker, selectedImages });
                //                     setLoader(100)
                //                     successFlag = true; // Set the success flag

                //                     toast.success(data.message, {
                //                         position: "top-center",
                //                         autoClose: 5000,
                //                         hideProgressBar: false,
                //                         closeOnClick: true,
                //                         pauseOnHover: true,
                //                         draggable: true,
                //                         progress: undefined,
                //                         theme: "light",
                //                     });

                //                     setContextData({ QUEUESaved: true })
                //                     setTweetText('');
                //                     setArrImg([])
                //                     handleRemoveImage()
                //                     handleRemoveGif()
                //                     setCharacterCount(0)
                //                     setVideoPreviews({ preview: '', type: "video", file: '' });
                //                     handleResetHeight(document.querySelector('textarea'));



                //                 } else if (data.code == 500) {

                //                     toast.error(data.error_message, {
                //                         position: "top-center",
                //                         autoClose: 5000,
                //                         hideProgressBar: false,
                //                         closeOnClick: true,
                //                         pauseOnHover: true,
                //                         draggable: true,
                //                         progress: undefined,
                //                         theme: "light",
                //                     });
                //                 }


                //                 else {

                //                     toast.error(data.message, {
                //                         position: "top-center",
                //                         autoClose: 5000,
                //                         hideProgressBar: false,
                //                         closeOnClick: true,
                //                         pauseOnHover: true,
                //                         draggable: true,
                //                         progress: undefined,
                //                         theme: "light",
                //                     });

                //                 }
                //                 setLoaderQueue(false)
                //                 setTweetText('')
                //                 setLoadingpro(false)

                //             })
                //             .catch(error => {
                //                 console.log(error);
                //             })
                //             .finally(() => {
                //                 setAPIProcessing(false); // Reset the API call status
                //             });
                //     }
                //     else {

                //     }


                // } catch (error) {
                //     console.log(error);
                //     setAPIProcessing(false); // Reset the API call status in case of an error
                // }

                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailTo);

                    if (targetUser) {
                        const { userID, accessToken, secretKey, providerToken } = targetUser;
                        setAPIProcessing(true); // Set the API call status to true

                        // setLoaderQueue(true)
                        setLoadingpro(true)

                        let fdata = new FormData();

                        const linkRegex = /(https?:\/\/[^\s]+)/g;
                        const formattedText = tweetText.replace(linkRegex, (_, link) => `"${link}"`);

                        // if (isChecked) {

                        //     fdata.append('text', formattedText);

                        // }
                        // else {
                        //     fdata.append('text', tweetText);
                        // }

                        // fdata.append('text', tweetText);

                        // arrImg.map(a => {
                        //     fdata.append('image[]', a);
                        // })


                        let pureText = tweetText
                        {
                            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                pureText = pureText.replace(imgnam, "")

                            })
                        }
                        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                        fdata.append('text', withoutGif);

                        fdata.append('full_text', tweetText);


                        findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            let imgEle = selectedImages.find(el => el.name === imgnam)
                            console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages, cont?.t?.image_code)

                            if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                // fdata.append('image[]', imgEle.imgFile)
                                fdata.append('image[image_file][]', imgEle.imgFile)
                                fdata.append('image[image_code][]', imgEle.name)


                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)


                            }

                        })


                        let urlAr = cont.t && cont.t.image && cont.t.image?.map((image, index) => {
                            return cont.t && tweetText?.includes(cont.t.image_code[index])
                                ? image
                                : null
                        }).filter(f => f);
                        fdata.append('image_url', urlAr ? urlAr : '')



                        {
                            cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                    fdata.append('gif_url', gif)
                                    : null
                            })
                        }

                        if (cont.t && cont.t.video) {
                            if (cont.t && tweetText?.includes(cont.t.image_code)) {
                                fdata.append('video_url', VideoUrl ? VideoUrl : '');
                            }
                        }



                        findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                //fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('image[image_code][]', selectedGifOb?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                fdata.append('video', videoPreviews.file)
                                fdata.append('image[image_code][]', videoPreviews?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })



                        fdata.append('provider_token', providerToken)
                        fdata.append('time', moment(cont.t.time, 'hh:mm:ss').format('hh:mm A'))
                        fdata.append('date', moment(cont.formattedDate).format('DD-MM-YYYY'))
                        fdata.append('day', moment(cont.formattedDate).format('dddd'))

                        if (timeZoneV == null || timeZoneV == '' || timeZoneV == undefined) {
                            fdata.append('timezone', '')
                        }
                        else {
                            fdata.append('timezone', timeZoneV ? timeZoneV : '')
                        }

                        // fdata.append('gif[]', NewGif ? NewGif : '');

                        if (cont?.type == 'queue') {

                            fdata.append('id', cont.t?.id ? cont.t?.id : '')
                        }
                        else {
                            fdata.append('id', '')
                        }

                        // fdata.append('image_url', imageUrl ? imageUrl : '')
                        // fdata.append('gif_url', GifUrl ? GifUrl : '')
                        // fdata.append('video_url', VideoUrl ? VideoUrl : '')
                        // fdata.append('video', videoPreviews ? videoPreviews?.file : '')
                        fdata.append('is_thread', 0)

                        fetch(`${BASE_URL}api/queue/tweet`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log("queue dtaa", data)
                                if (data.code == 200) {
                                    // alert(JSON.stringify(data.message));
                                    // setContextData({ tweetText, handleTime, handleDate, selectedGifpicker, selectedImages });
                                    setLoader(100)
                                    successFlag = true; // Set the success flag

                                    toast.success(data.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                    setContextData({ QUEUESaved: true })
                                    setTweetText('');
                                    setArrImg([])
                                    handleRemoveImage()
                                    handleRemoveGif()
                                    setCharacterCount(0)
                                    setVideoPreviews({ preview: '', type: "video", file: '' });
                                    handleResetHeight(document.querySelector('textarea'));



                                } else if (data.code == 500) {

                                    toast.error(data.error_message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }


                                else {

                                    toast.error(data.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                                setLoaderQueue(false)
                                setTweetText('')
                                setLoadingpro(false)

                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => {
                                setAPIProcessing(false); // Reset the API call status
                            });
                    }
                    else {

                    }


                } catch (error) {
                    console.log(error);
                    setAPIProcessing(false); // Reset the API call status in case of an error
                }
            }
            else {
                if (isAPIProcessing) {
                    return; // Exit the function if the API call is ongoing
                }
                let minVal = 0
                const maxVal = 99
                let randomValue = 0;
                let successFlag = false; // Flag to track API success


                const updateRandomValue = () => {
                    if (randomValue < maxVal && !successFlag) {
                        randomValue += 1;
                        setLoader(randomValue);
                        setTimeout(updateRandomValue, 100); // Schedule the next update
                    }
                };

                updateRandomValue(); // Start the update process

                try {
                    const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                    const targetUser = userDataArray.find(user => user.email === emailSwitch);

                    if (targetUser) {
                        const { userID, accessToken, secretKey, providerToken } = targetUser;
                        setAPIProcessing(true); // Set the API call status to true

                        // setLoaderQueue(true)
                        setLoadingpro(true)

                        let fdata = new FormData();

                        const linkRegex = /(https?:\/\/[^\s]+)/g;
                        const formattedText = tweetText.replace(linkRegex, (_, link) => `"${link}"`);

                        // if (isChecked) {

                        //     fdata.append('text', formattedText);

                        // }
                        // else {
                        //     fdata.append('text', tweetText);
                        // }

                        // fdata.append('text', tweetText);

                        // arrImg.map(a => {
                        //     fdata.append('image[]', a);
                        // })


                        let pureText = tweetText
                        {
                            findNextIndex(tweetText, "[ img: ").forEach((imgind, imgIndex) => {
                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                pureText = pureText.replace(imgnam, "")

                            })
                        }
                        let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                        let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                        fdata.append('text', withoutGif);

                        fdata.append('full_text', tweetText);


                        findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            let imgEle = selectedImages.find(el => el.name === imgnam)
                            console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages, cont?.t?.image_code)

                            if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                // fdata.append('image[]', imgEle.imgFile)
                                fdata.append('image[image_file][]', imgEle.imgFile)
                                fdata.append('image[image_code][]', imgEle.name)


                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)


                            }

                        })


                        let urlAr = cont.t && cont.t.image && cont.t.image?.map((image, index) => {
                            return cont.t && tweetText?.includes(cont.t.image_code[index])
                                ? image
                                : null
                        }).filter(f => f);
                        fdata.append('image_url', urlAr ? urlAr : '')



                        {
                            cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                    fdata.append('gif_url', gif)
                                    : null
                            })
                        }

                        if (cont.t && cont.t.video) {
                            if (cont.t && tweetText?.includes(cont.t.image_code)) {
                                fdata.append('video_url', VideoUrl ? VideoUrl : '');
                            }
                        }



                        findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                //fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('gif[]', selectedGifOb.filest)
                                fdata.append('image[image_code][]', selectedGifOb?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })

                        findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                            console.log("img file", imgind)
                            let imgnam = tweetText.substring(imgind, imgind + 13);
                            if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                fdata.append('video', videoPreviews.file)
                                fdata.append('image[image_code][]', videoPreviews?.name)
                            }
                            if (cont?.t?.image_code?.includes(imgnam)) {
                                fdata.append('image[image_code][]', imgnam)

                            }

                        })



                        fdata.append('provider_token', providerToken)
                        fdata.append('time', moment(cont.t.time, 'hh:mm:ss').format('hh:mm A'))
                        fdata.append('date', moment(cont.formattedDate).format('DD-MM-YYYY'))
                        fdata.append('day', moment(cont.formattedDate).format('dddd'))

                        if (timeZoneV == null || timeZoneV == '' || timeZoneV == undefined) {
                            fdata.append('timezone', '')
                        }
                        else {
                            fdata.append('timezone', timeZoneV ? timeZoneV : '')
                        }

                        // fdata.append('gif[]', NewGif ? NewGif : '');

                        if (cont?.type == 'queue') {

                            fdata.append('id', cont.t?.id ? cont.t?.id : '')
                        }
                        else {
                            fdata.append('id', '')
                        }

                        // fdata.append('image_url', imageUrl ? imageUrl : '')
                        // fdata.append('gif_url', GifUrl ? GifUrl : '')
                        // fdata.append('video_url', VideoUrl ? VideoUrl : '')
                        // fdata.append('video', videoPreviews ? videoPreviews?.file : '')
                        fdata.append('is_thread', 0)

                        fetch(`${BASE_URL}api/queue/tweet`, {
                            method: 'POST',
                            body: fdata,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            }
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log("queue dtaa", data)
                                if (data.code == 200) {
                                    // alert(JSON.stringify(data.message));
                                    // setContextData({ tweetText, handleTime, handleDate, selectedGifpicker, selectedImages });
                                    setLoader(100)
                                    successFlag = true; // Set the success flag

                                    toast.success(data.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                    setContextData({ QUEUESaved: true })
                                    setTweetText('');
                                    setArrImg([])
                                    handleRemoveImage()
                                    handleRemoveGif()
                                    setCharacterCount(0)
                                    setVideoPreviews({ preview: '', type: "video", file: '' });
                                    handleResetHeight(document.querySelector('textarea'));
                                    setSelectedGifOb('')



                                } else if (data.code == 500) {

                                    toast.error(data.error_message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }


                                else {

                                    toast.error(data.message, {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                                setLoaderQueue(false)
                                setTweetText('')
                                setLoadingpro(false)

                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => {
                                setAPIProcessing(false); // Reset the API call status
                            });
                    }
                    else {

                    }


                } catch (error) {
                    console.log(error);
                    setAPIProcessing(false); // Reset the API call status in case of an error
                }
            }
        }




    };







    const [displayProfileDetails, setDisplayProfileDetails] = useState()




    console.log("timeZoxe", timeZoneV)

    const currentDateDraft = new Date()







    const formattedTime2 = cont.t ? moment(cont.t.time, 'hh:mm:ss').format('h:mm A | ') : '';
    const formattedDate2 = cont.formattedDate ? moment(cont.formattedDate, 'dddd | MMMM DD YYYY').format('MMM DD YYYY') : '';

    const displayText = formattedTime2 + formattedDate2 || '';






    const renderTooltip = (props) => (

        <Tooltip id="button-tooltip" {...props}>
            Use AI to improve or continue your post
        </Tooltip>
    );



    const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            // Click occurred outside the popover, so close it
            setIsOpen(null);
        }
    };

    useEffect(() => {
        // Attach the click event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const handleClickOutsideThread = (event) => {
        if (popoverRefThread.current && !popoverRefThread.current.contains(event.target)) {
            // Click occurred outside the popover, so close it
            setIsOpenThread(null);
        }
    };

    useEffect(() => {
        // Attach the click event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutsideThread);
        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutsideThread);
        };
    }, []);








    // const [mediaArray, setMediaArray] = useState([[]]);
    // const [currentArrayIndex, setCurrentArrayIndex] = useState(0);
    // const [inputValues, setInputValues] = useState({ text: '', img: '', video: '' });

    // const handleNextArray = () => {
    //     const nextIndex = (currentArrayIndex + 1) % mediaArray.length;
    //     setCurrentArrayIndex(nextIndex);
    //     console.log('Switched to subarray index: ' + nextIndex);
    // };

    // const handleAddMedia = () => {
    //     const media = { ...inputValues }; // Copy the input values
    //     const updatedArray = [...mediaArray[currentArrayIndex], media];
    //     setMediaArray(
    //         mediaArray.map((arr, index) =>
    //             index === currentArrayIndex ? updatedArray : arr
    //         )
    //     );
    //     console.log('Added media to current subarray: ' + JSON.stringify(updatedArray));
    //     // Clear input values after adding media
    //     // setInputValues({ text: '', img: '', video: '' });
    // };

    // const handleAddSubArray = () => {
    //     setMediaArray([...mediaArray, []]);
    //     console.log('Added a new subarray: ' + JSON.stringify([...mediaArray, []]));
    // };

    // console.log('Current state of mediaArray: ' + JSON.stringify(mediaArray));

    // const handleTextChange2 = (e) => {
    //     setInputValues({ ...inputValues, text: e.target.value });
    // };


    const [prevThreadCount, setPrevThreadCount] = useState(0);

    const [threadnew, setThreadnew] = useState([]);

    const [currentText, setCurrentText] = useState('');

    const handleAddItem = () => {
        if (currentText.trim() !== '') {
            const newItem = { text: currentText, image: null };
            setThreadnew([...threadnew, newItem]);
            // setCurrentText('');
        }
    };

    console.log('Current state of thread:', threadnew);

    const handleButton = (e) => {
        // e.preventDefault()
        //console.log("aasaas")
        console.log("hehelloo", document.getElementById('t1').selectionStart)
    }








    const handleSubmitThread2 = async () => {
        let threadIds = { tweet_id: "", thread_tweet_id: "" };
        let count = 0;
        let errorMessages = [];
        let successMessages = [];
        try {
            setLoadingTweetThread(true);
            const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

            const targetUser = userDataArray.find(user => user.email === emailSwitch);
            const promises = [];
            if (targetUser) {
                const { userID, accessToken, providerToken, secretKey } = targetUser;


                promises.push(
                    ...textthread.map(async (element) => {
                        // for await (const element of textthread) {
                        try {
                            let fdata = new FormData();

                            console.log("kakak", fdata, element, "jjjjjj", targetUser)

                            let textValue = element.value;

                            // Your existing logic for creating FormData and handling images, gifs, videos, etc.

                            fdata.append('full_text', element.value);
                            !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('gif[]', imgEle.filest)
                                    //fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when gif is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                    fdata.append('gif[]', selectedGifOb.filest)
                                    //fdata.append('image[image_code][]', selectedGifOb.name)
                                }

                            })



                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    // fdata.append('image[image_code][]', imgEle.name)


                                }

                            })


                            // when images is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                //     fdata.append('image[]', imgEle.imgFile)
                                // }
                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    // fdata.append('image[image_code][]', imgEle.name)

                                }

                            })


                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('video', imgEle.imgFile)
                                    // fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when video is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                    fdata.append('video', videoPreviews.file)
                                    //fdata.append('image[image_code][]', videoPreviews.name)
                                }

                            })

                            if (cont?.t?.thread) {
                                if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('image_url', '');

                                }
                                else {
                                    fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');



                                }


                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('video_url', '');
                                }
                                else {
                                    fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');


                                }
                            }


                            const threadgif = cont?.t?.thread;
                            if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                fdata.append('gif_url', "");

                            } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {

                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });




                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }

                            count++

                            let pureText = textValue
                            {
                                findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                    let imgnam = textValue.substring(imgind, imgind + 13);
                                    pureText = pureText.replace(imgnam, "")

                                })
                            }

                            let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                            console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo }, element.value)
                            fdata.append('text', withoutGif);



                            fdata.append("provider_token", providerToken);
                            fdata.append("oauth_token_secret", secretKey);

                            fdata.append("id", "")
                            fdata.append(
                                "tweet_id",
                                threadIds.tweet_id ? threadIds.tweet_id : ""
                            );
                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );


                            fdata.append('count', textthread.length);




                            const result = await fetch(`https://app.xblue.ai/Xblue/api/create/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => response.json());

                            if (result.code === 200) {
                                console.log("result", result)
                                threadIds.tweet_id = result?.data?.tweet_id;
                                threadIds.thread_tweet_id = result?.data?.thread_tweet_id;

                                // Handle success if needed
                            } else {
                                // Handle specific error messages
                                console.error(`Unexpected error: ${result.message}`);
                            }

                            return result;
                        } catch (error) {
                            console.log(error);
                            // Handle errors if needed
                        }
                        //}
                    })
                );
            }

            const results = await Promise.all(promises);

            console.log("sdcdscefsw", results)

            // Handle results if needed

            setLoadingTweetThread(false);
        } catch (error) {
            console.log(error);
        }
    };











    const handleSubmitThread = async (e) => {
        e.preventDefault()
        if (localStorage.getItem("userloggedin") === 'true') {

            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            let successfulThreads = [];
            let failedThreads = [];


            // try {
            //     setLoadingTweetThread(true)
            //     const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

            //     const targetUser = userDataArray.find(user => user.email === emailTo);

            //     if (targetUser) {
            //         const { userID, accessToken, providerToken, secretKey } = targetUser;
            //         console.log("taregte user", targetUser)

            //         for await (const element of textthread) {


            //             try {


            //                 let fdata = new FormData();
            //                 let textValue = element.value

            //                 findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
            //                     console.log("img file", imgind)
            //                     let imgnam = element.value.substring(imgind, imgind + 13);
            //                     let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
            //                     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

            //                     if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
            //                         fdata.append('gif[]', imgEle.imgFile)
            //                     }
            //                 })

            //                 // fdata.append('video', '')
            //                 findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
            //                     let imgnam = element.value.substring(imgind, imgind + 13);
            //                     let imgEle = selectedImages2.find(el => el.name === imgnam)
            //                     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2)

            //                     if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
            //                         fdata.append('image[]', imgEle.imgFile)
            //                     }
            //                 })

            //                 findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
            //                     console.log("img file", imgind)
            //                     let imgnam = element.value.substring(imgind, imgind + 13);
            //                     let imgEle = videoPreviews2.find(el => el.name === imgnam)
            //                     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

            //                     if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
            //                         fdata.append('video', imgEle.imgFile)
            //                     }
            //                 })

            //                 if (cont?.t?.thread) {

            //                     // fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
            //                     // fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');

            //                     if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
            //                         fdata.append('image_url', '');

            //                     }
            //                     else {
            //                         fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
            //                     }

            //                     if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
            //                         fdata.append('video_url', '');
            //                     }
            //                     else {
            //                         fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
            //                     }
            //                 }


            //                 const threadgif = cont?.t?.thread;
            //                 // if (Array.isArray(threadgif) && threadgif[count]?.gif) {
            //                 //     threadgif[count].gif.forEach(item => {
            //                 //         console.log(item, 'itemm-gif');
            //                 //         fdata.append('gif_url', item);
            //                 //     });
            //                 // } else {
            //                 //     // Handle the case where the array or gif property doesn't exist
            //                 //     console.error('Cannot read properties of undefined or gif is not an array');
            //                 // }

            //                 if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
            //                     fdata.append('gif_url', "");

            //                 } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
            //                     threadgif[count].gif.forEach(item => {
            //                         console.log(item, 'itemm-gif');
            //                         fdata.append('gif_url', item);
            //                     });
            //                 } else {
            //                     // Handle the case where the array or gif property doesn't exist
            //                     console.error('Cannot read properties of undefined or gif is not an array');
            //                 }

            //                 count++;

            //                 // fdata.append("text", textValue);

            //                 const linkRegex = /(https?:\/\/[^\s]+)/g;
            //                 const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
            //                 // if (isChecked) {
            //                 //     let pureText = formattedText

            //                 //     {
            //                 //         findNextIndex(formattedText, "[ img: ").forEach((imgind, imgIndex) => {
            //                 //             let imgnam = formattedText.substring(imgind, imgind + 13);
            //                 //             pureText = pureText.replace(imgnam, "")

            //                 //         })
            //                 //     }
            //                 //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
            //                 //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


            //                 //     fdata.append('text', withoutGif);



            //                 // }
            //                 // else {
            //                 //     let pureText = textValue
            //                 //     {
            //                 //         findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
            //                 //             let imgnam = textValue.substring(imgind, imgind + 13);
            //                 //             pureText = pureText.replace(imgnam, "")

            //                 //         })
            //                 //     }
            //                 //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
            //                 //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


            //                 //     fdata.append('text', withoutGif);

            //                 // }

            //                 let pureText = textValue
            //                 {
            //                     findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
            //                         let imgnam = textValue.substring(imgind, imgind + 13);
            //                         pureText = pureText.replace(imgnam, "")

            //                     })
            //                 }
            //                 let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
            //                 let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


            //                 fdata.append('text', withoutGif);



            //                 fdata.append("provider_token", providerToken);
            //                 fdata.append("oauth_token_secret", secretKey);

            //                 fdata.append("id", "")
            //                 fdata.append(
            //                     "tweet_id",
            //                     threadIds.tweet_id ? threadIds.tweet_id : ""
            //                 );
            //                 fdata.append(
            //                     "thread_tweet_id",
            //                     threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
            //                 );


            //                 fdata.append('count', textthread.length);

            //                 await fetch(`https://app.xblue.ai/Xblue/api/create/thread`, {
            //                     method: "POST",
            //                     body: fdata,
            //                     headers: {
            //                         Authorization: `Bearer ${accessToken}`,
            //                     },
            //                 }).then((response) => {
            //                     return response.json();
            //                 }).then((data) => {


            //                     if (data.code == 200) {
            //                         threadIds.tweet_id = data.data.tweet_id;
            //                         threadIds.thread_tweet_id = data.data.thread_tweet_id;

            //                         successMessages.push(data.message);
            //                         setContextData({ publishedPost: true })
            //                         setSelectedImages2([])
            //                         setVideoPreviews2([])
            //                     } else {
            //                         // Collect specific error messages from the backend
            //                         if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
            //                             errorMessages.push(data.message);
            //                         } else {
            //                             // Handle other error cases
            //                             console.error(`Unexpected error: ${data.message}`);
            //                         }
            //                     }



            //                 });


            //             } catch (error) {
            //                 console.log(error);


            //             }
            //         }
            //     } else {
            //         console.log("not found email")
            //     }



            //     setLoadingTweetThread(false)
            //     if (successMessages.length > 0) {
            //         toast.success(`Your ${successMessages.length} threads will be published in a few minutes!`, {
            //             position: "top-center",
            //             autoClose: 5000,
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //             theme: "light",
            //         });
            //     }

            //     if (errorMessages.length > 0) {
            //         errorMessages.forEach((errorMessage) => {
            //             toast.error(errorMessage, {
            //                 position: "top-center",
            //                 autoClose: 5000,
            //                 hideProgressBar: false,
            //                 closeOnClick: true,
            //                 pauseOnHover: true,
            //                 draggable: true,
            //                 progress: undefined,
            //                 theme: "light",
            //             });
            //         });
            //     }

            //     // if (errorMessages.length === 0) {
            //     //     toast.success("All threads posted successfully!", {
            //     //         position: "top-center",
            //     //         autoClose: 5000,
            //     //         hideProgressBar: false,
            //     //         closeOnClick: true,
            //     //         pauseOnHover: true,
            //     //         draggable: true,
            //     //         progress: undefined,
            //     //         theme: "light",
            //     //     });
            //     // } else {
            //     //     // Display specific error messages if there are any
            //     //     errorMessages.forEach((errorMessage) => {
            //     //         toast.error(errorMessage, {
            //     //             position: "top-center",
            //     //             autoClose: 5000,
            //     //             hideProgressBar: false,
            //     //             closeOnClick: true,
            //     //             pauseOnHover: true,
            //     //             draggable: true,
            //     //             progress: undefined,
            //     //             theme: "light",
            //     //         });
            //     //     });
            //     // }
            // } catch (error) {
            //     console.log(error);
            // }


            try {
                setLoadingTweetThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    console.log("taregte user", targetUser)

                    for await (const element of textthread) {


                        try {


                            let fdata = new FormData();
                            let textValue = element.value


                            !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('gif[]', imgEle.filest)
                                    fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when gif is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                    fdata.append('gif[]', selectedGifOb.filest)
                                    fdata.append('image[image_code][]', selectedGifOb.name)
                                }

                            })



                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)


                                }


                            })


                            // when images is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                //     fdata.append('image[]', imgEle.imgFile)
                                // }
                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)


                                }

                            })



                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('video', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when video is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                    fdata.append('video', videoPreviews.file)
                                    fdata.append('image[image_code][]', videoPreviews.name)
                                }

                            })

                            if (cont?.t?.thread) {
                                if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('image_url', '');

                                }
                                else {
                                    fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

                                    // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
                                    //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                    //         ? image
                                    //         : null
                                    // }).filter(f => f);
                                    // fdata.append('image_url', urlAr)


                                }


                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('video_url', '');
                                }
                                else {
                                    fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');



                                }
                            }


                            const threadgif = cont?.t?.thread;
                            if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                fdata.append('gif_url', "");

                            } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }

                            count++;

                            // fdata.append("text", textValue);

                            const linkRegex = /(https?:\/\/[^\s]+)/g;
                            const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
                            // if (isChecked) {
                            //     let pureText = formattedText

                            //     {
                            //         findNextIndex(formattedText, "[ img: ").forEach((imgind, imgIndex) => {
                            //             let imgnam = formattedText.substring(imgind, imgind + 13);
                            //             pureText = pureText.replace(imgnam, "")

                            //         })
                            //     }
                            //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


                            //     fdata.append('text', withoutGif);

                            // }
                            // else {
                            //     let pureText = textValue
                            //     {
                            //         findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                            //             let imgnam = textValue.substring(imgind, imgind + 13);
                            //             pureText = pureText.replace(imgnam, "")


                            //         })
                            //     }
                            //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                            //     console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                            //     fdata.append('text', withoutGif);
                            // }
                            fdata.append('full_text', element.value);

                            let pureText = textValue
                            {
                                findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                    let imgnam = textValue.substring(imgind, imgind + 13);
                                    pureText = pureText.replace(imgnam, "")


                                })
                            }
                            let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                            console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                            fdata.append('text', withoutGif);



                            fdata.append("provider_token", providerToken);
                            fdata.append("oauth_token_secret", secretKey);

                            fdata.append("id", "")
                            fdata.append(
                                "tweet_id",
                                threadIds.tweet_id ? threadIds.tweet_id : ""
                            );
                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );


                            fdata.append('count', textthread.length);

                            await fetch(`https://app.xblue.ai/Xblue/api/create/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {


                                if (data.code == 200) {
                                    threadIds.tweet_id = data.data.tweet_id;
                                    threadIds.thread_tweet_id = data.data.thread_tweet_id;
                                    successfulThreads.push(element.value);

                                    successMessages.push(data.message);
                                    setContextData({ publishedPost: true })
                                    setSelectedImages2([])
                                    setVideoPreviews2([])
                                    {
                                        setContext({
                                            ...context,
                                            t: { ...context.t, thread: null, image: null, gif: null, text: '', }


                                        })
                                    }
                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                        failedThreads.push(element.value);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }



                            });
                            setLoadingTweetThread(false)


                        } catch (error) {
                            console.log(error);


                        }
                    }
                } else {
                    console.log("not found email")
                }


                // setLoadingTweetThread(false)

                if (successMessages.length > 0) {
                    toast.success(`Your ${successMessages.length} threads will be published in a few minutes!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

                // if (errorMessages.length === 0) {
                //     toast.success("All threads posted successfully!", {
                //         position: "top-center",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });
                // } else {
                //     // Display specific error messages if there are any
                //     errorMessages.forEach((errorMessage) => {
                //         toast.error(errorMessage, {
                //             position: "top-center",
                //             autoClose: 5000,
                //             hideProgressBar: false,
                //             closeOnClick: true,
                //             pauseOnHover: true,
                //             draggable: true,
                //             progress: undefined,
                //             theme: "light",
                //         });
                //     });
                // }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            let successfulThreads = [];
            let failedThreads = [];

            try {
                setLoadingTweetThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    console.log("taregte user", targetUser)

                    for await (const element of textthread) {


                        try {


                            let fdata = new FormData();
                            let textValue = element.value


                            !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('gif[]', imgEle.filest)
                                    fdata.append('image[image_code][]', selectedGifpickerArr ? imgEle.name : '')
                                }
                            })

                            // when gif is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                    fdata.append('gif[]', selectedGifOb.filest)
                                    fdata.append('image[image_code][]', selectedGifOb ? selectedGifOb.name : '')
                                }

                            })



                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', selectedImages2 ? imgEle.name : '')


                                }

                            })


                            // when images is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                //     fdata.append('image[]', imgEle.imgFile)
                                // }
                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', selectedImages ? imgEle.name : '')

                                }

                            })


                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('video', imgEle.imgFile)
                                    fdata.append('image[image_code][]', videoPreviews2 ? imgEle.name : '')
                                }
                            })

                            // when video is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                    fdata.append('video', videoPreviews.file)
                                    fdata.append('image[image_code][]', videoPreviews ? videoPreviews.name : '')
                                }

                            })

                            if (cont?.t?.thread) {
                                if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('image_url', '');

                                }
                                else {
                                    fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

                                    // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
                                    //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                    //         ? image
                                    //         : null
                                    // }).filter(f => f);
                                    // fdata.append('image_url', urlAr ? urlAr.join(',') : '')

                                }


                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('video_url', '');
                                }
                                else {
                                    fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
                                    // if (cont?.t?.thread && cont?.t?.thread[count]?.video) {
                                    //     if (cont?.t?.thread && tweetText?.includes(cont?.t?.thread[count]?.image_code)) {
                                    //         fdata.append('video_url', VideoUrl ? VideoUrl : '');
                                    //     }
                                    // }

                                }
                            }


                            const threadgif = cont?.t?.thread;
                            if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                fdata.append('gif_url', "");

                            } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {

                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });


                                // {
                                //     cont?.t?.thread[count]?.gif && cont?.t?.thread[count]?.gif?.map((gif, index) => {


                                //         return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index]) ?
                                //             fdata.append('gif_url', gif)
                                //             : null
                                //     })
                                // }



                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }

                            count++;

                            // fdata.append("text", textValue);

                            const linkRegex = /(https?:\/\/[^\s]+)/g;
                            const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
                            // if (isChecked) {
                            //     let pureText = formattedText

                            //     {
                            //         findNextIndex(formattedText, "[ img: ").forEach((imgind, imgIndex) => {
                            //             let imgnam = formattedText.substring(imgind, imgind + 13);
                            //             pureText = pureText.replace(imgnam, "")

                            //         })
                            //     }
                            //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


                            //     fdata.append('text', withoutGif);

                            // }
                            // else {
                            //     let pureText = textValue
                            //     {
                            //         findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                            //             let imgnam = textValue.substring(imgind, imgind + 13);
                            //             pureText = pureText.replace(imgnam, "")


                            //         })
                            //     }
                            //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                            //     console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                            //     fdata.append('text', withoutGif);
                            // }
                            fdata.append('full_text', element.value);

                            let pureText = textValue
                            {
                                findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                    let imgnam = textValue.substring(imgind, imgind + 13);
                                    pureText = pureText.replace(imgnam, "")


                                })
                            }
                            let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                            console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo }, element.value)
                            fdata.append('text', withoutGif);



                            fdata.append("provider_token", providerToken);
                            fdata.append("oauth_token_secret", secretKey);

                            fdata.append("id", "")
                            fdata.append(
                                "tweet_id",
                                threadIds.tweet_id ? threadIds.tweet_id : ""
                            );
                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );


                            fdata.append('count', textthread.length);



                            await fetch(`https://app.xblue.ai/Xblue/api/create/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {


                                if (data.code == 200) {
                                    threadIds.tweet_id = data.data.tweet_id;
                                    threadIds.thread_tweet_id = data.data.thread_tweet_id;

                                    successfulThreads.push(element.value);
                                    successMessages.push(data.message);

                                    setContextData({ publishedPost: true })
                                    setSelectedImages2([])
                                    setVideoPreviews2([])
                                    {
                                        setContext({
                                            ...context,
                                            t: { ...context.t, thread: null, image: null, gif: null, text: '', }


                                        })
                                    }
                                }
                                // else if (data.code == 500 && data.message && !errorMessages.includes(data.error_message)) {

                                //     errorMessages.push(data.error_message);
                                //     failedThreads.push(element);
                                //     // handleFailedPost(failedThreads)
                                // }
                                // else {
                                //     errorMessages.push(data.message);
                                //     failedThreads.push(element);
                                //     // handleFailedPost(failedThreads)

                                // }


                                else {

                                    threadIds.tweet_id = data.data.tweet_id;
                                    threadIds.thread_tweet_id = data.data.thread_tweet_id;

                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                        failedThreads.push(element.value);
                                        console.log("successsssss", element.value)
                                        //handleFailedPost(failedThreads)
                                    }
                                    else if (data.code == 500) {
                                        errorMessages.push(data.error_message);
                                        failedThreads.push(element.value);


                                    }

                                    else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }



                            });
                            setLoadingTweetThread(false)


                        } catch (error) {

                            console.log(error);


                        }
                    }
                } else {
                    console.log("not found email")
                }

                console.log("success", successMessages.length, errorMessages.length, "fail", failedThreads, "success", successfulThreads)


                // setLoadingTweetThread(false)

                if (successMessages.length > 0) {
                    toast.success(`Your ${successMessages.length} threads will be published in a few minutes!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

                if (errorMessages.length > 0) {

                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessages.length + errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

                // if (errorMessages.length === 0) {
                //     toast.success("All threads posted successfully!", {
                //         position: "top-center",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });
                // } else {
                //     // Display specific error messages if there are any
                //     errorMessages.forEach((errorMessage) => {
                //         toast.error(errorMessage, {
                //             position: "top-center",
                //             autoClose: 5000,
                //             hideProgressBar: false,
                //             closeOnClick: true,
                //             pauseOnHover: true,
                //             draggable: true,
                //             progress: undefined,
                //             theme: "light",
                //         });
                //     });
                // }
            } catch (error) {
                console.log(error);
            }
        }
    }



    const handleFailedPost = async (failedPost) => {
        console.log("failed fpisr ", failedPost)


        if (localStorage.getItem("userloggedin") === 'true') {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = cont?.t?.thread?.map(item => item.id).join(',');

            try {
                setLoadingDraftThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;

                    // setLoadingDraft(true)
                    let successCount = 0;
                    let errorOccurred = false;
                    let textthreadIndexArr = textthread.map(a => a.threadInd)

                    for await (const element of textthread) {
                        console.log(element, 'forelement', cont?.t?.thread)

                        try {

                            if (
                                element.value == "\n" ||
                                element.value == "" ||
                                element.value == null
                            ) {
                            }
                            else {


                                let fdata = new FormData();
                                let textValue = getTextWithoutImg(element.value, "[ img: ");




                                !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                    if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                        fdata.append('gif[]', imgEle.filest)
                                        fdata.append('image[image_code][]', imgEle.name)
                                    }
                                })

                                // when gif is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                        fdata.append('gif[]', selectedGifOb.filest)
                                        fdata.append('image[image_code][]', selectedGifOb.name)
                                    }

                                })



                                findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedImages2.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                    if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                        // fdata.append('image[]', imgEle.imgFile)
                                        fdata.append('image[image_file][]', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)


                                    }


                                })


                                // when images is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedImages.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                    // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    //     fdata.append('image[]', imgEle.imgFile)
                                    // }
                                    if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                        // fdata.append('image[]', imgEle.imgFile)
                                        fdata.append('image[image_file][]', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)


                                    }

                                })



                                findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                    if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                        fdata.append('video', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)
                                    }
                                })

                                // when video is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                        fdata.append('video', videoPreviews.file)
                                        fdata.append('image[image_code][]', videoPreviews.name)
                                    }

                                })

                                if (cont?.t?.thread) {
                                    if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                        fdata.append('image_url', '');

                                    }
                                    else {
                                        fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

                                        // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
                                        //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                        //         ? image
                                        //         : null
                                        // }).filter(f => f);
                                        // fdata.append('image_url', urlAr)


                                    }


                                    if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                        fdata.append('video_url', '');
                                    }
                                    else {
                                        fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');



                                    }
                                }


                                const threadgif = cont?.t?.thread;
                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('gif_url', "");

                                } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                    threadgif[count].gif.forEach(item => {
                                        console.log(item, 'itemm-gif');
                                        fdata.append('gif_url', item);
                                    });
                                } else {
                                    // Handle the case where the array or gif property doesn't exist
                                    console.error('Cannot read properties of undefined or gif is not an array');
                                }


                                count++;

                                console.log("ids-=-", JSON.stringify(idString))

                                if (cont?.t?.thread) {
                                    fdata.append('ids', JSON.stringify(idString));
                                }
                                else {
                                    fdata.append('ids', '');
                                }



                                // fdata.append("text", textValue);
                                const linkRegex = /(https?:\/\/[^\s]+)/g;
                                const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
                                // if (isChecked) {
                                //     let pureText = formattedText

                                //     {
                                //         findNextIndex(formattedText, "[ img: ").forEach((imgind, imgIndex) => {
                                //             let imgnam = formattedText.substring(imgind, imgind + 13);
                                //             pureText = pureText.replace(imgnam, "")

                                //         })
                                //     }
                                //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


                                //     fdata.append('text', withoutGif);

                                // }
                                // else {
                                //     let pureText = textValue
                                //     {
                                //         findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                //             let imgnam = textValue.substring(imgind, imgind + 13);
                                //             pureText = pureText.replace(imgnam, "")


                                //         })
                                //     }
                                //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                                //     console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                                //     fdata.append('text', withoutGif);
                                // }


                                let pureText = textValue
                                {
                                    findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                        let imgnam = textValue.substring(imgind, imgind + 13);
                                        pureText = pureText.replace(imgnam, "")


                                    })
                                }
                                let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                                console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                                fdata.append('text', withoutGif);

                                fdata.append('full_text', element.value);




                                fdata.append("provider_token", providerToken);
                                fdata.append("oauth_token_secret", secretKey);


                                if (!cont?.t?.thread) {

                                    fdata.append("id", "")
                                }
                                else if (element.id) {

                                    fdata.append("id", element.id)
                                }

                                else {
                                    fdata.append('id', cont?.t?.thread[element.threadInd]?.id ? cont?.t?.thread[element.threadInd]?.id : '');
                                }



                                fdata.append("tweet_id", "");
                                fdata.append(
                                    "thread_tweet_id",
                                    threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                                );

                                if (cont?.t?.thread) {
                                    const currentThreadCount = textthread.length;
                                    if (currentThreadCount !== prevThreadCount) {
                                        fdata.append('count', currentThreadCount);
                                    }
                                    else {


                                        fdata.append('count', cont?.t?.thread?.length);
                                    }
                                }

                                // fdata.append('count', textthread.length);


                                await fetch(`${BASE_URL}api/draft/thread`, {
                                    method: "POST",
                                    body: fdata,
                                    headers: {
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                }).then((response) => {
                                    return response.json();
                                }).then((data) => {
                                    // setDataa(data)

                                    if (data.code == 200) {
                                        successCount++; // Increment the success count
                                        threadIds.tweet_id = data.data.thread_draft ? data.data.thread_draft.tweet_id : data.data.updated_thread_draft.tweet_id;
                                        threadIds.thread_tweet_id = data.data.thread_draft ? data.data.thread_draft.thread_tweet_id : data.data.updated_thread_draft.thread_tweet_id;

                                        successMessages.push(data.message);
                                    }
                                    else {
                                        // Collect specific error messages from the backend
                                        if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                            errorMessages.push(data.message);
                                        } else {
                                            // Handle other error cases
                                            console.error(`Unexpected error: ${data.message}`);
                                        }
                                    }

                                });

                            }
                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    // setLoadingDraft(false)
                }
                else {
                    console.log("eororon sksks  444")
                }
                setLoadingDraftThread(false)

                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads drafted successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ DraftSaved: true })

                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = cont?.t?.thread?.map(item => item.id).join(',');

            try {
                setLoadingDraftThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;

                    // setLoadingDraft(true)
                    let successCount = 0;
                    let errorOccurred = false;
                    let textthreadIndexArr = textthread.map(a => a.threadInd)

                    for await (const element of failedPost) {
                        console.log(element, 'forelement', cont?.t?.thread)

                        try {

                            if (
                                element.value == "\n" ||
                                element.value == "" ||
                                element.value == null
                            ) {
                            }
                            else {


                                let fdata = new FormData();
                                let textValue = getTextWithoutImg(element.value, "[ img: ");




                                !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                    if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                        fdata.append('gif[]', imgEle.filest)
                                        fdata.append('image[image_code][]', imgEle.name)
                                    }
                                })

                                // when gif is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                        fdata.append('gif[]', selectedGifOb.filest)
                                        fdata.append('image[image_code][]', selectedGifOb.name)
                                    }

                                })



                                findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedImages2.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                    if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                        // fdata.append('image[]', imgEle.imgFile)
                                        fdata.append('image[image_file][]', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)


                                    }


                                })


                                // when images is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedImages.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                    // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    //     fdata.append('image[]', imgEle.imgFile)
                                    // }
                                    if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                        // fdata.append('image[]', imgEle.imgFile)
                                        fdata.append('image[image_file][]', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)


                                    }

                                })



                                findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                    if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                        fdata.append('video', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)
                                    }
                                })

                                // when video is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                        fdata.append('video', videoPreviews.file)
                                        fdata.append('image[image_code][]', videoPreviews.name)
                                    }

                                })

                                if (cont?.t?.thread) {
                                    if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                        fdata.append('image_url', '');

                                    }
                                    else {
                                        fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

                                        // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
                                        //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                        //         ? image
                                        //         : null
                                        // }).filter(f => f);
                                        // fdata.append('image_url', urlAr)


                                    }


                                    if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                        fdata.append('video_url', '');
                                    }
                                    else {
                                        fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');



                                    }
                                }


                                const threadgif = cont?.t?.thread;
                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('gif_url', "");

                                } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                    threadgif[count].gif.forEach(item => {
                                        console.log(item, 'itemm-gif');
                                        fdata.append('gif_url', item);
                                    });
                                } else {
                                    // Handle the case where the array or gif property doesn't exist
                                    console.error('Cannot read properties of undefined or gif is not an array');
                                }


                                count++;

                                console.log("ids-=-", JSON.stringify(idString))

                                if (cont?.t?.thread) {
                                    fdata.append('ids', JSON.stringify(idString));
                                }
                                else {
                                    fdata.append('ids', '');
                                }



                                // fdata.append("text", textValue);
                                const linkRegex = /(https?:\/\/[^\s]+)/g;
                                const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
                                // if (isChecked) {
                                //     let pureText = formattedText

                                //     {
                                //         findNextIndex(formattedText, "[ img: ").forEach((imgind, imgIndex) => {
                                //             let imgnam = formattedText.substring(imgind, imgind + 13);
                                //             pureText = pureText.replace(imgnam, "")

                                //         })
                                //     }
                                //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


                                //     fdata.append('text', withoutGif);

                                // }
                                // else {
                                //     let pureText = textValue
                                //     {
                                //         findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                //             let imgnam = textValue.substring(imgind, imgind + 13);
                                //             pureText = pureText.replace(imgnam, "")


                                //         })
                                //     }
                                //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                                //     console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                                //     fdata.append('text', withoutGif);
                                // }


                                let pureText = textValue
                                {
                                    findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                        let imgnam = textValue.substring(imgind, imgind + 13);
                                        pureText = pureText.replace(imgnam, "")


                                    })
                                }
                                let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                                console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                                fdata.append('text', withoutGif);

                                fdata.append('full_text', element.value);




                                fdata.append("provider_token", providerToken);
                                fdata.append("oauth_token_secret", secretKey);


                                if (!cont?.t?.thread) {

                                    fdata.append("id", "")
                                }
                                else if (element.id) {

                                    fdata.append("id", element.id)
                                }

                                else {
                                    fdata.append('id', cont?.t?.thread[element.threadInd]?.id ? cont?.t?.thread[element.threadInd]?.id : '');
                                }



                                fdata.append("tweet_id", "");
                                fdata.append(
                                    "thread_tweet_id",
                                    threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                                );

                                if (cont?.t?.thread) {
                                    const currentThreadCount = textthread.length;
                                    if (currentThreadCount !== prevThreadCount) {
                                        fdata.append('count', currentThreadCount);
                                    }
                                    else {


                                        fdata.append('count', cont?.t?.thread?.length);
                                    }
                                }

                                // fdata.append('count', textthread.length);


                                await fetch(`${BASE_URL}api/draft/thread`, {
                                    method: "POST",
                                    body: fdata,
                                    headers: {
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                }).then((response) => {
                                    return response.json();
                                }).then((data) => {
                                    // setDataa(data)

                                    if (data.code == 200) {
                                        successCount++; // Increment the success count
                                        threadIds.tweet_id = data.data.thread_draft ? data.data.thread_draft.tweet_id : data.data.updated_thread_draft.tweet_id;
                                        threadIds.thread_tweet_id = data.data.thread_draft ? data.data.thread_draft.thread_tweet_id : data.data.updated_thread_draft.thread_tweet_id;

                                        successMessages.push(data.message);
                                    }
                                    else {
                                        // Collect specific error messages from the backend
                                        if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                            errorMessages.push(data.message);
                                        } else {
                                            // Handle other error cases
                                            console.error(`Unexpected error: ${data.message}`);
                                        }
                                    }

                                });

                            }
                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    // setLoadingDraft(false)
                }
                else {
                    console.log("eororon sksks  444")
                }
                setLoadingDraftThread(false)

                // if (successMessages.length > 0) {
                //     toast.success(`${successMessages.length} threads drafted successfully!`, {
                //         position: "top-center",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });
                setContextData({ DraftSaved: true })

                // }

                // if (errorMessages.length > 0) {
                //     errorMessages.forEach((errorMessage) => {
                //         toast.error(errorMessage, {
                //             position: "top-center",
                //             autoClose: 5000,
                //             hideProgressBar: false,
                //             closeOnClick: true,
                //             pauseOnHover: true,
                //             draggable: true,
                //             progress: undefined,
                //             theme: "light",
                //         });
                //     });
                // }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleQueueThread = async (e) => {
        e.preventDefault()
        console.log("hgvvgv", selectedGifOb, typeof selectedGifOb)
        if (localStorage.getItem("userloggedin") === 'true') {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = cont?.t?.thread?.map(item => item.id).join(',');
            try {
                setLoadingQueueThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    // setLoadingQueue(true)
                    let successCount = 0;
                    let errorOccurred = false;

                    for await (const element of textthread) {

                        try {

                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");

                            findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('gif[]', imgEle.filest)
                                }
                            })
                            // fdata.append('video', '')
                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2)

                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('image[]', imgEle.imgFile)
                                }
                            })

                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('video', imgEle.imgFile)
                                }
                            })


                            if (cont?.t?.thread) {

                                // fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
                                // fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
                                if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('image_url', '');

                                }
                                else {
                                    fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
                                }
                                // fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
                                //fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');

                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('video_url', '');
                                }
                                else {
                                    fdata.append('video_url', cont?.t?.thread[count]?.video ? cont?.t?.thread[count]?.video : '');
                                }
                            }


                            const threadgif = cont?.t?.thread;
                            // if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                            //     threadgif[count].gif.forEach(item => {
                            //         console.log(item, 'itemm-gif');
                            //         fdata.append('gif_url', item);
                            //     });
                            // } else {
                            //     // Handle the case where the array or gif property doesn't exist
                            //     console.error('Cannot read properties of undefined or gif is not an array');
                            // }
                            if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                fdata.append('gif_url', "");

                            } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }

                            count++;

                            const linkRegex = /(https?:\/\/[^\s]+)/g;
                            const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);



                            let pureText = textValue
                            {
                                findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                    let imgnam = textValue.substring(imgind, imgind + 13);
                                    pureText = pureText.replace(imgnam, "")
                                    console.log({ imgind, imgnam, textValue, pureText }, textValue.includes(imgnam), "imgindimgind")

                                })
                            }

                            console.log(pureText, "pureTextpureText")
                            fdata.append('text', pureText);


                            // fdata.append("text", textValue);

                            fdata.append("provider_token", providerToken);

                            fdata.append('time', moment(queueTime, 'hh:mm:ss').format('hh:mm A'))

                            fdata.append('date', moment(cont.formattedDate).format('DD-MM-YYYY'))
                            fdata.append('day', moment(cont.formattedDate).format('dddd'))


                            if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
                                fdata.append('timezone', '')
                            }
                            else {
                                fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
                            }
                            // fdata.append('timezone', timeZone ? timeZone : '')
                            // fdata.append('is_thread', 1)


                            if (!cont?.t?.thread) {
                                fdata.append("id", "")


                            }
                            else if (cont?.type == 'draft') {
                                fdata.append("id", "")
                            }
                            else if (cont?.type == 'published') {
                                fdata.append("id", "")
                            }

                            else if (element.id) {
                                fdata.append("id", element.id)

                            }
                            else {
                                fdata.append('id', cont?.t?.thread[element.threadInd]?.id ? cont?.t?.thread[element.threadInd]?.id : '');

                            }



                            if (cont?.t?.thread) {
                                fdata.append('ids', JSON.stringify(idString));
                            }
                            else {
                                fdata.append('ids', '');
                            }

                            if (!threadIds.tweet_id) {
                                // If tweet_id is not set, set it to the response's id
                                fdata.append("tweet_id", "");
                            } else {
                                // Use the same tweet_id as the first response
                                fdata.append("tweet_id", threadIds.tweet_id);
                            }

                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );


                            fdata.append('count', textthread.length);

                            await fetch(`${BASE_URL}api/queue/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {

                                // setDataa(data)

                                if (data.code == 200) {
                                    successCount++;

                                    if (!threadIds.tweet_id) {
                                        threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : '';
                                    }
                                    threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
                                    // setContextData({ QUEUESaved: true })

                                    successMessages.push(data.message);


                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }

                            });

                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    // setLoadingQueue(false)

                }
                else {
                    console.log("erroror akakakak thyread")
                }

                setLoadingQueueThread(false)
                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads queued successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ QUEUESaved: true })

                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

            } catch (error) {
                console.log(error);
            }

            // try {
            //     setLoadingQueueThread(true)
            //     const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
            //     const targetUser = userDataArray.find(user => user.email === emailTo);

            //     if (targetUser) {
            //         const { userID, accessToken, providerToken, secretKey } = targetUser;
            //         // setLoadingQueue(true)
            //         let successCount = 0;
            //         let errorOccurred = false;

            //         for await (const element of textthread) {

            //             try {

            //                 let fdata = new FormData();
            //                 let textValue = getTextWithoutImg(element.value, "[ img: ");

            //                 // findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
            //                 //     console.log("img file", imgind)
            //                 //     let imgnam = element.value.substring(imgind, imgind + 13);
            //                 //     let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
            //                 //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

            //                 //     if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
            //                 //         fdata.append('gif[]', imgEle.filest)
            //                 //     }
            //                 // })
            //                 // // fdata.append('video', '')
            //                 // findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
            //                 //     let imgnam = element.value.substring(imgind, imgind + 13);
            //                 //     let imgEle = selectedImages2.find(el => el.name === imgnam)
            //                 //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2)

            //                 //     if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
            //                 //         fdata.append('image[]', imgEle.imgFile)
            //                 //     }
            //                 // })

            //                 // findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
            //                 //     console.log("img file", imgind)
            //                 //     let imgnam = element.value.substring(imgind, imgind + 13);
            //                 //     let imgEle = videoPreviews2.find(el => el.name === imgnam)
            //                 //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

            //                 //     if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
            //                 //         fdata.append('video', imgEle.imgFile)
            //                 //     }
            //                 // })



            //                 // if (cont?.t?.thread) {

            //                 //     // fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
            //                 //     // fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
            //                 //     if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
            //                 //         fdata.append('image_url', '');

            //                 //     }
            //                 //     else {
            //                 //         fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
            //                 //     }

            //                 //     if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
            //                 //         fdata.append('video_url', '');
            //                 //     }
            //                 //     else {
            //                 //         fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
            //                 //     }
            //                 // }


            //                 // const threadgif = cont?.t?.thread;
            //                 // // if (Array.isArray(threadgif) && threadgif[count]?.gif) {
            //                 // //     threadgif[count].gif.forEach(item => {
            //                 // //         console.log(item, 'itemm-gif');
            //                 // //         fdata.append('gif_url', item);
            //                 // //     });
            //                 // // } else {
            //                 // //     // Handle the case where the array or gif property doesn't exist
            //                 // //     console.error('Cannot read properties of undefined or gif is not an array');
            //                 // // }
            //                 // if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
            //                 //     fdata.append('gif_url', "");

            //                 // } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
            //                 //     threadgif[count].gif.forEach(item => {
            //                 //         console.log(item, 'itemm-gif');
            //                 //         fdata.append('gif_url', item);
            //                 //     });
            //                 // } else {
            //                 //     // Handle the case where the array or gif property doesn't exist
            //                 //     console.error('Cannot read properties of undefined or gif is not an array');
            //                 // }


            //                 !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
            //                     console.log("img file", imgind)
            //                     let imgnam = element.value.substring(imgind, imgind + 13);
            //                     let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
            //                     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

            //                     if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
            //                         fdata.append('gif[]', imgEle.filest)
            //                         fdata.append('image[image_code][]', imgEle.name)
            //                     }
            //                 })

            //                 // when gif is sleected for single compose tweet but then switched to thread.
            //                 findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
            //                     console.log("img file", imgind)
            //                     let imgnam = element.value.substring(imgind, imgind + 13);
            //                     if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
            //                         fdata.append('gif[]', selectedGifOb.filest)
            //                         fdata.append('image[image_code][]', selectedGifOb.name)
            //                     }

            //                 })

            //                 findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
            //                     let imgnam = element.value.substring(imgind, imgind + 13);
            //                     let imgEle = selectedImages2.find(el => el.name === imgnam)
            //                     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

            //                     if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
            //                         // fdata.append('image[]', imgEle.imgFile)
            //                         fdata.append('image[image_file][]', imgEle.imgFile)
            //                         fdata.append('image[image_code][]', imgEle.name)

            //                     }

            //                 })


            //                 // when images is sleected for single compose tweet but then switched to thread.
            //                 findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
            //                     let imgnam = element.value.substring(imgind, imgind + 13);
            //                     let imgEle = selectedImages.find(el => el.name === imgnam)
            //                     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

            //                     // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
            //                     //     fdata.append('image[]', imgEle.imgFile)
            //                     // }
            //                     if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
            //                         // fdata.append('image[]', imgEle.imgFile)
            //                         fdata.append('image[image_file][]', imgEle.imgFile)
            //                         fdata.append('image[image_code][]', imgEle.name)


            //                     }

            //                 })



            //                 findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
            //                     console.log("img file", imgind)
            //                     let imgnam = element.value.substring(imgind, imgind + 13);
            //                     let imgEle = videoPreviews2.find(el => el.name === imgnam)
            //                     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

            //                     if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
            //                         fdata.append('video', imgEle.imgFile)
            //                         fdata.append('image[image_code][]', imgEle.name)
            //                     }
            //                 })

            //                 // when video is sleected for single compose tweet but then switched to thread.
            //                 findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
            //                     console.log("img file", imgind)
            //                     let imgnam = element.value.substring(imgind, imgind + 13);
            //                     if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
            //                         fdata.append('video', videoPreviews.file)
            //                         fdata.append('image[image_code][]', videoPreviews.name)
            //                     }

            //                 })

            //                 if (cont?.t?.thread) {
            //                     if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
            //                         fdata.append('image_url', '');

            //                     }
            //                     else {
            //                         fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

            //                         // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
            //                         //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
            //                         //         ? image
            //                         //         : null
            //                         // }).filter(f => f);
            //                         // fdata.append('image_url', urlAr)


            //                     }


            //                     if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
            //                         fdata.append('video_url', '');
            //                     }
            //                     else {
            //                         fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');



            //                     }
            //                 }


            //                 const threadgif = cont?.t?.thread;
            //                 if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
            //                     fdata.append('gif_url', "");

            //                 } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
            //                     threadgif[count].gif.forEach(item => {
            //                         console.log(item, 'itemm-gif');
            //                         fdata.append('gif_url', item);
            //                     });
            //                 } else {
            //                     // Handle the case where the array or gif property doesn't exist
            //                     console.error('Cannot read properties of undefined or gif is not an array');
            //                 }


            //                 count++;

            //                 const linkRegex = /(https?:\/\/[^\s]+)/g;
            //                 const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);




            //                 // let pureText = textValue
            //                 // {
            //                 //     findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
            //                 //         let imgnam = textValue.substring(imgind, imgind + 13);
            //                 //         pureText = pureText.replace(imgnam, "")

            //                 //     })
            //                 // }
            //                 // let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
            //                 // let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


            //                 // fdata.append('text', withoutGif);

            //                 fdata.append('full_text', element.value);

            //                 let pureText = textValue
            //                 {
            //                     findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
            //                         let imgnam = textValue.substring(imgind, imgind + 13);
            //                         pureText = pureText.replace(imgnam, "")


            //                     })
            //                 }
            //                 let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
            //                 let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

            //                 console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
            //                 fdata.append('text', withoutGif);




            //                 //fdata.append("text", element.value);



            //                 fdata.append("provider_token", providerToken);

            //                 fdata.append('time', moment(queueTime, 'hh:mm:ss').format('hh:mm A'))

            //                 fdata.append('date', moment(cont.formattedDate).format('DD-MM-YYYY'))
            //                 fdata.append('day', moment(cont.formattedDate).format('dddd'))


            //                 if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
            //                     fdata.append('timezone', '')
            //                 }
            //                 else {
            //                     fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
            //                 }
            //                 // fdata.append('timezone', timeZone ? timeZone : '')
            //                 // fdata.append('is_thread', 1)


            //                 if (!cont?.t?.thread) {
            //                     fdata.append("id", "")


            //                 }
            //                 else if (cont?.type == 'draft') {
            //                     fdata.append("id", "")
            //                 }
            //                 else if (cont?.type == 'published') {
            //                     fdata.append("id", "")
            //                 }

            //                 else if (element.id) {
            //                     fdata.append("id", element.id)

            //                 }
            //                 else {
            //                     fdata.append('id', cont?.t?.thread[element.threadInd]?.id ? cont?.t?.thread[element.threadInd]?.id : '');

            //                 }



            //                 if (cont?.t?.thread) {
            //                     fdata.append('ids', JSON.stringify(idString));
            //                 }
            //                 else {
            //                     fdata.append('ids', '');
            //                 }

            //                 if (!threadIds.tweet_id) {
            //                     // If tweet_id is not set, set it to the response's id
            //                     fdata.append("tweet_id", "");
            //                 } else {
            //                     // Use the same tweet_id as the first response
            //                     fdata.append("tweet_id", threadIds.tweet_id);
            //                 }

            //                 fdata.append(
            //                     "thread_tweet_id",
            //                     threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
            //                 );


            //                 fdata.append('count', textthread.length);

            //                 await fetch(`${BASE_URL}api/queue/thread`, {
            //                     method: "POST",
            //                     body: fdata,
            //                     headers: {
            //                         Authorization: `Bearer ${accessToken}`,
            //                     },
            //                 }).then((response) => {
            //                     return response.json();
            //                 }).then((data) => {

            //                     // setDataa(data)

            //                     if (data.code == 200) {
            //                         successCount++;

            //                         if (!threadIds.tweet_id) {
            //                             threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : '';
            //                         }
            //                         threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
            //                         // setContextData({ QUEUESaved: true })

            //                         successMessages.push(data.message);


            //                     } else {
            //                         // Collect specific error messages from the backend
            //                         if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
            //                             errorMessages.push(data.message);
            //                         } else {
            //                             // Handle other error cases
            //                             console.error(`Unexpected error: ${data.message}`);
            //                         }
            //                     }

            //                 });

            //             } catch (error) {
            //                 console.log(error);
            //                 errorOccurred = true; // Set errorOccurred flag to true
            //             }
            //         }


            //         // setLoadingQueue(false)

            //     }
            //     else {
            //         console.log("erroror akakakak thyread")
            //     }

            //     setLoadingQueueThread(false)
            //     if (successMessages.length > 0) {
            //         toast.success(`${successMessages.length} threads queued successfully!`, {
            //             position: "top-center",
            //             autoClose: 5000,
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //             theme: "light",
            //         });
            //         setContextData({ QUEUESaved: true })

            //     }

            //     if (errorMessages.length > 0) {
            //         errorMessages.forEach((errorMessage) => {
            //             toast.error(errorMessage, {
            //                 position: "top-center",
            //                 autoClose: 5000,
            //                 hideProgressBar: false,
            //                 closeOnClick: true,
            //                 pauseOnHover: true,
            //                 draggable: true,
            //                 progress: undefined,
            //                 theme: "light",
            //             });
            //         });
            //     }

            // } catch (error) {
            //     console.log(error);
            // }
        }
        else {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = cont?.t?.thread?.map(item => item.id).join(',');
            try {
                setLoadingQueueThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    // setLoadingQueue(true)
                    let successCount = 0;
                    let errorOccurred = false;

                    for await (const element of textthread) {

                        try {

                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");

                            // findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                            //     console.log("img file", imgind)
                            //     let imgnam = element.value.substring(imgind, imgind + 13);
                            //     let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                            //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                            //     if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                            //         fdata.append('gif[]', imgEle.filest)
                            //     }
                            // })
                            // // fdata.append('video', '')
                            // findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                            //     let imgnam = element.value.substring(imgind, imgind + 13);
                            //     let imgEle = selectedImages2.find(el => el.name === imgnam)
                            //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2)

                            //     if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                            //         fdata.append('image[]', imgEle.imgFile)
                            //     }
                            // })

                            // findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                            //     console.log("img file", imgind)
                            //     let imgnam = element.value.substring(imgind, imgind + 13);
                            //     let imgEle = videoPreviews2.find(el => el.name === imgnam)
                            //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                            //     if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                            //         fdata.append('video', imgEle.imgFile)
                            //     }
                            // })



                            // if (cont?.t?.thread) {

                            //     // fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
                            //     // fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
                            //     if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                            //         fdata.append('image_url', '');

                            //     }
                            //     else {
                            //         fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
                            //     }

                            //     if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                            //         fdata.append('video_url', '');
                            //     }
                            //     else {
                            //         fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
                            //     }
                            // }


                            // const threadgif = cont?.t?.thread;
                            // // if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                            // //     threadgif[count].gif.forEach(item => {
                            // //         console.log(item, 'itemm-gif');
                            // //         fdata.append('gif_url', item);
                            // //     });
                            // // } else {
                            // //     // Handle the case where the array or gif property doesn't exist
                            // //     console.error('Cannot read properties of undefined or gif is not an array');
                            // // }
                            // if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                            //     fdata.append('gif_url', "");

                            // } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                            //     threadgif[count].gif.forEach(item => {
                            //         console.log(item, 'itemm-gif');
                            //         fdata.append('gif_url', item);
                            //     });
                            // } else {
                            //     // Handle the case where the array or gif property doesn't exist
                            //     console.error('Cannot read properties of undefined or gif is not an array');
                            // }


                            !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('gif[]', imgEle.filest)
                                    fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when gif is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                    fdata.append('gif[]', selectedGifOb.filest)
                                    fdata.append('image[image_code][]', selectedGifOb.name)
                                }

                            })

                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)

                                }

                            })


                            // when images is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                //     fdata.append('image[]', imgEle.imgFile)
                                // }
                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)


                                }

                            })



                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('video', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when video is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                    fdata.append('video', videoPreviews.file)
                                    fdata.append('image[image_code][]', videoPreviews.name)
                                }

                            })

                            if (cont?.t?.thread) {
                                if (element.value.includes("[ vid:") || element.value.includes("[ gif:") || element.value.includes("[ img:")) {
                                    fdata.append('image_url', '');

                                }
                                else {
                                    fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

                                    // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
                                    //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                    //         ? image
                                    //         : null
                                    // }).filter(f => f);
                                    // fdata.append('image_url', urlAr ? urlAr.join(',') : '');

                                    // let urlArf = cont?.t?.thread[count]?.image_code && cont?.t?.thread[count]?.image_code?.map((image, index) => {
                                    //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                    //         ? image
                                    //         : null
                                    // }).filter(fs => fs);
                                    // fdata.append('image[image_code][]', urlArf)
                                }
                            }

                            if (cont?.t?.thread) {
                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    //if (element.value.includes("[ img:") || element.value.includes("[ gif:") || videoPreviews2.findIndex(name => element.value.includes(name.name)) > -1) {
                                    fdata.append('video_url', '');

                                }
                                else {

                                    // fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
                                    fdata.append('video_url', cont?.t?.thread[count]?.video ? cont?.t?.thread[count]?.video : '');

                                    // if (cont?.t?.thread && cont?.t?.thread[count]?.video) {
                                    //     if (cont?.t?.thread && tweetText?.includes(cont?.t?.thread[count]?.image_code)) {
                                    //         fdata.append('video_url', cont?.t?.thread[count]?.video);
                                    //     }
                                    // }

                                    // let urlArf = cont?.t?.thread[count]?.image_code && cont?.t?.thread[count]?.image_code?.map((image, index) => {
                                    //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                    //         ? image
                                    //         : null
                                    // }).filter(fs => fs);
                                    // fdata.append('image[image_code][]', urlArf)

                                    // console.log("gvghvghv bibb", urlArf, cont?.t?.thread[count]?.video, VideoUrl)



                                }
                            }

                            //console.log("gvghvghv bibb", urlArf, cont?.t?.thread[count]?.video, VideoUrl)



                            const threadgif = cont?.t?.thread;
                            if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes(selectedGifOb?.name)) {
                                fdata.append('gif_url', "");

                            } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });

                                // {
                                //     cont?.t?.thread[count]?.gif && cont?.t?.thread[count]?.gif?.map((gif, index) => {


                                //         return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index]) ?
                                //             fdata.append('gif_url', gif)
                                //             : null
                                //     })
                                // }

                                // let urlArf = cont?.t?.thread[count]?.image_code && cont?.t?.thread[count]?.image_code?.map((image, index) => {
                                //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                //         ? image
                                //         : null
                                // }).filter(fs => fs);
                                // fdata.append('image[image_code][]', urlArf)

                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }


                            count++;

                            const linkRegex = /(https?:\/\/[^\s]+)/g;
                            const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);




                            // let pureText = textValue
                            // {
                            //     findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                            //         let imgnam = textValue.substring(imgind, imgind + 13);
                            //         pureText = pureText.replace(imgnam, "")

                            //     })
                            // }
                            // let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            // let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


                            // fdata.append('text', withoutGif);

                            fdata.append('full_text', element.value);

                            let pureText = textValue
                            {
                                findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                    let imgnam = textValue.substring(imgind, imgind + 13);
                                    pureText = pureText.replace(imgnam, "")


                                })
                            }
                            let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                            console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo }, element.value)
                            fdata.append('text', withoutGif);




                            //fdata.append("text", element.value);



                            fdata.append("provider_token", providerToken);

                            fdata.append('time', moment(queueTime, 'hh:mm:ss').format('hh:mm A'))

                            fdata.append('date', moment(cont.formattedDate).format('DD-MM-YYYY'))
                            fdata.append('day', moment(cont.formattedDate).format('dddd'))


                            if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
                                fdata.append('timezone', '')
                            }
                            else {
                                fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
                            }
                            // fdata.append('timezone', timeZone ? timeZone : '')
                            // fdata.append('is_thread', 1)


                            if (!cont?.t?.thread) {
                                fdata.append("id", "")


                            }
                            else if (cont?.type == 'draft') {
                                fdata.append("id", "")
                            }
                            else if (cont?.type == 'published') {
                                fdata.append("id", "")
                            }

                            else if (element.id) {
                                fdata.append("id", element.id)

                            }
                            else {
                                fdata.append('id', cont?.t?.thread[element.threadInd]?.id ? cont?.t?.thread[element.threadInd]?.id : '');

                            }



                            if (cont?.t?.thread) {
                                fdata.append('ids', JSON.stringify(idString));
                            }
                            else {
                                fdata.append('ids', '');
                            }

                            if (!threadIds.tweet_id) {
                                // If tweet_id is not set, set it to the response's id
                                fdata.append("tweet_id", "");
                            } else {
                                // Use the same tweet_id as the first response
                                fdata.append("tweet_id", threadIds.tweet_id);
                            }

                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );


                            fdata.append('count', textthread.length);

                            await fetch(`${BASE_URL}api/queue/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {

                                // setDataa(data)

                                if (data.code == 200) {
                                    successCount++;

                                    if (!threadIds.tweet_id) {
                                        threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : '';
                                    }
                                    threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
                                    // setContextData({ QUEUESaved: true })

                                    successMessages.push(data.message);


                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }

                            });

                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    // setLoadingQueue(false)

                }
                else {
                    console.log("erroror akakakak thyread")
                }

                setLoadingQueueThread(false)
                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads queued successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ QUEUESaved: true })

                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

            } catch (error) {
                console.log(error);
            }
        }

    }

    const handleDraftThread = async (e) => {
        e.preventDefault()
        if (localStorage.getItem("userloggedin") === 'true') {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = cont?.t?.thread?.map(item => item.id).join(',');

            try {
                setLoadingDraftThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;

                    // setLoadingDraft(true)
                    let successCount = 0;
                    let errorOccurred = false;
                    let textthreadIndexArr = textthread.map(a => a.threadInd)

                    for await (const element of textthread) {
                        console.log(element, 'forelement', cont?.t?.thread)

                        try {

                            if (
                                element.value == "\n" ||
                                element.value == "" ||
                                element.value == null
                            ) {
                            }
                            else {


                                let fdata = new FormData();
                                let textValue = getTextWithoutImg(element.value, "[ img: ");




                                !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                    if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                        fdata.append('gif[]', imgEle.filest)
                                        fdata.append('image[image_code][]', imgEle.name)
                                    }
                                })

                                // when gif is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                        fdata.append('gif[]', selectedGifOb.filest)
                                        fdata.append('image[image_code][]', selectedGifOb.name)
                                    }

                                })



                                findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedImages2.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                    if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                        // fdata.append('image[]', imgEle.imgFile)
                                        fdata.append('image[image_file][]', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)


                                    }


                                })


                                // when images is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedImages.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                    // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    //     fdata.append('image[]', imgEle.imgFile)
                                    // }
                                    if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                        // fdata.append('image[]', imgEle.imgFile)
                                        fdata.append('image[image_file][]', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)


                                    }

                                })



                                findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                    if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                        fdata.append('video', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)
                                    }
                                })

                                // when video is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                        fdata.append('video', videoPreviews.file)
                                        fdata.append('image[image_code][]', videoPreviews.name)
                                    }

                                })

                                if (cont?.t?.thread) {
                                    if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                        fdata.append('image_url', '');

                                    }
                                    else {
                                        fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

                                        // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
                                        //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                        //         ? image
                                        //         : null
                                        // }).filter(f => f);
                                        // fdata.append('image_url', urlAr)


                                    }


                                    if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                        fdata.append('video_url', '');
                                    }
                                    else {
                                        fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');



                                    }
                                }


                                const threadgif = cont?.t?.thread;
                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('gif_url', "");

                                } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                    threadgif[count].gif.forEach(item => {
                                        console.log(item, 'itemm-gif');
                                        fdata.append('gif_url', item);
                                    });
                                } else {
                                    // Handle the case where the array or gif property doesn't exist
                                    console.error('Cannot read properties of undefined or gif is not an array');
                                }


                                count++;

                                console.log("ids-=-", JSON.stringify(idString))

                                if (cont?.t?.thread) {
                                    fdata.append('ids', JSON.stringify(idString));
                                }
                                else {
                                    fdata.append('ids', '');
                                }



                                // fdata.append("text", textValue);
                                const linkRegex = /(https?:\/\/[^\s]+)/g;
                                const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
                                // if (isChecked) {
                                //     let pureText = formattedText

                                //     {
                                //         findNextIndex(formattedText, "[ img: ").forEach((imgind, imgIndex) => {
                                //             let imgnam = formattedText.substring(imgind, imgind + 13);
                                //             pureText = pureText.replace(imgnam, "")

                                //         })
                                //     }
                                //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


                                //     fdata.append('text', withoutGif);

                                // }
                                // else {
                                //     let pureText = textValue
                                //     {
                                //         findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                //             let imgnam = textValue.substring(imgind, imgind + 13);
                                //             pureText = pureText.replace(imgnam, "")


                                //         })
                                //     }
                                //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                                //     console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                                //     fdata.append('text', withoutGif);
                                // }


                                let pureText = textValue
                                {
                                    findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                        let imgnam = textValue.substring(imgind, imgind + 13);
                                        pureText = pureText.replace(imgnam, "")


                                    })
                                }
                                let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                                console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                                fdata.append('text', withoutGif);

                                fdata.append('full_text', element.value);




                                fdata.append("provider_token", providerToken);
                                fdata.append("oauth_token_secret", secretKey);


                                if (!cont?.t?.thread) {

                                    fdata.append("id", "")
                                }
                                else if (element.id) {

                                    fdata.append("id", element.id)
                                }

                                else {
                                    fdata.append('id', cont?.t?.thread[element.threadInd]?.id ? cont?.t?.thread[element.threadInd]?.id : '');
                                }



                                fdata.append("tweet_id", "");
                                fdata.append(
                                    "thread_tweet_id",
                                    threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                                );

                                if (cont?.t?.thread) {
                                    const currentThreadCount = textthread.length;
                                    if (currentThreadCount !== prevThreadCount) {
                                        fdata.append('count', currentThreadCount);
                                    }
                                    else {


                                        fdata.append('count', cont?.t?.thread?.length);
                                    }
                                }

                                // fdata.append('count', textthread.length);


                                await fetch(`${BASE_URL}api/draft/thread`, {
                                    method: "POST",
                                    body: fdata,
                                    headers: {
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                }).then((response) => {
                                    return response.json();
                                }).then((data) => {
                                    // setDataa(data)

                                    if (data.code == 200) {
                                        successCount++; // Increment the success count
                                        threadIds.tweet_id = data.data.thread_draft ? data.data.thread_draft.tweet_id : data.data.updated_thread_draft.tweet_id;
                                        threadIds.thread_tweet_id = data.data.thread_draft ? data.data.thread_draft.thread_tweet_id : data.data.updated_thread_draft.thread_tweet_id;

                                        successMessages.push(data.message);
                                    }
                                    else {
                                        // Collect specific error messages from the backend
                                        if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                            errorMessages.push(data.message);
                                        } else {
                                            // Handle other error cases
                                            console.error(`Unexpected error: ${data.message}`);
                                        }
                                    }

                                });

                            }
                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    // setLoadingDraft(false)
                }
                else {
                    console.log("eororon sksks  444")
                }
                setLoadingDraftThread(false)

                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads drafted successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ DraftSaved: true })

                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = cont?.t?.thread?.map(item => item.id).join(',');

            try {
                setLoadingDraftThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken, providerToken, secretKey } = targetUser;

                    // setLoadingDraft(true)
                    let successCount = 0;
                    let errorOccurred = false;
                    let textthreadIndexArr = textthread.map(a => a.threadInd)

                    for await (const element of textthread) {
                        console.log(element, 'forelement', cont?.t?.thread)

                        try {

                            if (
                                element.value == "\n" ||
                                element.value == "" ||
                                element.value == null
                            ) {
                            }
                            else {


                                let fdata = new FormData();
                                let textValue = getTextWithoutImg(element.value, "[ img: ");




                                !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                    if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                        fdata.append('gif[]', imgEle.filest)
                                        fdata.append('image[image_code][]', imgEle.name)
                                    }
                                })

                                // when gif is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                        fdata.append('gif[]', selectedGifOb.filest)
                                        fdata.append('image[image_code][]', selectedGifOb.name)
                                    }

                                })



                                findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedImages2.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                    if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                        // fdata.append('image[]', imgEle.imgFile)
                                        fdata.append('image[image_file][]', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)


                                    }


                                })


                                // when images is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = selectedImages.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                    // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    //     fdata.append('image[]', imgEle.imgFile)
                                    // }
                                    if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                        // fdata.append('image[]', imgEle.imgFile)
                                        fdata.append('image[image_file][]', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)


                                    }

                                })



                                findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                    console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                    if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                        fdata.append('video', imgEle.imgFile)
                                        fdata.append('image[image_code][]', imgEle.name)
                                    }
                                })

                                // when video is sleected for single compose tweet but then switched to thread.
                                findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                    console.log("img file", imgind)
                                    let imgnam = element.value.substring(imgind, imgind + 13);
                                    if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                        fdata.append('video', videoPreviews.file)
                                        fdata.append('image[image_code][]', videoPreviews.name)
                                    }

                                })

                                if (cont?.t?.thread) {
                                    if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                        fdata.append('image_url', '');

                                    }
                                    else {
                                        fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

                                        // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
                                        //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                        //         ? image
                                        //         : null
                                        // }).filter(f => f);
                                        // fdata.append('image_url', urlAr)


                                    }


                                    if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                        fdata.append('video_url', '');
                                    }
                                    else {
                                        fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');



                                    }
                                }


                                const threadgif = cont?.t?.thread;
                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('gif_url', "");

                                } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                    threadgif[count].gif.forEach(item => {
                                        console.log(item, 'itemm-gif');
                                        fdata.append('gif_url', item);
                                    });
                                } else {
                                    // Handle the case where the array or gif property doesn't exist
                                    console.error('Cannot read properties of undefined or gif is not an array');
                                }


                                count++;

                                console.log("ids-=-", JSON.stringify(idString))

                                if (cont?.t?.thread) {
                                    fdata.append('ids', JSON.stringify(idString));
                                }
                                else {
                                    fdata.append('ids', '');
                                }



                                // fdata.append("text", textValue);
                                const linkRegex = /(https?:\/\/[^\s]+)/g;
                                const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);
                                // if (isChecked) {
                                //     let pureText = formattedText

                                //     {
                                //         findNextIndex(formattedText, "[ img: ").forEach((imgind, imgIndex) => {
                                //             let imgnam = formattedText.substring(imgind, imgind + 13);
                                //             pureText = pureText.replace(imgnam, "")

                                //         })
                                //     }
                                //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);


                                //     fdata.append('text', withoutGif);

                                // }
                                // else {
                                //     let pureText = textValue
                                //     {
                                //         findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                //             let imgnam = textValue.substring(imgind, imgind + 13);
                                //             pureText = pureText.replace(imgnam, "")


                                //         })
                                //     }
                                //     let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                //     let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                                //     console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                                //     fdata.append('text', withoutGif);
                                // }


                                let pureText = textValue
                                {
                                    findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                        let imgnam = textValue.substring(imgind, imgind + 13);
                                        pureText = pureText.replace(imgnam, "")


                                    })
                                }
                                let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                                let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                                console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                                fdata.append('text', withoutGif);

                                fdata.append('full_text', element.value);




                                fdata.append("provider_token", providerToken);
                                fdata.append("oauth_token_secret", secretKey);


                                if (!cont?.t?.thread) {

                                    fdata.append("id", "")
                                }
                                else if (element.id) {

                                    fdata.append("id", element.id)
                                }

                                else {
                                    fdata.append('id', cont?.t?.thread[element.threadInd]?.id ? cont?.t?.thread[element.threadInd]?.id : '');
                                }



                                fdata.append("tweet_id", "");
                                fdata.append(
                                    "thread_tweet_id",
                                    threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                                );

                                if (cont?.t?.thread) {
                                    const currentThreadCount = textthread.length;
                                    if (currentThreadCount !== prevThreadCount) {
                                        fdata.append('count', currentThreadCount);
                                    }
                                    else {


                                        fdata.append('count', cont?.t?.thread?.length);
                                    }
                                }

                                // fdata.append('count', textthread.length);


                                await fetch(`${BASE_URL}api/draft/thread`, {
                                    method: "POST",
                                    body: fdata,
                                    headers: {
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                }).then((response) => {
                                    return response.json();
                                }).then((data) => {
                                    // setDataa(data)

                                    if (data.code == 200) {
                                        successCount++; // Increment the success count
                                        threadIds.tweet_id = data.data.thread_draft ? data.data.thread_draft.tweet_id : data.data.updated_thread_draft.tweet_id;
                                        threadIds.thread_tweet_id = data.data.thread_draft ? data.data.thread_draft.thread_tweet_id : data.data.updated_thread_draft.thread_tweet_id;

                                        successMessages.push(data.message);
                                    }
                                    else {
                                        // Collect specific error messages from the backend
                                        if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                            errorMessages.push(data.message);
                                        } else {
                                            // Handle other error cases
                                            console.error(`Unexpected error: ${data.message}`);
                                        }
                                    }

                                });

                            }
                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    // setLoadingDraft(false)
                }
                else {
                    console.log("eororon sksks  444")
                }
                setLoadingDraftThread(false)

                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads drafted successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ DraftSaved: true })

                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }


    }



    const [selectedDay, setSelectedDay] = useState()

    const handleScheduleLater = async (e) => {
        e.preventDefault()


        if (localStorage.getItem("userloggedin") === 'true') {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = cont?.t?.thread?.map(item => item.id).join(',');


            try {
                setLoadingSchduleThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailTo);


                if (targetUser) {

                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    // setLoadingSchdule(true)
                    let successCount = 0;
                    let errorOccurred = false;

                    for await (const element of textthread) {

                        try {

                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");


                            !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('gif[]', imgEle.filest)
                                    fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when gif is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                    fdata.append('gif[]', selectedGifOb.filest)
                                    fdata.append('image[image_code][]', selectedGifOb.name)
                                }

                            })



                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)


                                }


                            })


                            // when images is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                //     fdata.append('image[]', imgEle.imgFile)
                                // }
                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)


                                }

                            })



                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('video', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when video is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                    fdata.append('video', videoPreviews.file)
                                    fdata.append('image[image_code][]', videoPreviews.name)
                                }

                            })

                            if (cont?.t?.thread) {
                                if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('image_url', '');

                                }
                                else {
                                    fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

                                    // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
                                    //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                    //         ? image
                                    //         : null
                                    // }).filter(f => f);
                                    // fdata.append('image_url', urlAr)


                                }


                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('video_url', '');
                                }
                                else {
                                    fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');



                                }
                            }


                            const threadgif = cont?.t?.thread;
                            if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                fdata.append('gif_url', "");

                            } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }



                            // console.log(cont?.t?.thread, 'counttttt');
                            // findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                            //     console.log("img file", imgind)
                            //     let imgnam = element.value.substring(imgind, imgind + 13);
                            //     let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                            //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                            //     if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                            //         fdata.append('gif[]', imgEle.filest)
                            //     }
                            // })
                            // // fdata.append('video', '')
                            // findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                            //     let imgnam = element.value.substring(imgind, imgind + 13);
                            //     let imgEle = selectedImages2.find(el => el.name === imgnam)
                            //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2)

                            //     if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                            //         fdata.append('image[]', imgEle.imgFile)
                            //     }
                            // })

                            // findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                            //     console.log("img file", imgind)
                            //     let imgnam = element.value.substring(imgind, imgind + 13);
                            //     let imgEle = videoPreviews2.find(el => el.name === imgnam)
                            //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                            //     if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                            //         fdata.append('video', imgEle.imgFile)
                            //     }
                            // })



                            // if (cont?.t?.thread) {

                            //     // fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
                            //     // fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
                            //     if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                            //         fdata.append('image_url', '');

                            //     }
                            //     else {
                            //         fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
                            //     }


                            //     if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                            //         fdata.append('video_url', '');
                            //     }
                            //     else {
                            //         fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
                            //     }
                            // }


                            // const threadgif = cont?.t?.thread;
                            // // if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                            // //     threadgif[count].gif.forEach(item => {
                            // //         console.log(item, 'itemm-gif');
                            // //         fdata.append('gif_url', item);
                            // //     });
                            // // } else {
                            // //     // Handle the case where the array or gif property doesn't exist
                            // //     console.error('Cannot read properties of undefined or gif is not an array');
                            // // }
                            // if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                            //     fdata.append('gif_url', "");

                            // } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                            //     threadgif[count].gif.forEach(item => {
                            //         console.log(item, 'itemm-gif');
                            //         fdata.append('gif_url', item);
                            //     });
                            // } else {
                            //     // Handle the case where the array or gif property doesn't exist
                            //     console.error('Cannot read properties of undefined or gif is not an array');
                            // }



                            // fdata.append('time', .cont?.t?.thread ? moment(.cont?.t?.thread[count]?.schedule_time, 'hh:mm:ss').format('hh:mm A') : selectedTim)
                            // fdata.append('date', .cont?.t?.thread ? moment(.cont?.t?.thread[count]?.schedule_date, 'DD-MM-YYYY').format('DD/MM/YYYY') : selectedDat)
                            // fdata.append('day', .cont?.t?.thread ? moment(.cont?.t?.thread[count]?.schedule_date, 'DD-MM-YYYY').format('dddd') : moment(selectedDat, 'DD-MM-YYYY').format('dddd'))

                            fdata.append('time', selectedTim2 ? selectedTim2 : moment(cont?.t?.schedule_time, 'hh:mm:ss').format("hh:mm A"))
                            fdata.append('date', selectedDat2 ? moment(selectedDat2, "DD-MM-YYYY").format('DD-MM-YYYY') : moment(cont?.t?.schedule_date, "DD-MM-YYYY").format('DD-MM-YYYY'))
                            fdata.append('day', selectedDat2 ? moment(selectedDat2, 'DD-MM-YYYY').format('dddd') : cont?.t?.schedule_day)

                            count++;

                            // fdata.append("text", textValue);
                            const linkRegex = /(https?:\/\/[^\s]+)/g;
                            const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);


                            fdata.append('full_text', element.value);

                            let pureText = textValue
                            {
                                findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                    let imgnam = textValue.substring(imgind, imgind + 13);
                                    pureText = pureText.replace(imgnam, "")


                                })
                            }
                            let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                            console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                            fdata.append('text', withoutGif);

                            fdata.append("provider_token", providerToken);



                            if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
                                fdata.append('timezone', '')
                            }
                            else {
                                fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
                            }
                            // fdata.append('timezone', timeZone ? timeZone : '')
                            // fdata.append('is_thread', 1)


                            if (!cont?.t?.thread) {
                                fdata.append("id", "")


                            }
                            else if (cont?.type == 'draft') {
                                fdata.append("id", "")
                            }
                            else if (cont?.type == 'published') {
                                fdata.append("id", "")
                            }

                            else if (element.id) {
                                fdata.append("id", element.id)

                            }
                            else {
                                fdata.append('id', cont?.t?.thread[element.threadInd]?.id ? cont?.t?.thread[element.threadInd]?.id : '');

                            }

                            if (!threadIds.tweet_id) {
                                // If tweet_id is not set, set it to the response's id
                                fdata.append("tweet_id", "");
                            } else {
                                // Use the same tweet_id as the first response
                                fdata.append("tweet_id", threadIds.tweet_id);
                            }

                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );

                            if (cont?.t?.thread) {
                                fdata.append('ids', JSON.stringify(idString));
                            }
                            else {
                                fdata.append('ids', '');
                            }

                            fdata.append('count', textthread.length);
                            await fetch(`${BASE_URL}api/schedule/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {

                                // setDataa(data)

                                if (data.code == 200) {
                                    successCount++;

                                    if (!threadIds.tweet_id) {
                                        threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : '';
                                    }
                                    threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
                                    // setContextData({ QUEUESaved: true })

                                    successMessages.push(data.message);


                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }

                            });

                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    // setLoadingSchdule(false)
                }
                else {
                    console.log("scjnsjncs cdes schduler")
                }

                setLoadingSchduleThread(false)

                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads scheduled successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ QUEUESaved: true })
                    setSelectedDateTime('')
                    setIsOpen(false)
                    setSelectedDateTime2('')
                    setIsOpenThread(false)


                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

            } catch (error) {
                console.log(error, "hhuhu");
            }
        }
        else {
            let threadIds = { tweet_id: "", thread_tweet_id: "" };
            let count = 0;
            let errorMessages = [];
            let successMessages = [];
            const idString = cont?.t?.thread?.map(item => item.id).join(',');

            try {
                setLoadingSchduleThread(true)
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];
                const targetUser = userDataArray.find(user => user.email === emailSwitch);


                if (targetUser) {

                    const { userID, accessToken, providerToken, secretKey } = targetUser;
                    // setLoadingSchdule(true)
                    let successCount = 0;
                    let errorOccurred = false;

                    for await (const element of textthread) {

                        try {

                            let fdata = new FormData();
                            let textValue = getTextWithoutImg(element.value, "[ img: ");


                            !element.value.includes("[ img:") && !element.value.includes("[ vid:") && findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                                if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('gif[]', imgEle.filest)
                                    fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when gif is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (selectedGifOb?.name === imgnam && selectedGifOb.type === 'gif') {
                                    fdata.append('gif[]', selectedGifOb.filest)
                                    fdata.append('image[image_code][]', selectedGifOb.name)
                                }

                            })



                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2, cont?.t?.image_code)

                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)


                                }


                            })


                            // when images is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = selectedImages.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages)

                                // if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                //     fdata.append('image[]', imgEle.imgFile)
                                // }
                                if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                                    // fdata.append('image[]', imgEle.imgFile)
                                    fdata.append('image[image_file][]', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)


                                }

                            })



                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                let imgEle = videoPreviews2.find(el => el.name === imgnam)
                                console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                                if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                                    fdata.append('video', imgEle.imgFile)
                                    fdata.append('image[image_code][]', imgEle.name)
                                }
                            })

                            // when video is sleected for single compose tweet but then switched to thread.
                            findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                                console.log("img file", imgind)
                                let imgnam = element.value.substring(imgind, imgind + 13);
                                if (videoPreviews?.name === imgnam && videoPreviews.type === 'video') {
                                    fdata.append('video', videoPreviews.file)
                                    fdata.append('image[image_code][]', videoPreviews.name)
                                }

                            })

                            if (cont?.t?.thread) {
                                if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('image_url', '');

                                }
                                else {
                                    fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');

                                    // let urlAr = cont?.t?.thread[count]?.image && cont?.t?.thread[count]?.image?.map((image, index) => {
                                    //     return cont.t && element.value?.includes(cont?.t?.thread[count]?.image_code[index])
                                    //         ? image
                                    //         : null
                                    // }).filter(f => f);
                                    // fdata.append('image_url', urlAr)


                                }


                                if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                    fdata.append('video_url', '');
                                }
                                else {
                                    fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');



                                }
                            }


                            const threadgif = cont?.t?.thread;
                            if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                                fdata.append('gif_url', "");

                            } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                                threadgif[count].gif.forEach(item => {
                                    console.log(item, 'itemm-gif');
                                    fdata.append('gif_url', item);
                                });
                            } else {
                                // Handle the case where the array or gif property doesn't exist
                                console.error('Cannot read properties of undefined or gif is not an array');
                            }



                            // console.log(cont?.t?.thread, 'counttttt');
                            // findNextIndex(element.value, "[ gif: ").map((imgind, imgIndex) => {
                            //     console.log("img file", imgind)
                            //     let imgnam = element.value.substring(imgind, imgind + 13);
                            //     let imgEle = selectedGifpickerArr.find(el => el.name === imgnam)
                            //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedGifpickerArr)

                            //     if (imgEle && imgEle.type === 'gif' && imgEle.imgnm === imgEle.imgnm2) {
                            //         fdata.append('gif[]', imgEle.filest)
                            //     }
                            // })
                            // // fdata.append('video', '')
                            // findNextIndex(element.value, "[ img: ").map((imgind, imgIndex) => {
                            //     let imgnam = element.value.substring(imgind, imgind + 13);
                            //     let imgEle = selectedImages2.find(el => el.name === imgnam)
                            //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', selectedImages2)

                            //     if (imgEle && imgEle.type === 'img' && imgEle.imgnm === imgEle.imgnm2) {
                            //         fdata.append('image[]', imgEle.imgFile)
                            //     }
                            // })

                            // findNextIndex(element.value, "[ vid: ").map((imgind, imgIndex) => {
                            //     console.log("img file", imgind)
                            //     let imgnam = element.value.substring(imgind, imgind + 13);
                            //     let imgEle = videoPreviews2.find(el => el.name === imgnam)
                            //     console.log({ imgind, imgIndex, imgnam, imgEle }, 'imgindimgind', videoPreviews2)

                            //     if (imgEle && imgEle.type === 'video' && imgEle.imgnm === imgEle.imgnm2) {
                            //         fdata.append('video', imgEle.imgFile)
                            //     }
                            // })



                            // if (cont?.t?.thread) {

                            //     // fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
                            //     // fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
                            //     if (element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                            //         fdata.append('image_url', '');

                            //     }
                            //     else {
                            //         fdata.append('image_url', cont?.t?.thread[count]?.image ? cont?.t?.thread[count]?.image.join(',') : '');
                            //     }


                            //     if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                            //         fdata.append('video_url', '');
                            //     }
                            //     else {
                            //         fdata.append('video_url', cont?.t?.thread ? cont?.t?.thread[count]?.video : '');
                            //     }
                            // }


                            // const threadgif = cont?.t?.thread;
                            // // if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                            // //     threadgif[count].gif.forEach(item => {
                            // //         console.log(item, 'itemm-gif');
                            // //         fdata.append('gif_url', item);
                            // //     });
                            // // } else {
                            // //     // Handle the case where the array or gif property doesn't exist
                            // //     console.error('Cannot read properties of undefined or gif is not an array');
                            // // }
                            // if (element.value.includes("[ img:") || element.value.includes("[ vid:") || element.value.includes("[ gif:")) {
                            //     fdata.append('gif_url', "");

                            // } else if (Array.isArray(threadgif) && threadgif[count]?.gif) {
                            //     threadgif[count].gif.forEach(item => {
                            //         console.log(item, 'itemm-gif');
                            //         fdata.append('gif_url', item);
                            //     });
                            // } else {
                            //     // Handle the case where the array or gif property doesn't exist
                            //     console.error('Cannot read properties of undefined or gif is not an array');
                            // }



                            // fdata.append('time', .cont?.t?.thread ? moment(.cont?.t?.thread[count]?.schedule_time, 'hh:mm:ss').format('hh:mm A') : selectedTim)
                            // fdata.append('date', .cont?.t?.thread ? moment(.cont?.t?.thread[count]?.schedule_date, 'DD-MM-YYYY').format('DD/MM/YYYY') : selectedDat)
                            // fdata.append('day', .cont?.t?.thread ? moment(.cont?.t?.thread[count]?.schedule_date, 'DD-MM-YYYY').format('dddd') : moment(selectedDat, 'DD-MM-YYYY').format('dddd'))

                            fdata.append('time', selectedTim2 ? selectedTim2 : moment(cont?.t?.schedule_time, 'hh:mm:ss').format("hh:mm A"))
                            fdata.append('date', selectedDat2 ? moment(selectedDat2, "DD-MM-YYYY").format('DD-MM-YYYY') : moment(cont?.t?.schedule_date, "DD-MM-YYYY").format('DD-MM-YYYY'))
                            fdata.append('day', selectedDat2 ? moment(selectedDat2, 'DD-MM-YYYY').format('dddd') : cont?.t?.schedule_day)

                            count++;

                            // fdata.append("text", textValue);
                            const linkRegex = /(https?:\/\/[^\s]+)/g;
                            const formattedText = textValue.replace(linkRegex, (_, link) => `"${link}"`);


                            fdata.append('full_text', element.value);

                            let pureText = textValue
                            {
                                findNextIndex(textValue, "[ img: ").forEach((imgind, imgIndex) => {
                                    let imgnam = textValue.substring(imgind, imgind + 13);
                                    pureText = pureText.replace(imgnam, "")


                                })
                            }
                            let withoutVdo = getTextWithoutImg(pureText, `[ vid: `);
                            let withoutGif = getTextWithoutImg(withoutVdo, `[ gif: `);

                            console.log(pureText, "pureTextpureText", { withoutGif, withoutVdo })
                            fdata.append('text', withoutGif);

                            fdata.append("provider_token", providerToken);



                            if (displayProfileDetails?.timezone == null || displayProfileDetails?.timezone == '' || displayProfileDetails?.timezone == undefined) {
                                fdata.append('timezone', '')
                            }
                            else {
                                fdata.append('timezone', displayProfileDetails.timezone ? displayProfileDetails.timezone : '')
                            }
                            // fdata.append('timezone', timeZone ? timeZone : '')
                            // fdata.append('is_thread', 1)


                            if (!cont?.t?.thread) {
                                fdata.append("id", "")


                            }
                            else if (cont?.type == 'draft') {
                                fdata.append("id", "")
                            }
                            else if (cont?.type == 'published') {
                                fdata.append("id", "")
                            }

                            else if (element.id) {
                                fdata.append("id", element.id)

                            }
                            else {
                                fdata.append('id', cont?.t?.thread[element.threadInd]?.id ? cont?.t?.thread[element.threadInd]?.id : '');

                            }

                            if (!threadIds.tweet_id) {
                                // If tweet_id is not set, set it to the response's id
                                fdata.append("tweet_id", "");
                            } else {
                                // Use the same tweet_id as the first response
                                fdata.append("tweet_id", threadIds.tweet_id);
                            }

                            fdata.append(
                                "thread_tweet_id",
                                threadIds.thread_tweet_id ? threadIds.thread_tweet_id : ""
                            );

                            if (cont?.t?.thread) {
                                fdata.append('ids', JSON.stringify(idString));
                            }
                            else {
                                fdata.append('ids', '');
                            }

                            fdata.append('count', textthread.length);
                            await fetch(`${BASE_URL}api/schedule/thread`, {
                                method: "POST",
                                body: fdata,
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }).then((response) => {
                                return response.json();
                            }).then((data) => {

                                // setDataa(data)

                                if (data.code == 200) {
                                    successCount++;

                                    if (!threadIds.tweet_id) {
                                        threadIds.tweet_id = data.data.queue.id ? data.data.queue.id : '';
                                    }
                                    threadIds.thread_tweet_id = data.data.queue.id ? data.data.queue.id : ''
                                    // setContextData({ QUEUESaved: true })

                                    successMessages.push(data.message);


                                } else {
                                    // Collect specific error messages from the backend
                                    if (data.code === 400 && data.message && !errorMessages.includes(data.message)) {
                                        errorMessages.push(data.message);
                                    } else {
                                        // Handle other error cases
                                        console.error(`Unexpected error: ${data.message}`);
                                    }
                                }

                            });

                        } catch (error) {
                            console.log(error);
                            errorOccurred = true; // Set errorOccurred flag to true
                        }
                    }


                    // setLoadingSchdule(false)
                }
                else {
                    console.log("scjnsjncs cdes schduler")
                }

                setLoadingSchduleThread(false)

                if (successMessages.length > 0) {
                    toast.success(`${successMessages.length} threads scheduled successfully!`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setContextData({ QUEUESaved: true })
                    setSelectedDateTime('')
                    setIsOpen(false)
                    setSelectedDateTime2('')
                    setIsOpenThread(false)


                }

                if (errorMessages.length > 0) {
                    errorMessages.forEach((errorMessage) => {
                        toast.error(errorMessage, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    });
                }

            } catch (error) {
                console.log(error, "hhuhu");
            }
        }


    }










    return (
        <>
            <div className="right-sidebar-mini" id="rightSidebar" >






                {/* <div className={themeContext?.theme == true ? "right-sidebar-mini-dark p-0" : "right-sidebar-panel p-0"}> */}

                <div className="right-sidebar-panel p-0">

                    <Card className="shadow-none">
                        <Card.Body className="p-0">

                            <div className="right-sidebar-toggle bg-primary text-white mt-3 d-flex" onClick={minirightsidebar}>
                                <span className="material-symbols-outlined">chat</span>
                            </div>
                        </Card.Body>
                    </Card>


                    <div className='media-height'>
                        {/* {showthread ? <>
                            <div>
                                <Alert variant="light" className='alert-text' style={{ width: '91%' }}>
                                    <p className='para-alert'>
                                        <FontAwesomeIcon icon={faCircleInfo} className='alert-icon' />
                                        When creating thread please only interact from the thread preview.
                                    </p>
                                </Alert>
                            </div>
                        </> : ''} */}


                        <div className='card-form-compose'>
                            <form >
                                <h5 style={{ fontWeight: 600 }}>Compose a post here</h5>

                                {showthread == false ?
                                    <div className='save-draft' onClick={handleDraft}>
                                        {loaderDraft ? (
                                            <Spinner animation="border" variant="primary" />
                                        ) : (

                                            " Save as draft")}

                                    </div> : (
                                        <div className='save-draft' onClick={handleDraftThread}>
                                            {loadingDraftThread ? (
                                                <Spinner animation="border" variant="primary" />
                                            ) : (

                                                " Save as draft")}

                                        </div>
                                    )}
                                <textarea
                                    className='text-area-compose'
                                    type="text"
                                    id="t1"
                                    value={tweetText}
                                    onChange={handleTextChange}
                                    onKeyPress={handleKeyPress}
                                    onClickCapture={handleKeyDown}

                                    ref={textareaRef}
                                    placeholder={`Write here.\n\n\nSkip 3 lines to start a thread.`}
                                    // style={{ height: `${calculateHeight()}px`, resize: 'none', overflowY: 'auto' }}
                                    style={{ minHeight: '200px', height: 'auto', resize: 'none', overflowY: 'scroll' }}
                                // maxLength={280}
                                />

                                <div className='attachments-flex'>
                                    <div >
                                        {/* {showthread == false ? <span>{remainingWords}/280</span> : ''} */}
                                        {/* <span>{wordCount}/280</span> */}
                                        {/* <span>{remainingWords}/280</span> */}

                                        {showthread == false ? <span className={`${characterCount > 280 ? 'text-area-red' : ''}`}>{characterCount ? characterCount : 0}/280</span> : ''}
                                        <button className={isSaved ? 'button-saved' : 'button-unsaved'} onClick={handleAutoSaved}> Autosaved</button>
                                    </div>

                                    <div className='input-attachment'>


                                        <FontAwesomeIcon icon={faBolt} className='emoji-mar-bolt' onClick={handleOpenAIoptions} style={{ color: isOpenAI ? '#284E79' : '' }} />
                                        {/* </OverlayTrigger> */}
                                        {isOpenAI ? (
                                            <FontAwesomeIcon icon={faCaretUp} className='emoji-mar-arrow' style={{ color: isOpenAI ? '#284E79' : '' }} onClick={handleOpenAIoptions} />
                                        ) : (
                                            <FontAwesomeIcon icon={faCaretDown} className='emoji-mar-arrow' style={{ color: isOpenAI ? '#284E79' : '' }} onClick={handleOpenAIoptions} />
                                        )}




                                        {showthread == false ? (
                                            <>
                                                <label htmlFor="image-upload">
                                                    <input
                                                        ref={imageInputRef}
                                                        id="image-upload"
                                                        type="file"
                                                        accept="image/*, video/*"
                                                        style={{ display: 'none' }}
                                                        onChange={handleImageSelect}
                                                    />
                                                    <FontAwesomeIcon icon={faFileMedical} className='image-icon' />
                                                </label>

                                            </>) : (
                                            <>
                                                <label htmlFor="image-upload">
                                                    <input
                                                        key={inputKey}
                                                        ref={imageInputRef}
                                                        id="image-upload"
                                                        type="file"
                                                        accept="image/*, video/*"
                                                        style={{ display: 'none' }}
                                                        onChange={handleImageSelect2}

                                                    />
                                                    {/* <i className='fa fa-file image-icon' /> */}
                                                    <FontAwesomeIcon icon={faFileMedical} className='image-icon' />
                                                </label>
                                            </>

                                        )}


                                        <FontAwesomeIcon icon={faSmile} onClick={toggleEmojiPicker} className='emoji-mar' />
                                        {/* ) : " "} */}
                                        {showthread == false ? (

                                            <>
                                                {/* <FontAwesomeIcon icon={faFilm} onClick={handleGifopen} /> */}
                                                <img src={gifimage} onClick={handleGifopen} style={{ width: 25, cursor: 'pointer' }} />
                                            </>
                                        )
                                            : (
                                                <>
                                                    <img src={gifimage} onClick={handleGifopen2} style={{ width: 25, cursor: 'pointer' }} />
                                                </>
                                            )}

                                    </div>

                                </div>

                                {isOpenAI ? (

                                    <div className='AI-options-screen'>
                                        {/* <Container> */}
                                        <p>Improve your post with AI:</p>

                                        <ul className='two-column-list'>
                                            <li onClick={() => handleItemClickAIImproveTweet("Improve Tweet")} className='icon-text-black'>

                                                <FontAwesomeIcon icon={faWandMagicSparkles} className='icon-bule' />   {loadingimpoveText ? <Spinner animation="border" variant="primary" size="sm" /> : 'Improve Tweet'}
                                            </li>
                                            <li onClick={() => handleItemClickAIKeepWriting("Keep writing")} className='icon-text-black'>
                                                <FontAwesomeIcon icon={faForwardFast} className='icon-bule' />   {loadingKeepWriting ? <Spinner animation="border" variant="primary" size="sm" /> : 'Keep writing'}
                                            </li>
                                            <li onClick={() => handleItemClickAIMoreAssertive("More Assertive")} className='icon-text-black'>
                                                <FontAwesomeIcon icon={faHandBackFist} className='icon-bule' /> {loadingMoreAssertive ? <Spinner animation="border" variant="primary" size="sm" /> : 'More Assertive'}
                                            </li>
                                            <li onClick={() => handleItemClickAIAddEmoji("Add emojis")} className='icon-text-black'>
                                                <FontAwesomeIcon icon={faSmile} className='icon-bule' />  {loadingAddEmoji ? <Spinner animation="border" variant="primary" size="sm" /> : 'Add emojis'}
                                            </li>
                                            <li onClick={() => handleItemClickAIMoreCasual("More Casual")} className='icon-text-black'>
                                                <FontAwesomeIcon icon={faMugSaucer} className='icon-bule' />  {loadingMoreCasual ? <Spinner animation="border" variant="primary" size="sm" /> : 'More Casual'}
                                            </li>
                                            <li onClick={() => handleItemClickAIMakeShorter("Make shorter")} className='icon-text-black'>
                                                <FontAwesomeIcon icon={faMinimize} className='icon-bule' />  {loadingMakeShorter ? <Spinner animation="border" variant="primary" size="sm" /> : 'Make shorter'}
                                            </li>
                                            <li onClick={() => handleItemClickAIMoreFormal("More Formal")} className='icon-text-black'>
                                                <FontAwesomeIcon icon={faUserTie} className='icon-bule' />  {loadingMoreFormal ? <Spinner animation="border" variant="primary" size="sm" /> : 'More Formal'}
                                            </li>
                                            <li onClick={() => handleItemClickAIExpandTweet("Expand tweet")} className='icon-text-black'>
                                                <FontAwesomeIcon icon={faMaximize} className='icon-bule' />  {loadingExpandTweet ? <Spinner animation="border" variant="primary" size="sm" /> : 'Expand tweet'}
                                            </li>
                                            <li onClick={() => handleItemClickAIFixGrammer("Fix grammer")} className='icon-text-black'>
                                                <FontAwesomeIcon icon={faSpellCheck} className='icon-bule' />  {loadingFixGrammer ? <Spinner animation="border" variant="primary" size="sm" /> : 'Fix grammer'}
                                            </li>
                                        </ul>
                                        {/* </Container> */}


                                    </div>

                                ) : ''}

                                {/* <div style={{ marginBottom: 20 }}>

                                    {loadingpro && <ProgressBar now={loader} label={`${loader}%`} />}
                                </div> */}

                                <div className='image-select-background-'>
                                    {/* 
                                    {selectedFile.length > 0 && (
                                        <div>
                                            <p>Selected Videos:</p>
                                            <div className="video-previews">
                                                {selectedFile?.img?.map((previewURL, index) => (
                                                    <video key={index} controls width="300">
                                                        <source src={previewURL} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                ))}
                                            </div>
                                        </div>
                                    )} */}

                                    {showthread == false ? (
                                        videoPreviews?.preview && (
                                            <div>

                                                {/* <video width="100%" height="240"
                                                    controls
                                                    src={videoPreviews?.preview}>


                                                </video> */}



                                                {/* <video
                                                    width="100%" height="240"
                                                    controls
                                                >
                                                    <source
                                                        src={
                                                            videoPreviews?.preview}

                                                        type="video/mp4" />

                                                </video>
                                                <FontAwesomeIcon icon={faCircleXmark} type="button" onClick={() => handleRemoveVideo1()} className='cross-remove-2' /> */}

                                                {findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                                                    let imgnam = tweetText.substring(imgind, imgind + 13);
                                                    tweetText.replace(imgnam, "");

                                                    return (
                                                        <>
                                                            {videoPreviews &&
                                                                videoPreviews.name === imgnam ? (
                                                                <>



                                                                    <video
                                                                        style={{ width: '80px', height: "72px", borderRadius: '10px' }}

                                                                    >
                                                                        <source src={videoPreviews.preview}
                                                                            type="video/mp4" />

                                                                    </video>

                                                                </>

                                                            ) : null}

                                                        </>
                                                    )
                                                })}


                                            </div>
                                        )) : (

                                        findNextIndex(tweetText, "[ vid: ").map((imgind, imgIndex) => {
                                            let imgnam = tweetText.substring(imgind, imgind + 13);
                                            tweetText.replace(imgnam, "");

                                            return (
                                                <>
                                                    {videoPreviews2 &&
                                                        videoPreviews2.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                        <>
                                                            {console.log("condn==>", videoPreviews2.findIndex((a) => a.name === imgnam))}

                                                            {/* <video

                                                                src={
                                                                    videoPreviews2[
                                                                        videoPreviews2 &&
                                                                        videoPreviews2.findIndex((a) => a.name === imgnam)
                                                                    ]?.preview
                                                                }
                                                                style={{ width: '80px', height: "72px", borderRadius: '10px' }}


                                                            /> */}

                                                            <video
                                                                style={{ width: '80px', height: "72px", borderRadius: '10px' }}

                                                            >
                                                                <source src={videoPreviews2[
                                                                    videoPreviews2 &&
                                                                    videoPreviews2.findIndex((a) => a.name === imgnam)
                                                                ]?.preview}
                                                                    type="video/mp4" />

                                                            </video>

                                                        </>

                                                    ) : null}

                                                </>
                                            )
                                        }))}
                                    {/* //{videoPreviews2 ? videoPreviews2?.map((image, index) => (
                                    //     <div>

                                    //         <video width="100%" height="240"
                                    //             controls
                                    //             src={image?.preview}>


                                    //         </video>

                                    //     </div>
                                    // )) : ' '} */}

                                    {VideoUrl && (
                                        <div>

                                            {/* <video width="100%" height="240"
                                                controls
                                                src={VideoUrl}>


                                            </video>
                                            <FontAwesomeIcon icon={faCircleXmark} type="button" onClick={() => handleRemoveVideo()} className='cross-remove-2' /> */}

                                            {cont.t && cont.t.video ? (

                                                cont.t && tweetText?.includes(cont.t.image_code) ?

                                                    <video width="100%" height="240"
                                                        controls
                                                        src={cont.t.video}>


                                                    </video>

                                                    : null

                                            ) : ' hahahah'}
                                        </div>
                                    )}




                                    {showthread == false ? (
                                        <Row>
                                            {cont.t && cont?.t?.image && cont?.t?.image?.map((image, index) => {

                                                return cont.t && tweetText?.includes(cont?.t?.image_code[index]) ?
                                                    <Col lg={4} sm={4} md={4} key={index}
                                                    >
                                                        <img src={image} alt={`Selected ${index + 1}`} style={{ width: '80px', height: '80px', marginBottom: '10px' }} />



                                                    </Col>
                                                    : null
                                            })}



                                            {findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                                tweetText.replace(imgnam, "");

                                                console.log("dejcef", imgind, imgIndex, selectedImages, imgnam)

                                                return (
                                                    <Col lg={4} sm={4} md={4} className="thread-li" key={imgIndex}
                                                    >

                                                        {selectedImages &&
                                                            selectedImages.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                            <img
                                                                src={
                                                                    selectedImages[
                                                                        selectedImages &&
                                                                        selectedImages.findIndex((a) => a.name === imgnam)
                                                                    ]?.img
                                                                }
                                                                className="image-img"
                                                                style={{ width: '80px', height: '80px', marginBottom: '10px', }}
                                                            />
                                                        ) : null}

                                                    </Col>
                                                );
                                            })}





                                        </Row>
                                    ) : (
                                        <Row>
                                            {/* {selectedImages?.map((image, index) => (
                                                <Col lg={4} sm={4} md={4} key={index}
                                                >

                                                    <img src={image.img} alt={`Selected ${index + 1}`} style={{ width: '80px', height: '80px', marginBottom: '10px', display: image?.imgnm === image?.imgnm2 ? 'block' : 'none' }} />
                                                    <FontAwesomeIcon icon={faCircleXmark} type="button" onClick={() => handleRemoveImage(index)} className='cross-remove' />


                                                </Col>
                                            ))} */}


                                            {findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                                tweetText.replace(imgnam, "");

                                                return (
                                                    <Col lg={4} sm={4} md={4} className="thread-li" key={imgIndex}
                                                    >
                                                        {/* <li className="thread-li" key={imgIndex}> */}
                                                        {selectedImages2 &&
                                                            selectedImages2.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                            <img
                                                                src={
                                                                    selectedImages2[
                                                                        selectedImages2 &&
                                                                        selectedImages2.findIndex((a) => a.name === imgnam)
                                                                    ]?.img
                                                                }
                                                                className="image-img"
                                                                style={{ width: '80px', height: '80px', marginBottom: '10px', }}
                                                            />
                                                        ) : null}
                                                        {/* </li> */}
                                                    </Col>
                                                );
                                            })}

                                            {/* 
---------------------------------- when images is sleected for single compose tweet but then switched to thread. --------------------------- */}

                                            {findNextIndex(tweetText, "[ img: ").map((imgind, imgIndex) => {
                                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                                tweetText.replace(imgnam, "");

                                                return (
                                                    <Col lg={4} sm={4} md={4} className="thread-li" key={imgIndex}
                                                    >
                                                        {/* <li className="thread-li" key={imgIndex}> */}
                                                        {selectedImages &&
                                                            selectedImages.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                            <img
                                                                src={
                                                                    selectedImages[
                                                                        selectedImages &&
                                                                        selectedImages.findIndex((a) => a.name === imgnam)
                                                                    ]?.img
                                                                }
                                                                className="image-img"
                                                                style={{ width: '80px', height: '80px', marginBottom: '10px', }}
                                                            />
                                                        ) : null}
                                                        {/* </li> */}
                                                    </Col>
                                                );
                                            })}


                                        </Row>

                                    )}



                                    {/* {selectedGifpicker2 && selectedGifpicker2?.map((gif, index) => (
                                        <Col lg={4} sm={4} md={4} key={index}
                                        // style={{ display: 'inline-block', marginRight: '10px' }}
                                        >
                                            <img src={gif.g} alt={`Selected ${index + 1}`} style={{ width: '80px', height: '80px', marginBottom: '10px' }} />
                                            <FontAwesomeIcon icon={faCircleXmark} type="button" onClick={() => handleRemoveGif(index)} className='cross-remove' />


                                        </Col>
                                    ))} */}


                                    {/* {cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                        return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                            <Col lg={4} sm={4} md={4} key={index}
                                            >
                                                <img src={gif} alt={`Selected ${index + 1}`} style={{ width: '80px', height: '80px', marginBottom: '10px' }} />
                                                <FontAwesomeIcon icon={faCircleXmark} type="button" onClick={() => handleRemoveImage(index)} className='cross-remove' />


                                            </Col>
                                            : null
                                    })} */}


                                    {showthread == false ? (
                                        // selectedGifpicker && (
                                        //     <div>
                                        //         <img src={selectedGifOb.gif} alt="Selected GIF" style={{ width: '100px', height: '100px', marginBottom: 10 }} />
                                        //         <FontAwesomeIcon icon={faCircleXmark} type="button" onClick={handleRemoveGif} className='cross-remove' />

                                        //     </div>)
                                        <>

                                            {cont.t && cont.t.gif && cont.t.gif?.map((gif, index) => {

                                                return cont.t && tweetText?.includes(cont.t.image_code[index]) ?
                                                    <Col lg={4} sm={4} md={4} key={index}
                                                    >
                                                        <img src={gif} alt={`Selected ${index + 1}`} style={{ width: '80px', height: '80px', marginBottom: '10px' }} />
                                                        {/* <FontAwesomeIcon icon={faCircleXmark} type="button" onClick={() => handleRemoveImage(index)} className='cross-remove' /> */}


                                                    </Col>
                                                    : null
                                            })}


                                            {findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                                                let imgnam = tweetText.substring(imgind, imgind + 13);
                                                tweetText.replace(imgnam, "");

                                                return (
                                                    <Col lg={4} sm={4} md={4} className="thread-li" key={imgIndex}
                                                    >
                                                        {/* <li className="thread-li" key={imgIndex}> */}
                                                        {selectedGifOb &&
                                                            selectedGifOb.name === imgnam ? (
                                                            <img
                                                                src={
                                                                    selectedGifOb.gif
                                                                }
                                                                className="image-img"
                                                                style={{ width: '80px', height: '80px', marginBottom: '10px', }}
                                                            />
                                                        ) : null}
                                                        {/* </li> */}
                                                    </Col>
                                                );
                                            })}
                                        </>

                                    )
                                        : (

                                            // selectedGifpickerArr?.map((selectedGif, index) => {
                                            //     return (
                                            //         <>
                                            //             <img src={selectedGif.gif} alt="Selected GIF" style={{ width: '100px', height: '100px', marginBottom: 10 }} />

                                            //         </>
                                            //     )
                                            // })
                                            <Row>
                                                {findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                                                    let imgnam = tweetText.substring(imgind, imgind + 13);
                                                    tweetText.replace(imgnam, "");

                                                    return (
                                                        <Col lg={4} sm={4} md={4} className="thread-li" key={imgIndex}
                                                        >
                                                            {/* <li className="thread-li" key={imgIndex}> */}
                                                            {selectedGifpickerArr &&
                                                                selectedGifpickerArr.findIndex((a) => a.name === imgnam) >= 0 ? (
                                                                <img
                                                                    src={
                                                                        selectedGifpickerArr[
                                                                            selectedGifpickerArr &&
                                                                            selectedGifpickerArr.findIndex((a) => a.name === imgnam)
                                                                        ]?.gif
                                                                    }
                                                                    className="image-img"
                                                                    style={{ width: '80px', height: '80px', marginBottom: '10px', }}
                                                                />
                                                            ) : null}
                                                            {/* </li> */}
                                                        </Col>
                                                    );
                                                })}


                                                {findNextIndex(tweetText, "[ gif: ").map((imgind, imgIndex) => {
                                                    let imgnam = tweetText.substring(imgind, imgind + 13);
                                                    tweetText.replace(imgnam, "");

                                                    return (
                                                        <Col lg={4} sm={4} md={4} className="thread-li" key={imgIndex}
                                                        >
                                                            {/* <li className="thread-li" key={imgIndex}> */}
                                                            {selectedGifOb &&
                                                                selectedGifOb.name === imgnam ? (
                                                                <img
                                                                    src={
                                                                        selectedGifOb.gif
                                                                    }
                                                                    className="image-img"
                                                                    style={{ width: '80px', height: '80px', marginBottom: '10px', }}
                                                                />
                                                            ) : null}
                                                            {/* </li> */}
                                                        </Col>
                                                    )
                                                })}

                                            </Row>
                                        )

                                    }




                                    {/* Emoji picker */}
                                    {showEmojiPicker && (
                                        <Picker onEmojiClick={handleEmojiSelect} />
                                    )}
                                </div>



                                {showGif &&
                                    <div>

                                        <ReactGiphySearchbox
                                            apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
                                            onSelect={handleGifPicker}
                                            masonryConfig={[
                                                { columns: 2, imageWidth: 110, gutter: 5 },
                                                { mq: "700px", columns: 3, imageWidth: 120, gutter: 5 }
                                            ]}
                                        />
                                    </div>
                                }


                                {showGif2 &&
                                    <div>

                                        <ReactGiphySearchbox
                                            apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
                                            onSelect={handleGifPicker2}
                                            masonryConfig={[
                                                { columns: 2, imageWidth: 110, gutter: 5 },
                                                { mq: "700px", columns: 3, imageWidth: 120, gutter: 5 }
                                            ]}
                                        />
                                    </div>
                                }



                                {showthread == false ? (
                                    <>

                                        <Row className='mb-3'>
                                            <Col sm={6} md={6} lg={6} xs={6}>





                                                <button className='button-rightsidebar' type='submit' onClick={handleTweetSubmit}>
                                                    {/* <button className={contextData?.theme == true ? 'dark-buttton' : 'button-rightsidebar'} type='submit' onClick={handleTweetSubmit}> */}
                                                    {loading2 ?
                                                        <Spinner animation="border" size="sm" variant="white" className='mr-5' style={{ marginRight: 5 }} /> :

                                                        "Post now"}

                                                </button>
                                            </Col>

                                            <Col sm={6} md={6} lg={6} xs={6}>
                                                <div className='button-queue'>
                                                    <button className='button-rightsidebar' onClick={handleOpenQueue} >
                                                        {loaderQueue ? (
                                                            <Spinner animation="border" variant="light" />
                                                        ) : (

                                                            <>
                                                                Add to queue <br />
                                                                <span className='queue-text'>
                                                                    {/* {cont.t ? moment(cont.t.time, 'hh:mm:ss').format(' h:mm A') : ''} | {moment(cont.formattedDate, 'dddd | MMMM DD YYYY').format('MMM DD YYYY ') ? moment(handleDate, 'dddd | MMMM DD YYYY').format('MMM DD YYYY ') : ''} */}

                                                                    {cont?.t && moment(queueTime, 'hh:mm:ss').isValid()
                                                                        ? moment(queueTime, 'hh:mm:ss').format('h:mm A | ')
                                                                        : ''}
                                                                    {cont?.formattedDate && moment(cont?.formattedDate, 'dddd | MMMM DD YYYY').isValid()
                                                                        ? moment(cont?.formattedDate, 'dddd | MMMM DD YYYY').format('MMM DD YYYY')
                                                                        : ''}

                                                                </span>
                                                            </>
                                                        )}

                                                        {/* {displayText} */}

                                                    </button>
                                                    <button className='button-rightsidebar-drop' onClick={toggleDropdown}> <FontAwesomeIcon icon={faCaretDown} /></button>

                                                </div>
                                                {isOpen ? (
                                                    // <ul className="dropdown-queue" >
                                                    <div ref={popoverRef}>
                                                        <ul className="dropdown-queue">


                                                            <li onClick={() => handleItemClick('Option 1')}>Schedule for later</li>

                                                            <hr />
                                                            <li onClick={handleShowQueue}>Edit Queue</li>
                                                        </ul>
                                                    </div>
                                                ) : ''}

                                            </Col>
                                        </Row>
                                    </>
                                )

                                    : (
                                        <>

                                            <Row className='mb-3'>
                                                <Col sm={6} md={6} lg={6} xs={6}>

                                                    <button className='button-rightsidebar' type='submit' onClick={handleSubmitThread}>
                                                        {loadingTweetThread ?
                                                            <Spinner animation="border" size="sm" variant="white" className='mr-5' style={{ marginRight: 5 }} /> :

                                                            "Post now"}

                                                    </button>
                                                </Col>

                                                <Col sm={6} md={6} lg={6} xs={6}>
                                                    <div className='button-queue'>
                                                        <button className='button-rightsidebar' onClick={handleQueueThread} type="submit" >
                                                            {loadingQueueThread ? (
                                                                <Spinner animation="border" variant="light" />
                                                            ) : (

                                                                <>
                                                                    Add to queue<br />
                                                                    <span className='queue-text'>
                                                                        {/* {cont.t ? moment(cont.t.time, 'hh:mm:ss').format(' h:mm A') : ''} | {moment(cont.formattedDate, 'dddd | MMMM DD YYYY').format('MMM DD YYYY ') ? moment(handleDate, 'dddd | MMMM DD YYYY').format('MMM DD YYYY ') : ''} */}

                                                                        {cont?.t && moment(queueTime, 'hh:mm:ss').isValid()
                                                                            ? moment(queueTime, 'hh:mm:ss').format('h:mm A | ')
                                                                            : ''}
                                                                        {cont?.formattedDate && moment(cont?.formattedDate, 'dddd | MMMM DD YYYY').isValid()
                                                                            ? moment(cont?.formattedDate, 'dddd | MMMM DD YYYY').format('MMM DD YYYY')
                                                                            : ''}

                                                                    </span>
                                                                </>
                                                            )}

                                                            {/* {displayText} */}

                                                        </button>
                                                        <button className='button-rightsidebar-drop' onClick={toggleDropdown}> <FontAwesomeIcon icon={faCaretDown} /></button>

                                                    </div>
                                                    {isOpen ? (
                                                        // <ul className="dropdown-queue" >
                                                        <div ref={popoverRef}>
                                                            <ul className="dropdown-queue">
                                                                <li onClick={toggleDropdownThread}>Schedule for later</li>
                                                                <hr />
                                                                <li onClick={handleShowQueue}>Edit Queue</li>
                                                            </ul>
                                                        </div>
                                                    ) : ''}


                                                </Col>
                                            </Row>

                                            {isOpenThread ? (
                                                <div style={{ marginTop: 20 }} className="form-value-sche" ref={popoverRefThread}>
                                                    <label>Schedule Later</label>
                                                    <div className="datetime-picker-thread" style={{ display: 'flex' }}>

                                                        <DatePicker
                                                            selected={selectedDateTime2}
                                                            onChange={handleDateChange2}
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            showTimeSelect
                                                            timeFormat="h:mm aa"
                                                            timeIntervals={1}
                                                            timeCaption="Time"
                                                            customInput={<CustomInput2 />}
                                                            placeholderText="Select date and time"
                                                            minDate={new Date()}
                                                        // filterDate={filterPastDates}


                                                        />
                                                        {
                                                            loadingSchduleThread ? (
                                                                <Spinner animation="border" size="lg" variant="primary" className='mr-5' style={{ marginRight: 5 }} />
                                                            ) :

                                                                <button onClick={(e) => handleScheduleLater(e)} className='schd-button'>Submit</button>

                                                        }
                                                    </div>

                                                </div>
                                            ) : ("")}
                                        </>
                                    )}


                                <div className='display-button'>

                                </div>


                                <Form.Check
                                    // inline
                                    label="Removed URL previews"
                                    name="group1"
                                    type='checkbox'
                                    id='checkbox'


                                    checked={isChecked}
                                    onChange={handleCheckboxChange}

                                />


                                {/* {loading2 ? */}
                                {/* <div>post is being uploading : {loader}
                                </div> */}
                                {/* : ""} */}

                                {/* {loading2 && <ProgressBar now={loader} label={`${loader}%`} />} */}
                            </form>

                        </div>

                    </div>



                </div >


                {/* ********SCHDULE POST ********** */}
                < Modal show={show} onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Schedule this post</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <Card className='schdeule-card'>

                            <Card.Body>
                                <Card.Title>
                                    <div className="schdeule-card-header">
                                        <div className='schdeule-card-image'>
                                            <img src={displayProfileDetails?.image_url} className="schdeule-img-fluid" alt="You tube" />
                                        </div>

                                        <div className='schdeule-card-text'>
                                            <span>{displayProfileDetails?.name}</span>

                                            <p>{tweetText}</p>



                                            <Row>
                                                {selectedImages?.map((image, index) => (
                                                    <Col lg={4} sm={4} md={4} key={index}
                                                    // style={{ display: 'inline-block', marginRight: '10px' }}
                                                    >
                                                        <img src={image.img} alt={`Selected ${index + 1}`} style={{ width: '80px', height: '80px', marginBottom: '10px', paddingRight: '10px' }} />
                                                        {/* <FontAwesomeIcon icon={faCircleXmark} type="button" onClick={() => handleRemoveImage(index)} className='cross-remove' /> */}


                                                    </Col>
                                                ))}
                                            </Row>


                                            {selectedGifpicker && (
                                                <div>
                                                    <img src={selectedGifpicker} alt="Selected GIF" style={{ width: '100px', height: '100px', marginBottom: 10 }} />


                                                </div>
                                            )}



                                        </div>

                                    </div>
                                </Card.Title>

                                <Card.Text>



                                </Card.Text>



                            </Card.Body>





                        </Card>

                        <div className='text-center modal-schedule-text'>
                            <p>This tweet will be published now. </p>
                            <p>You can choose a time slot from below.</p>
                        </div>

                        <div className='text-center '>
                            <DatePicker
                                selected={selectedDateTime}


                                onChange={handleDateChange}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                showTimeSelect
                                timeFormat="h:mm aa"
                                timeIntervals={1}
                                timeCaption="Time"
                                customInput={<CustomInput />}
                                placeholderText="Select date and time"
                                minDate={new Date()}
                            // filterDate={filterPastDates}


                            />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button> */}
                        <button onClick={handleScheduleTweet} className='schduled-button-latter'>
                            {loadingSet ?

                                <Spinner animation="border" variant="light" /> :
                                'Submit  '}
                        </button>
                    </Modal.Footer>
                </Modal >




                <Modal show={showQueue} onHide={handleCloseQueue} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Edit queue
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditQueue handleCloseQueue={handleCloseQueue} />
                    </Modal.Body>

                </Modal>





                <SettingOffCanvas handleShowthread={handleShowthread}
                    setShowthread={setShowthread} showthread={showthread}
                    handleClosethread={handleClosethread} textthread={textthread}
                    selectedImages={selectedImages}
                    videoPreviews={videoPreviews}
                    selectedGifpicker={selectedGifpicker}
                    selectedGifpickerArr={selectedGifpickerArr}
                    arrImg={arrImg}
                    NewGif={NewGif}
                    deleteCall={deleteCall}
                    selectedImages2={selectedImages2}
                    cont={cont}
                    videoPreviews2={videoPreviews2}
                    isChecked={isChecked}
                    selectedGifOb={selectedGifOb}
                />


                {/* 
                <ThreadOverLay handleShowthread={handleShowthread}
                    setShowthread={setShowthread}
                    showthread={showthread}
                    handleClosethread={handleClosethread}
                    textthread={textthread}
                    selectedImages={selectedImages}
                    //    selectedGifpicker={selectedGifpicker} 
                    //    selectedGifpickerArr={selectedGifpickerArr} 
                    //    arrImg={arrImg} NewGif={NewGif} 
                    deleteCall={deleteCall}
                    cont={cont}
                    isChecked={isChecked}
                /> */}
            </div >
            {/* <ToastContainer /> */}
        </>
    )
}

export default RightSidebar




const EditQueue = ({ handleCloseQueue, cont }) => {
    const [context, setContext] = useContext(MyContext);
    const [contextData, setContextData] = useContext(MySideBarContext);
    const [valueTime, setValueTime] = useState('');

    const userID = localStorage.getItem('UserID')
    const emailSwitch = localStorage.getItem('emailSwitch')
    const emailTo = localStorage.getItem('email-to')
    const [editQueueTime, setEditQueue] = useState(null)
    const [displayListTimeSlot, setDisplayListTimeSlot] = useState([])
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);

    const getTime2 = (dateTime) => {
        return dateTime ? dateTime.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '';
    };

    const ee = getTime2(editQueueTime)

    const handleTimeChange = (time) => {
        setEditQueue(time)

    };
    const tokenLogin = localStorage.getItem('accessToken')





    const hanldeStoreTimeSlot = (e) => {
        e.preventDefault()
        if (localStorage.getItem("userloggedin") === 'true') {
            try {


                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const isDuplicate = displayListTimeSlot && displayListTimeSlot?.some(slot => slot.timeslot === ee);
                    if (isDuplicate) {

                        toast.success("Selected time slot already exists. Please choose a different time slot", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        return;
                    }


                    let fdata = new FormData();


                    fdata.append('time', ee);
                    fdata.append('id', userID)


                    fetch(`${BASE_URL}api/save/timeslot`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response save time tweet", response)
                        return response.json()
                    }).then(data => {
                        console.log("save time tweet data -----", data);
                        if (data.code == 200) {
                            // window.location.reload()
                            displaytimeSlot()

                            // alert(JSON.stringify(data.message))
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            // handleClose()

                        }


                        else {


                            toast.error('please fill the time slot', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            // toast.error('please  ', {
                            //     position: "top-center",
                            //     autoClose: 5000,
                            //     hideProgressBar: false,
                            //     closeOnClick: true,
                            //     pauseOnHover: true,
                            //     draggable: true,
                            //     progress: undefined,
                            //     theme: "light",
                            // });
                        }

                    })
                }
                else {

                }



            } catch (error) {
                console.log(error)
            }
        }
        else {
            try {


                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const isDuplicate = displayListTimeSlot && displayListTimeSlot?.some(slot => slot.timeslot === ee);
                    if (isDuplicate) {

                        toast.success("Selected time slot already exists. Please choose a different time slot", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        return;
                    }


                    let fdata = new FormData();


                    fdata.append('time', ee);
                    fdata.append('id', userID)


                    fetch(`${BASE_URL}api/save/timeslot`, {
                        method: 'POST',
                        body: fdata,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        }
                    }).then(response => {

                        console.log("response save time tweet", response)
                        return response.json()
                    }).then(data => {
                        console.log("save time tweet data -----", data);
                        if (data.code == 200) {
                            // window.location.reload()
                            displaytimeSlot()

                            // alert(JSON.stringify(data.message))
                            toast.success(data.message, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            // handleClose()

                        }


                        else {


                            toast.error('please fill the time slot', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            // toast.error('please  ', {
                            //     position: "top-center",
                            //     autoClose: 5000,
                            //     hideProgressBar: false,
                            //     closeOnClick: true,
                            //     pauseOnHover: true,
                            //     draggable: true,
                            //     progress: undefined,
                            //     theme: "light",
                            // });
                        }

                    })
                }
                else {

                }



            } catch (error) {
                console.log(error)
            }
        }

    }






    const handleToggleDay = (day, index) => {
        const updatedDays = [...selectedDays];

        updatedDays[index] = updatedDays[index] ? [...updatedDays[index]] : [];

        if (updatedDays[index].includes(day)) {

            const dayIndex = updatedDays[index].indexOf(day);
            updatedDays[index].splice(dayIndex, 1);
        } else {

            updatedDays[index].push(day);
        }

        setSelectedDays(updatedDays);
    };


    useEffect(() => {
        displaytimeSlot()
    }, [])


    const displaytimeSlot = async () => {
        if (localStorage.getItem("userloggedin") === 'true') {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    if (data.data) {

                        setDisplayListTimeSlot(data.data.timeslot)
                        const initialSelectedDays = data.data.timeslot.map(slot => slot.weekdays);
                        setSelectedDays(initialSelectedDays);

                    }
                    else {
                        setDisplayListTimeSlot([])

                        setSelectedDays([]);

                    }
                }
                else {
                    console.log("error in login failed")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        } else {
            try {
                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const response = await fetch(`${BASE_URL}api/get/timeslot/${userID}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    if (data.data) {

                        setDisplayListTimeSlot(data.data.timeslot)
                        const initialSelectedDays = data.data.timeslot.map(slot => slot.weekdays);
                        setSelectedDays(initialSelectedDays);

                    }
                    else {
                        setDisplayListTimeSlot([])

                        setSelectedDays([]);

                    }
                }
                else {
                    console.log("error in login failed")
                }


            } catch (error) {
                console.error('Error fetching buyer data:', error);
            }
        }
    };






    const handleSubmitEditQueue = (e) => {
        e.preventDefault();

        const updatedTimeSlots = displayListTimeSlot && displayListTimeSlot?.map((item, index) => {
            return {
                time: item.timeslot,
                weekdays: selectedDays[index] || [] // Use selectedDays[index] if it exists, otherwise use an empty array
            };
        });

        let showAlert = true; // Variable to track if the alert should be shown or not

        if (localStorage.getItem("userloggedin") === 'true') {

            updatedTimeSlots?.forEach((timeSlot, index) => {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailTo);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const formData = new FormData();
                    formData.append('id', userID);
                    formData.append(`time`, timeSlot.time);
                    // formData.append(`weekdays`, timeSlot.weekdays.join(','));
                    formData.append(`weekdays`, timeSlot.weekdays.join(',').replace(/^,/, ''));


                    fetch(`${BASE_URL}api/save/weekdays`, {
                        method: 'POST',
                        body: formData,
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        },
                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log("Updated time slots:", data);


                            if (showAlert) {

                                // alert(JSON.stringify(data.message));
                                toast.success(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setContextData({ QUEUESaved: true })
                                handleCloseQueue()
                                // window.location.reload()
                                showAlert = false; // Set showAlert to false to prevent showing the alert again
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });

                }
                else {

                }
            });

        }
        else {
            updatedTimeSlots?.forEach((timeSlot, index) => {

                const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];


                const targetUser = userDataArray.find(user => user.email === emailSwitch);

                if (targetUser) {
                    const { userID, accessToken } = targetUser;

                    const formData = new FormData();
                    formData.append('id', userID);
                    formData.append(`time`, timeSlot.time);
                    // formData.append(`weekdays`, timeSlot.weekdays.join(','));
                    formData.append(`weekdays`, timeSlot.weekdays.join(',').replace(/^,/, ''));


                    fetch(`${BASE_URL}api/save/weekdays`, {
                        method: 'POST',
                        body: formData,
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        },
                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log("Updated time slots:", data);


                            if (showAlert) {

                                // alert(JSON.stringify(data.message));
                                toast.success(data.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                setContextData({ QUEUESaved: true })
                                handleCloseQueue()
                                // window.location.reload()
                                showAlert = false; // Set showAlert to false to prevent showing the alert again
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });

                }
                else {

                }
            });
        }
    };




    return (
        <>
            <div className='Edit-queue-box'>
                {/* <p><FontAwesomeIcon icon={faClock} /> Will send this Tweet on Fri, 21 April 2023 at 4:30 PM</p> */}

                <div>






                    <div className='text-center date-picker-time'>
                        <DatePicker
                            selected={editQueueTime}
                            onChange={handleTimeChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={1}
                            dateFormat="h:mm aa"
                            timeCaption="Time"
                            placeholderText="Select date and time"
                            className="custom-input"
                        />
                        {/* <TimePicker onChange={setValueTime} value={valueTime} /> */}
                        <button className='timeSlot-Button--1' onClick={hanldeStoreTimeSlot}>+</button>

                    </div>





                    {displayListTimeSlot ? (
                        <Table bordered hover className='mt-5'>
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Mon</th>
                                    <th>Tue</th>
                                    <th>Wed</th>
                                    <th>Thu</th>
                                    <th>Fri</th>
                                    <th>Sat</th>
                                    <th>Sun</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // displayListTimeSlot.map((item, index) => {

                                    displayListTimeSlot && displayListTimeSlot
                                        .map((item, index) => {


                                            const converted = moment(item.timeslot, 'HH:mm:ss').format('h:mm A');


                                            const handleQueueListDelete = () => {
                                                if (localStorage.getItem("userloggedin") === 'true') {
                                                    try {
                                                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

                                                        const targetUser = userDataArray.find(user => user.email === emailTo);

                                                        if (targetUser) {
                                                            const { userID, accessToken, providerToken, secretKey } = targetUser;
                                                            let fdata = new FormData();
                                                            fdata.append('time', item.timeslot); // Use the specific item.timeslot value
                                                            fdata.append('id', userID);

                                                            fetch(`${BASE_URL}api/delete/timeslot`, {
                                                                method: 'POST',
                                                                body: fdata,
                                                                headers: {
                                                                    Authorization: `Bearer ${accessToken}`,
                                                                },
                                                            })
                                                                .then(response => response.json())
                                                                .then(data => {
                                                                    console.log("save time tweet data -----", data);
                                                                    if (data.code === 200) {

                                                                        toast.success(data.message, {
                                                                            position: "top-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                            theme: "light",
                                                                        });
                                                                        displaytimeSlot()
                                                                        setContextData({ QUEUESaved: true })
                                                                        setContext({ ...cont, t: '', formattedDate: '', type: "queue" })
                                                                        // handleCloseQueue()
                                                                        // Perform any additional actions upon successful deletion
                                                                    } else {

                                                                        toast.error(data.message, {
                                                                            position: "top-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                            theme: "light",
                                                                        });
                                                                        // Handle the deletion failure scenario
                                                                    }
                                                                })
                                                        }
                                                        else {
                                                            console.log("scjbsbhjcdhjsva")
                                                        }
                                                    } catch (error) {
                                                        // Handle any potential errors during the deletion process
                                                    }

                                                }
                                                else {
                                                    try {
                                                        const userDataArray = JSON.parse(localStorage.getItem('userDataArray')) || [];

                                                        const targetUser = userDataArray.find(user => user.email === emailSwitch);

                                                        if (targetUser) {
                                                            const { userID, accessToken, providerToken, secretKey } = targetUser;

                                                            let fdata = new FormData();
                                                            fdata.append('time', item.timeslot); // Use the specific item.timeslot value
                                                            fdata.append('id', userID);

                                                            fetch(`${BASE_URL}api/delete/timeslot`, {
                                                                method: 'POST',
                                                                body: fdata,
                                                                headers: {
                                                                    Authorization: `Bearer ${accessToken}`,
                                                                },
                                                            })
                                                                .then(response => response.json())
                                                                .then(data => {
                                                                    console.log("save time tweet data -----", data);
                                                                    if (data.code === 200) {

                                                                        toast.success(data.message, {
                                                                            position: "top-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                            theme: "light",
                                                                        });
                                                                        displaytimeSlot()
                                                                        setContextData({ QUEUESaved: true })
                                                                        setContext({ ...cont, t: '', formattedDate: '', type: "queue" })
                                                                        // handleCloseQueue()
                                                                        // Perform any additional actions upon successful deletion
                                                                    } else {

                                                                        toast.error(data.message, {
                                                                            position: "top-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true,
                                                                            progress: undefined,
                                                                            theme: "light",
                                                                        });
                                                                        // Handle the deletion failure scenario
                                                                    }
                                                                })
                                                        }
                                                        else {
                                                            console.log("scjbsbhjcdhjsva")
                                                        }
                                                    } catch (error) {
                                                        // Handle any potential errors during the deletion process
                                                    }

                                                }
                                            }
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td className='table-time-clumn'>{converted} <butoon onClick={handleQueueListDelete} className="buttoncross">x</butoon></td>

                                                        <td>
                                                            {/* <input
                                                        type="checkbox"
                                                        className='checkbox-field'
                                                        checked={selectedDays[index] && selectedDays[index].includes('Monday')}
                                                        onChange={() => handleToggleDay('Monday', index)}
                                                    /> */}
                                                            <Form.Check


                                                                name="group1"
                                                                type='checkbox'
                                                                id='checkbox'
                                                                className='checkbox-filter'
                                                                checked={selectedDays[index] && selectedDays[index].includes('Monday')}
                                                                onChange={() => handleToggleDay('Monday', index)}

                                                            />
                                                        </td>

                                                        <td>
                                                            {/* <input
                                                        type="checkbox"
                                                        className='checkbox-field'
                                                        checked={selectedDays[index] && selectedDays[index].includes('Tuesday')}
                                                        onChange={() => handleToggleDay('Tuesday', index)}
                                                    /> */}
                                                            <Form.Check


                                                                name="group1"
                                                                type='checkbox'
                                                                id='checkbox'
                                                                className='checkbox-filter'
                                                                checked={selectedDays[index] && selectedDays[index].includes('Tuesday')}
                                                                onChange={() => handleToggleDay('Tuesday', index)}

                                                            />
                                                        </td>
                                                        <td>
                                                            {/* <input
                                                        type="checkbox"
                                                        className='checkbox-field'
                                                        checked={selectedDays[index] && selectedDays[index].includes('Wednesday')}
                                                        onChange={() => handleToggleDay('Wednesday', index)}
                                                    /> */}
                                                            <Form.Check


                                                                name="group1"
                                                                type='checkbox'
                                                                id='checkbox'
                                                                className='checkbox-filter'
                                                                checked={selectedDays[index] && selectedDays[index].includes('Wednesday')}
                                                                onChange={() => handleToggleDay('Wednesday', index)}

                                                            />
                                                        </td>
                                                        <td>
                                                            {/* <input
                                                        type="checkbox"
                                                        className='checkbox-field'
                                                        checked={selectedDays[index] && selectedDays[index].includes('Thursday')}
                                                        onChange={() => handleToggleDay('Thursday', index)}
                                                    /> */}
                                                            <Form.Check


                                                                name="group1"
                                                                type='checkbox'
                                                                id='checkbox'
                                                                className='checkbox-filter'
                                                                checked={selectedDays[index] && selectedDays[index].includes('Thursday')}
                                                                onChange={() => handleToggleDay('Thursday', index)}

                                                            />
                                                        </td>
                                                        <td>
                                                            {/* <input
                                                        type="checkbox"
                                                        className='checkbox-field'
                                                        checked={selectedDays[index] && selectedDays[index].includes('Friday')}
                                                        onChange={() => handleToggleDay('Friday', index)}
                                                    /> */}
                                                            <Form.Check


                                                                name="group1"
                                                                type='checkbox'
                                                                id='checkbox'
                                                                className='checkbox-filter'
                                                                checked={selectedDays[index] && selectedDays[index].includes('Friday')}
                                                                onChange={() => handleToggleDay('Friday', index)}
                                                            />


                                                        </td>
                                                        <td>
                                                            {/* <input
                                                        type="checkbox"
                                                        className='checkbox-field'
                                                        checked={selectedDays[index] && selectedDays[index].includes('Saturday')}
                                                        onChange={() => handleToggleDay('Saturday', index)}
                                                    /> */}
                                                            <Form.Check


                                                                name="group1"
                                                                type='checkbox'
                                                                id='checkbox'
                                                                className='checkbox-filter'
                                                                checked={selectedDays[index] && selectedDays[index].includes('Saturday')}
                                                                onChange={() => handleToggleDay('Saturday', index)}
                                                            />
                                                        </td>
                                                        <td>
                                                            {/* <input
                                                        type="checkbox"
                                                        className='checkbox-field'
                                                        checked={selectedDays[index] && selectedDays[index].includes('Sunday')}
                                                        onChange={() => handleToggleDay('Sunday', index)}
                                                    /> */}
                                                            <Form.Check


                                                                name="group1"
                                                                type='checkbox'
                                                                id='checkbox'
                                                                className='checkbox-filter'
                                                                checked={selectedDays[index] && selectedDays[index].includes('Sunday')}
                                                                onChange={() => handleToggleDay('Sunday', index)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                }

                            </tbody>
                        </Table>
                    ) : (
                        <div className="text-center Edit-noresponse-text">
                            <p> You haven't included any slots please use the '+' symbol to add slots.</p>
                        </div>
                    )}


                    {displayListTimeSlot ? (
                        <div className='button-edit-queue text-center'>
                            <button type="submit" className='edit-queue' onClick={handleSubmitEditQueue}>Submit</button>
                        </div>)
                        : ""}




                </div>
            </div>
            {/* <ToastContainer /> */}
        </>
    )
}



const PostNext = () => {
    alert("post next")
    return (
        <>
            Post NEcxt
        </>
    )
}


const ThreadPost = ({ showthread, handleClosethread, thread }) => {



    return (
        <>
            <Modal show={showthread} onHide={handleClosethread}>
                <Modal.Header closeButton>
                    <Modal.Title>Thread </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosethread}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClosethread}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
